import { GlossarySinglePageOpenEventHandler } from './GlossarySinglePageOpenEvent';
import { GlossarySinglePageBannerClickEventHandler } from './GlossarySinglePageBannerClickEvent';
import { GlossarySinglePageLinkClickHandler } from './GlossarySinglePageLinkClickEvent';
import { GlossarySinglePageAngelNumberClickEventHandler } from './GlossarySinglePageAngelNumberClickEvent';

export * from './GlossarySinglePageOpenEvent';
export * from './GlossarySinglePageLinkClickEvent';
export * from './GlossarySinglePageBannerClickEvent';
export * from './GlossarySinglePageAngelNumberClickEvent';

export const glossarySinglePageEventHandlers = [
  GlossarySinglePageOpenEventHandler,
  GlossarySinglePageBannerClickEventHandler,
  GlossarySinglePageLinkClickHandler,
  GlossarySinglePageAngelNumberClickEventHandler,
];
