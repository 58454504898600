import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type ChartSinglePageCtaClickEventPayload = {
  chart: string;
};

export class ChartSinglePageCtaClickEvent extends BaseEvent<ChartSinglePageCtaClickEventPayload> {}

export class ChartSinglePageCtaClickEventHandler extends BaseEventHandler<ChartSinglePageCtaClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ChartSinglePageCtaClickEvent;
  }

  async track(event: ChartSinglePageCtaClickEvent) {
    const { chart } = event.getPayload();

    return this.amplitudeService.logEvent('seo_chart_page_cta_click', {
      chart,
    });
  }
}
