import { useState, useEffect } from 'react';

const useHideOnScroll = () => {
  const [isHidden, setIsHidden] = useState(false);

  const checkScrollPosition = () => {
    const scrollPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const halfViewport = viewportHeight / 2;
    const scrollableArea = documentHeight - viewportHeight;
    const halfScrollableArea = scrollableArea / 2;
    const threshold = Math.min(halfViewport, halfScrollableArea);
    setIsHidden(scrollPosition > threshold);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  return isHidden;
};

export default useHideOnScroll;
