import { ReactNode, useEffect, useState } from 'react';

type RenderOnlyOnClientProps = {
  children: ReactNode;
};

const RenderOnlyOnClient = ({ children }: RenderOnlyOnClientProps) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return <>{children}</>;
};

export default RenderOnlyOnClient;
