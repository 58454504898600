import { CircularProgressProps as MaterialCircularProgressProps } from '@mui/material';
import { StyledMaterialCircularProgress } from './styles';

export type CircularProgressProps = MaterialCircularProgressProps & {
  className?: string;
};

const CircularProgress = ({ className, ...otherProps }: CircularProgressProps) => {
  return <StyledMaterialCircularProgress className={className} {...otherProps} />;
};

export default CircularProgress;
