import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type DailyHoroscopeSinglePageOpenEventPayload = {
  zodiac: ZodiacTypes;
};

export class DailyHoroscopeSinglePageOpenEvent extends BaseEvent<DailyHoroscopeSinglePageOpenEventPayload> {}

export class DailyHoroscopeSinglePageOpenEventHandler extends BaseEventHandler<DailyHoroscopeSinglePageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof DailyHoroscopeSinglePageOpenEvent;
  }

  async track(event: DailyHoroscopeSinglePageOpenEvent) {
    const { zodiac } = event.getPayload();
    return this.amplitudeService.logEvent('seo_daily_horoscope_page_open', {
      zodiac,
    });
  }
}
