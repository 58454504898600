import { ExpertId } from 'src/core/experts/entities';
import { Chat } from '../entities';

export type ChatState = {
  entities: Record<ExpertId, Chat>;
  chatCount: number;
  sessions: ExpertId[];
};

export const chatInitialState: ChatState = {
  entities: {},
  chatCount: 0,
  sessions: [],
};
