import { BlogSinglePageOpenEventHandler } from './single/BlogSinglePageOpenEvent';
import { BlogSinglePageBackToMainClickEventHandler } from './single/BlogSingleBackToMainClickEvent';
import { BlogSinglePageCardClickEventHandler } from './single/BlogSingleCardClickEvent';
import { BlogSinglePageCtaClickEventHandler } from './single/BlogSingleСtaClickEvent';
import { BlogSinglePageTarotMeaningShowEventHandler } from './single/BlogSingleTarotMeaningShowEvent';
import { BlogSinglePageTarotReadMoreClickEventHandler } from './single/BlogSingleTarotReadMoreClickEvent';
import { BlogSinglePageTarotGetPersonalizedClickEventHandler } from './single/BlogSingleTarotGetPersonalizedClickEvent';

import { BlogMainExpertCardClickEventHandler } from './main/BlogMainExpertCardClickEvent';
import { BlogMainCtaClickEventHandler } from './main/BlogMainCtaClickEvent';
import { BlogMainStartChatClickEventHandler } from './main/BlogMainStartChatClickEvent';
import { BlogMainFindAdvisorClickEventHandler } from './main/BlogMainFindAdvisorClickEvent';
import { BlogMainPageOpenEventHandler } from './main/BlogMainPageOpenEvent';
import { BlogMainCardClickEventHandler } from './main/BlogMainCardClickEvent';
import { BlogMainCategoryClickEventHandler } from './main/BlogMainCategoryClickEvent';

export * from './single/BlogSinglePageOpenEvent';
export * from './single/BlogSingleBackToMainClickEvent';
export * from './single/BlogSingleCardClickEvent';
export * from './single/BlogSingleСtaClickEvent';
export * from './single/BlogSingleTarotMeaningShowEvent';
export * from './single/BlogSingleTarotReadMoreClickEvent';
export * from './single/BlogSingleTarotGetPersonalizedClickEvent';

export * from './main/BlogMainExpertCardClickEvent';
export * from './main/BlogMainCtaClickEvent';
export * from './main/BlogMainCardClickEvent';
export * from './main/BlogMainFindAdvisorClickEvent';
export * from './main/BlogMainPageOpenEvent';
export * from './main/BlogMainStartChatClickEvent';
export * from './main/BlogMainCategoryClickEvent';

export const blogPageEventHandlers = [
  BlogSinglePageOpenEventHandler,
  BlogSinglePageBackToMainClickEventHandler,
  BlogSinglePageCardClickEventHandler,
  BlogSinglePageCtaClickEventHandler,
  BlogSinglePageTarotMeaningShowEventHandler,
  BlogSinglePageTarotReadMoreClickEventHandler,
  BlogSinglePageTarotGetPersonalizedClickEventHandler,

  BlogMainExpertCardClickEventHandler,
  BlogMainCtaClickEventHandler,
  BlogMainStartChatClickEventHandler,
  BlogMainFindAdvisorClickEventHandler,
  BlogMainPageOpenEventHandler,
  BlogMainCardClickEventHandler,
  BlogMainCategoryClickEventHandler,
];
