import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossaryMainPageOpenEventPayload = {
  name: string;
};

export class GlossaryMainPageOpenEvent extends BaseEvent<GlossaryMainPageOpenEventPayload> {}

export class GlossaryMainPageOpenEventHandler extends BaseEventHandler<GlossaryMainPageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossaryMainPageOpenEvent;
  }

  async track(event: GlossaryMainPageOpenEvent) {
    const { name } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_main_page_open', {
      name,
    });
  }
}
