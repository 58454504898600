import { HomePageOpenEventHandler } from './HomePageOpenEvent';
import { HomePageSeeMoreExpertsClickEventHandler } from './HomePageSeeMoreExpertsClickEvent';
import { HomePageExpertCardClickEventHandler } from './HomePageExpertCardClickEvent';
import { HomePageExpertStartChatClickEventHandler } from './HomePageExpertStartChatClickEvent';
import { HomePageGetStartedClickEventHandler } from './HomePageGetStartedClickEvent';
import { HomePageAppBannerClickEventHandler } from './HomePageAppBannerClickEvent';

export * from './HomePageOpenEvent';
export * from './HomePageSeeMoreExpertsClickEvent';
export * from './HomePageExpertCardClickEvent';
export * from './HomePageExpertStartChatClickEvent';
export * from './HomePageGetStartedClickEvent';
export * from './HomePageAppBannerClickEvent';

export const homePageEventHandlers = [
  HomePageOpenEventHandler,
  HomePageSeeMoreExpertsClickEventHandler,
  HomePageGetStartedClickEventHandler,
  HomePageExpertCardClickEventHandler,
  HomePageExpertStartChatClickEventHandler,
  HomePageAppBannerClickEventHandler,
];
