/* eslint-disable max-len */
import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossaryRootPageGlossaryClickEventPayload = {
  glossary: string;
};

export class GlossaryRootPageGlossaryClickEvent extends BaseEvent<GlossaryRootPageGlossaryClickEventPayload> {}

export class GlossaryRootPageGlossaryClickEventHandler extends BaseEventHandler<GlossaryRootPageGlossaryClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossaryRootPageGlossaryClickEvent;
  }

  async track(event: GlossaryRootPageGlossaryClickEvent) {
    const { glossary } = event.getPayload();

    return this.amplitudeService.logEvent('seo_meaning_page_glossary_click', {
      glossary,
    });
  }
}
