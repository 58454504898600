import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type ExpertsCatalogueFlowOpenedEventPayload = {
  funnel: string;
};

export class ExpertsCatalogueFlowOpenedEvent extends BaseEvent<ExpertsCatalogueFlowOpenedEventPayload> {}

export class ExpertsCatalogueFlowOpenEventHandler extends BaseEventHandler<ExpertsCatalogueFlowOpenedEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ExpertsCatalogueFlowOpenedEvent;
  }

  async track(event: ExpertsCatalogueFlowOpenedEvent) {
    const { funnel } = event.getPayload();

    return this.amplitudeService.logEvent('funnel_open_asknebula', {
      funnel,
    });
  }
}
