// import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { B3Propagator } from '@opentelemetry/propagator-b3';
import { ConsoleSpanExporter } from '@opentelemetry/sdk-trace-base';
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { Config } from 'src/config';
import { AppEnvironmentService } from 'src/core/common/services';
import { shouldSucceedWithProbability } from 'src/core/common/utils/number/shouldSucceedWithProbability';
import { Resource, Span, SpanStatusCode } from '../entities';
import { ObservabilitySystem } from '../interfaces';
import { OTelObservabilitySystem } from './OTelObservabilitySystem';
import { ObservabilitySystemWithSplitId } from './ObservabilitySystemWithSplitId';

export class OTelObservabilitySystemBuilder {
  private constructor(private observabilitySystem: ObservabilitySystem) {}

  static create(config: Config) {
    return new OTelObservabilitySystemBuilder(new OTelObservabilitySystem(config));
  }

  addBrowserTraceProvider(config: Partial<{ resource: Resource }> = {}): this {
    const tracerProvider = new WebTracerProvider(config);

    tracerProvider.register({
      // contextManager: new ZoneContextManager,
      propagator: new B3Propagator(),
    });

    this.observabilitySystem.setGlobalTracerProvider(tracerProvider);

    return this;
  }

  withSplitId(appEnvironment: AppEnvironmentService): this {
    this.observabilitySystem = new ObservabilitySystemWithSplitId(
      appEnvironment,
      this.observabilitySystem,
    );

    return this;
  }

  withConsoleSpanExporter(): this {
    this.observabilitySystem.addSpanProcessor(new BatchSpanProcessor(new ConsoleSpanExporter()));

    return this;
  }

  withHttpSpanExporter(config: { url: string; headers: Record<string, unknown> }): this {
    this.observabilitySystem.addSpanProcessor(
      new BatchSpanProcessor(new OTLPTraceExporter(config)),
    );

    return this;
  }

  addXMLHttpRequestInstrumentation(
    config: Partial<{
      ignoreUrls: Array<string | RegExp>;
      spanRate: number;
    }> = {},
  ): this {
    const { ignoreUrls } = config;

    const applyCustomAttributesOnSpan = (span: Span) => {
      this.addCustomAttributesToInstrumentation(span, config);
    };

    const xmlHttpRequestInstrumentation = new XMLHttpRequestInstrumentation({
      ignoreUrls,
      applyCustomAttributesOnSpan,
    });

    const tracerProvider = this.observabilitySystem.getTracerProvider();
    xmlHttpRequestInstrumentation.setTracerProvider(tracerProvider);

    return this;
  }

  addDocumentLoadedInstrumentation() {
    const applyCustomAttributesOnSpan = (span: Span) => {
      this.addCustomAttributesToInstrumentation(span);
    };

    const documentLoadInstrumentation = new DocumentLoadInstrumentation({
      applyCustomAttributesOnSpan: {
        documentLoad: applyCustomAttributesOnSpan,
        documentFetch: applyCustomAttributesOnSpan,
        resourceFetch: applyCustomAttributesOnSpan,
      },
    });

    const tracerProvider = this.observabilitySystem.getTracerProvider();

    documentLoadInstrumentation.setTracerProvider(tracerProvider);

    return this;
  }

  getResult(): ObservabilitySystem {
    return this.observabilitySystem;
  }

  private addCustomAttributesToInstrumentation(
    span: Span,
    config: Partial<{ spanRate: number }> = {},
  ) {
    const { spanRate = 1 } = config;
    // Skip sending in n percent of cases where n is (100% - spanRate * 10)
    if (!shouldSucceedWithProbability(spanRate)) {
      span.setStatus({ code: SpanStatusCode.OK });
    }

    const attributes = this.observabilitySystem.getAttributes();
    span.setAttributes({ ...attributes });
  }
}
