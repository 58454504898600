import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type BlogSinglePageTarotMeaningShowEventPayload = {
  blog: string;
};

export class BlogSinglePageTarotMeaningShowEvent extends BaseEvent<BlogSinglePageTarotMeaningShowEventPayload> {}

export class BlogSinglePageTarotMeaningShowEventHandler extends BaseEventHandler<BlogSinglePageTarotMeaningShowEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogSinglePageTarotMeaningShowEvent;
  }

  async track(event: BlogSinglePageTarotMeaningShowEvent) {
    const { blog } = event.getPayload();

    return this.amplitudeService.logEvent('seo_blog_page_tarot_meaning_show', {
      blog: convertToSnakeCase(blog),
    });
  }
}
