import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type DailyHoroscopeSingleCompatibilityWithClickEventPayload = {
  zodiac: ZodiacTypes;
  clickedZodiac: ZodiacTypes;
};

export class DailyHoroscopeSingleCompatibilityWithClickEvent extends BaseEvent<DailyHoroscopeSingleCompatibilityWithClickEventPayload> {}

export class DailyHoroscopeSingleCompatibilityWithClickEventHandler extends BaseEventHandler<DailyHoroscopeSingleCompatibilityWithClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof DailyHoroscopeSingleCompatibilityWithClickEvent;
  }

  async track(event: DailyHoroscopeSingleCompatibilityWithClickEvent) {
    const { zodiac, clickedZodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_daily_horoscope_page_compatibility_with_click', {
      zodiac,
      clicked_zodiac: clickedZodiac,
    });
  }
}
