import cn from 'classnames';
import { useState } from 'react';
import Drawer from 'src/core/common/components/common/Drawer';
import RenderOnlyOnClient from 'src/core/common/components/common/RenderOnlyOnClient';
import { useCookieConsent, useRedirectToMainApp } from 'src/core/common/hooks';
import { LocationRegion, countriesWithConsentCheck } from 'src/core/location/entities';
import {
  LocationCountryCase,
  LocationCountrySwitch,
  LocationRegionCase,
  LocationRegionSwitch,
} from 'src/core/location/ui';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './HeaderMenu.module.scss';

export type HeaderMenuProps = {
  className?: string;
};

export const HeaderMenu = ({ className }: HeaderMenuProps) => {
  const [isOpened, setIsOpened] = useState(false);

  const {
    getTermsPagePath,
    getFAQPagePath,
    getSupportPath,
    getCookiePolicyPagePath,
    getPrivacyPolicyPagePath,
    getMoneyBackPolicyPagePath,
    getPersonalInfoPolicyPagePath,
  } = useRedirectToMainApp();

  const { toggleReconsent } = useCookieConsent();

  const onControlClick = () => {
    setIsOpened((value) => !value);
  };

  const onClose = () => {
    setIsOpened(false);
  };

  return (
    <div className={cn(styles.root, className)}>
      <span className={styles.menuControl} onClick={onControlClick}>
        <Icon name="burger" className={styles.menuControlIcon} />
      </span>

      <Drawer
        open={isOpened}
        onClose={onClose}
        PaperProps={{ className: styles.menuContentWrapper }}
      >
        <div className={styles.menuContent}>
          <div className={styles.menuCloseWrapper}>
            {/* TODO change to button */}
            <span className={styles.menuClose} onClick={onClose}>
              <Icon name="cross" className={styles.menuCloseIcon} />
            </span>
          </div>
          <nav className={styles.menuNav}>
            <ul className={styles.menuNavList}>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getPrivacyPolicyPagePath()}>
                  Privacy policy
                </a>
              </li>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getTermsPagePath()}>
                  Terms of use
                </a>
              </li>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getMoneyBackPolicyPagePath()}>
                  Money back policy
                </a>
              </li>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getCookiePolicyPagePath()}>
                  Cookie policy
                </a>
              </li>
              <LocationRegionSwitch>
                <LocationRegionCase value={LocationRegion.CALIFORNIA}>
                  <li className={styles.menuNavListItem}>
                    <a className={styles.menuNavLink} href={getPersonalInfoPolicyPagePath()}>
                      Personal information
                    </a>
                  </li>
                </LocationRegionCase>
              </LocationRegionSwitch>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getFAQPagePath()}>
                  FAQ
                </a>
              </li>
              <li className={styles.menuNavListItem}>
                <a className={styles.menuNavLink} href={getSupportPath()}>
                  Contact us
                </a>
              </li>
              <RenderOnlyOnClient>
                <LocationCountrySwitch>
                  <LocationCountryCase value={countriesWithConsentCheck}>
                    <li className={styles.menuNavListItem}>
                      <button className={styles.menuNavLink} onClick={toggleReconsent}>
                        Consent settings
                      </button>
                    </li>
                  </LocationCountryCase>
                </LocationCountrySwitch>
              </RenderOnlyOnClient>
            </ul>
          </nav>
        </div>
      </Drawer>
    </div>
  );
};

export default HeaderMenu;
