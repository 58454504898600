import { Log, LogsBatch, LogsBatchId } from '../../entities';
import { LogsBatchCache } from '../../interfaces';

type BaseLogsBatchCacheConfig = {
  batchLength: number;
};

export class BaseLogsBatchCache implements LogsBatchCache {
  private config: BaseLogsBatchCacheConfig;

  private batches: Record<LogsBatchId, LogsBatch> = {};

  constructor(config: Partial<BaseLogsBatchCacheConfig> = {}) {
    const defaultConfig: BaseLogsBatchCacheConfig = {
      batchLength: 15,
    };

    this.config = { ...config, ...defaultConfig };
  }

  getBatches() {
    return LogsBatch.sort(Object.values(this.batches));
  }

  addPendingLog(log: Log): void {
    const lastBatch = this.getLastBatch();
    const canAddToLastBatch = lastBatch && lastBatch.isPending() && !lastBatch.isOverflowed();

    if (canAddToLastBatch) {
      lastBatch.addLog(log);
      return;
    }

    const newBatch = LogsBatch.create({
      logs: [log],
      maxSize: this.getBatchMaxLength(),
    });
    this.saveBatch(newBatch);
  }

  isEmpty() {
    return !this.getBatches().length;
  }

  getLastBatch(): LogsBatch | null {
    const batches = this.getBatches();
    return batches[batches.length - 1] ? batches[batches.length - 1] : null;
  }

  dropBatch(logsBatchId: LogsBatchId) {
    delete this.batches[logsBatchId];
  }

  saveBatch(batch: LogsBatch) {
    this.batches[batch.getId()] = batch;
  }

  private getBatchMaxLength() {
    return this.config.batchLength;
  }
}
