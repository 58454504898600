import { createReducer } from '@reduxjs/toolkit';
import { chatCountIncreased, chatSessionUpdated, messageReceived, messageSent } from './actions';
import { chatInitialState } from './state';

export const chatReducer = createReducer(chatInitialState, (builder) => {
  builder
    .addCase(messageSent, (state, { payload }) => {
      state.entities[payload.expertId] = {
        ...state.entities[payload.expertId],
        outgoingMessage: payload.text,
      };
    })
    .addCase(messageReceived, (state, { payload }) => {
      state.entities[payload.expertId] = {
        ...state.entities[payload.expertId],
        incomingMessage: payload.text,
      };
    })
    .addCase(chatCountIncreased, (state) => {
      state.chatCount = state.chatCount + 1;
    })
    .addCase(chatSessionUpdated, (state, { payload }) => {
      state.sessions.push(payload);
    });
});
