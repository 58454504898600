import { AnswerData } from 'src/core/quiz/entities';

export enum InputControlType {
  NOOP = 'noop',
  NAME = 'name',
  QUESTION = 'question',
  EMAIL = 'email',
  LOCATION = 'location',
  DATE = 'date',
  TIME = 'time',
  SINGLE_CHOICE = 'single-choice',
}

interface NoopInputControl {
  type: InputControlType.NOOP;
  text: string;
}

interface NameInputControl {
  type: InputControlType.NAME;
}

interface QuestionInputControl {
  type: InputControlType.QUESTION;
}

interface EmailInputControl {
  type: InputControlType.EMAIL;
}

interface LocationInputControl {
  type: InputControlType.LOCATION;
}

interface DateInputControl {
  type: InputControlType.DATE;
}

interface TimeInputControl {
  type: InputControlType.TIME;
}

interface SingleChoiceInputControl {
  type: InputControlType.SINGLE_CHOICE;
  variants: Array<AnswerData>;
}

export type InputControl =
  | NoopInputControl
  | NameInputControl
  | EmailInputControl
  | LocationInputControl
  | DateInputControl
  | TimeInputControl
  | SingleChoiceInputControl
  | QuestionInputControl;
