import { parseQueryParams } from 'src/core/common/utils/query';
import { FeatureFlagsDecorator } from './FeatureFlagsDecorator';
import { FeatureFlags } from './FeatureFlags';

export class QuerySettingFeatureFlagsDecorator extends FeatureFlagsDecorator {
  constructor(featureFlags: FeatureFlags) {
    super(featureFlags);
    this.loadFromQueries();
  }

  private loadFromQueries() {
    const queries = parseQueryParams();

    if (!Object.keys(queries).length) return;

    super.setForcedFeatureValues(queries);
  }
}
