import { combineReducers } from 'redux';
import { chatReducer } from 'src/core/chat/store';
import { chatBotReducer } from 'src/core/chat-bot/store';
import { expertsReducer } from 'src/core/experts/store';
import { userReducer, userUtmTagsReducer } from 'src/core/user/store/reducer';
import { locationReducer } from 'src/core/location/store';
import { systemReducer } from './system';

const rootReducer = combineReducers({
  system: systemReducer,
  user: userReducer,
  userUtmTags: userUtmTagsReducer,
  chatBot: chatBotReducer,
  experts: expertsReducer,
  location: locationReducer,
  chat: chatReducer,
});

export const getRootState = (state: RootInitialState) => state;

export type RootInitialState = ReturnType<typeof rootReducer>;

export default rootReducer;
