import { createReducer } from '@reduxjs/toolkit';
import { UserUtmTagsState } from './state';
import { userUtmTagsUpdated } from './actions';

export const userUtmTagsInitialState: UserUtmTagsState = {
  tags: {},
};

export const userUtmTagsReducer = createReducer(userUtmTagsInitialState, (builder) => {
  builder.addCase(userUtmTagsUpdated, (state, { payload }) => {
    state.tags = payload;
  });
});
