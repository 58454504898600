import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'src/core/common/entities';
import { fetchLocation } from './actions';
import { LocationState } from './state';

export const locationInitialState: LocationState = {
  location: {},
  loadingsStatus: LoadingStatuses.IDLE,
};

export const locationReducer = createReducer(locationInitialState, (builder) => {
  builder
    .addCase(fetchLocation.pending, (state) => {
      state.loadingsStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchLocation.fulfilled, (state, { payload }) => {
      state.location = payload;
      state.loadingsStatus = LoadingStatuses.FULFILLED;
    })
    .addCase(fetchLocation.rejected, (state) => {
      state.loadingsStatus = LoadingStatuses.FAILED;
    });
});
