import { createDate } from 'src/core/common/utils/date';
import { BaseService } from 'src/core/common/services';
import { UserGender } from 'src/core/user/entities';
import { ZodiacTypes } from '../entities';

export class ZodiacService extends BaseService {
  static getZodiacSignByDate(date: string) {
    const month = +createDate(date).format('M');
    const day = +createDate(date).format('DD');

    const zodiacSigns = [
      ZodiacTypes.CAPRICORN,
      ZodiacTypes.AQUARIUS,
      ZodiacTypes.PISCES,
      ZodiacTypes.ARIES,
      ZodiacTypes.TAURUS,
      ZodiacTypes.GEMINI,
      ZodiacTypes.CANCER,
      ZodiacTypes.LEO,
      ZodiacTypes.VIRGO,
      ZodiacTypes.LIBRA,
      ZodiacTypes.SCORPIO,
      ZodiacTypes.SAGITTARIUS,
    ];

    const zodiacBoundDates = [19, 18, 20, 19, 20, 20, 22, 22, 22, 22, 21, 21];

    const monthIndex = month - 1;
    const signMonthIndex = day <= zodiacBoundDates[monthIndex] ? monthIndex : (monthIndex + 1) % 12;

    if (!zodiacSigns[signMonthIndex]) return ZodiacTypes.CAPRICORN;

    return zodiacSigns[signMonthIndex];
  }

  static getZodiacCharacterTrait(zodiac: ZodiacTypes, gender: UserGender) {
    if (!zodiac || !gender) {
      return 'Innovative';
    }

    const zodiacCharacterTraitMap: Record<ZodiacTypes, Record<UserGender, string>> = {
      [ZodiacTypes.ARIES]: {
        male: 'Courageous',
        female: 'Dynamic',
        'non-binary': 'Dynamic',
      },
      [ZodiacTypes.TAURUS]: {
        male: 'Dependable',
        female: 'Graceful',
        'non-binary': 'Graceful',
      },
      [ZodiacTypes.GEMINI]: {
        male: 'Eloquent',
        female: 'Intelligent',
        'non-binary': 'Intelligent',
      },
      [ZodiacTypes.CANCER]: {
        male: 'Empathetic',
        female: 'Caring',
        'non-binary': 'Caring',
      },
      [ZodiacTypes.LEO]: {
        male: 'Generous',
        female: 'Radiant',
        'non-binary': 'Radiant',
      },
      [ZodiacTypes.VIRGO]: {
        male: 'Diligent',
        female: 'Kind',
        'non-binary': 'Kind',
      },
      [ZodiacTypes.LIBRA]: {
        male: 'Charming',
        female: 'Balanced',
        'non-binary': 'Balanced',
      },
      [ZodiacTypes.SCORPIO]: {
        male: 'Passionate',
        female: 'Resilient',
        'non-binary': 'Resilient',
      },
      [ZodiacTypes.SAGITTARIUS]: {
        male: 'Optimistic',
        female: 'Free-spirited',
        'non-binary': 'Free-spirited',
      },
      [ZodiacTypes.CAPRICORN]: {
        male: 'Ambitious',
        female: 'Elegant',
        'non-binary': 'Elegant',
      },
      [ZodiacTypes.AQUARIUS]: {
        male: 'Visionary',
        female: 'Innovative',
        'non-binary': 'Innovative',
      },
      [ZodiacTypes.PISCES]: {
        male: 'Compassionate',
        female: 'Intuitive',
        'non-binary': 'Intuitive',
      },
    };

    return zodiacCharacterTraitMap[zodiac][gender];
  }

  static getZodiacLuckyDays(zodiac: ZodiacTypes) {
    if (!zodiac) {
      return [22, 26, 30];
    }

    const zodiacLuckyDaysMap: Record<ZodiacTypes, [number, number, number]> = {
      [ZodiacTypes.ARIES]: [9, 18, 23],
      [ZodiacTypes.TAURUS]: [23, 28, 29],
      [ZodiacTypes.GEMINI]: [22, 26, 30],
      [ZodiacTypes.CANCER]: [1, 13, 21],
      [ZodiacTypes.LEO]: [2, 16, 27],
      [ZodiacTypes.VIRGO]: [1, 12, 21],
      [ZodiacTypes.LIBRA]: [15, 24, 27],
      [ZodiacTypes.SCORPIO]: [16, 24, 28],
      [ZodiacTypes.SAGITTARIUS]: [16, 23, 26],
      [ZodiacTypes.CAPRICORN]: [6, 24, 26],
      [ZodiacTypes.AQUARIUS]: [7, 26, 29],
      [ZodiacTypes.PISCES]: [2, 6, 9],
    };

    return zodiacLuckyDaysMap[zodiac];
  }
}
