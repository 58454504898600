import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class HomePageAppBannerClickEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class HomePageAppBannerClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof HomePageAppBannerClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_home_page_app_banner_click');
  }
}
