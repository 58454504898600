import { ExpertCatalogueIdleModalCancelEventHandler } from './ExpertCatalogueIdleModalCancelEvent';
import { ExpertCatalogueIdleModalClaimEventHandler } from './ExpertCatalogueIdleModalClaimEvent';
import { ExpertCatalogueIdleModalOpenEventHandler } from './ExpertCatalogueIdleModalOpenEvent';
import { ExpertsCatalogueFlowOpenEventHandler } from './ExpertsCatalogueFlowOpenedEvent';

export * from './ExpertCatalogueIdleModalOpenEvent';
export * from './ExpertCatalogueIdleModalClaimEvent';
export * from './ExpertCatalogueIdleModalCancelEvent';
export * from './ExpertsCatalogueFlowOpenedEvent';

export const expertCatalogueEventHandlers = [
  ExpertCatalogueIdleModalOpenEventHandler,
  ExpertCatalogueIdleModalClaimEventHandler,
  ExpertCatalogueIdleModalCancelEventHandler,
  ExpertsCatalogueFlowOpenEventHandler,
];
