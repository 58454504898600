import { BaseService } from 'src/core/common/services';
import { PackageProduct } from 'src/core/payments/entities';
import { User } from '../../entities';

type UserUpdateInfo = Partial<{
  birth_day: string;
  birth_place: string;
  gender: string;
  name: string;
  phone_number: string;
}>;

export class UserService extends BaseService {
  async activateFreeTrial(productId: PackageProduct): Promise<void> {
    await this.httpClient.post(`/free-trial/activate/${productId}`);
  }

  async updateUserInformation(data: UserUpdateInfo): Promise<void> {
    await this.httpClient.put('/user', {
      data,
    });
  }

  async getUser(): Promise<User> {
    const { data } = await this.httpClient.get<User>('/user');
    return data;
  }

  async changeEmail(email: string): Promise<void> {
    await this.httpClient.put('/user/account', {
      data: { email },
    });
  }

  async setEmailConsent(emailConsent: boolean) {
    return this.httpClient.put(`/user/settings`, {
      data: {
        email_consent: emailConsent,
      },
    });
  }
}
