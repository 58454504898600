import { PersistentStorageService } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { PreferredTheme } from 'src/core/theme/entities';
import { ThemeService } from 'src/core/theme/services';

export class ThemeServerService implements ThemeService {
  private persistentStorage: PersistentStorageService;
  private logger: Logger;

  constructor(persistentStorage: PersistentStorageService, logger: Logger) {
    this.persistentStorage = persistentStorage;
    this.logger = logger;
  }
  getInitialTheme() {
    return PreferredTheme.DARK;
  }

  async setTheme() {
    return;
  }
}
