import { useCallback, useState } from 'react';
import { LoadingStatuses } from 'src/core/common/entities';
import { ApiError, UnknownError } from 'src/core/common/errors';
import { useServices } from './useServices';
import { useLogger } from './useLogger';

export function useUpdateEmailConsent() {
  const [status, setStatus] = useState(LoadingStatuses.IDLE);

  const logger = useLogger();

  const { userService } = useServices();

  const fetchUpdateEmailConsent = useCallback(
    async (emailConsent: boolean) => {
      setStatus(LoadingStatuses.PENDING);
      try {
        await userService.setEmailConsent(emailConsent);
        setStatus(LoadingStatuses.FULFILLED);
      } catch (err) {
        const error = err instanceof ApiError ? err : new UnknownError(err);
        logger.error(error);
        setStatus(LoadingStatuses.FAILED);
      }
    },
    [userService, logger],
  );

  return {
    fetchUpdateEmailConsent,
    status,
  };
}
