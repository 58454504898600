import { AdvisorSinglePageOpenEventHandler } from '../events/AdvisorSinglePageOpenEvent';
import { AdvisorSinglePageBlogClickEventHandler } from '../events/AdvisorSinglePageBlogClickEvent';
import { AdvisorSinglePageSocialLinkClickEventHandler } from '../events/AdvisorSinglePageSocialLinkClickEvent';
import { AdvisorSinglePageOtherLinkClickEventHandler } from '../events/AdvisorSinglePageOtherLinkClickEvent';

export * from '../events/AdvisorSinglePageOpenEvent';
export * from '../events/AdvisorSinglePageBlogClickEvent';
export * from '../events/AdvisorSinglePageSocialLinkClickEvent';
export * from '../events/AdvisorSinglePageOtherLinkClickEvent';

export const advisorSinglePageEventHandlers = [
  AdvisorSinglePageOpenEventHandler,
  AdvisorSinglePageBlogClickEventHandler,
  AdvisorSinglePageSocialLinkClickEventHandler,
  AdvisorSinglePageOtherLinkClickEventHandler,
];
