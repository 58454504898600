import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type FooterLinkClickEventPayload = {
  link: string;
};

export class FooterLinkClickEvent extends BaseEvent<FooterLinkClickEventPayload> {}

export class FooterClickEventHandler extends BaseEventHandler<FooterLinkClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof FooterLinkClickEvent;
  }

  async track(event: FooterLinkClickEvent) {
    const { link } = event.getPayload();

    return this.amplitudeService.logEvent('seo_footer_link_click', {
      link,
    });
  }
}
