import { generateUuid } from 'src/core/common/utils/generateUuid';
import { HttpClient, HttpRequestConfig } from './HttpClient';

export class WithRequestId implements HttpClient {
  constructor(private readonly httpClient: HttpClient) {}

  onUnauthorized(listener: (reason: unknown) => void): void {
    return this.httpClient.onUnauthorized(listener);
  }

  get<ResponseData = any, ResponseMeta = any>(
    url: string,
    config: HttpRequestConfig = {},
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    return this.httpClient.get(url, {
      ...config,
      headers: {
        'x-request-id': generateUuid(),
        ...config.headers,
      },
    });
  }

  post<ResponseData = any, ResponseMeta = any>(
    url: string,
    config: HttpRequestConfig = {},
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    return this.httpClient.post(url, {
      ...config,
      headers: {
        'x-request-id': generateUuid(),
        ...config.headers,
      },
    });
  }

  put<ResponseData = any, ResponseMeta = any>(
    url: string,
    config: HttpRequestConfig = {},
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    return this.httpClient.put(url, {
      ...config,
      headers: {
        'x-request-id': generateUuid(),
        ...config.headers,
      },
    });
  }
}
