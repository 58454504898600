import { RootInitialState } from 'src/core/common/store/rootReducer';
import { ExpertId } from 'src/core/experts/entities';

export const getIncomingMessage = (expertId: ExpertId) => (state: RootInitialState) => {
  const isChatExist = !!state.chat.entities[expertId];

  if (!isChatExist) return '';

  return state.chat.entities[expertId].incomingMessage;
};

export const getOutgoingMessage = (expertId: ExpertId) => (state: RootInitialState) => {
  const isChatExist = !!state.chat.entities[expertId];

  if (!isChatExist) return '';

  return state.chat.entities[expertId].outgoingMessage;
};

export const getChatCount = (state: RootInitialState) => state.chat.chatCount;

export const getHasChatSessionEverBeen = (expertId: ExpertId) => (state: RootInitialState) => {
  return state.chat.sessions.includes(expertId);
};
