import { v4 as uuidv4 } from 'uuid';
import { CookiesService } from 'src/core/common/services';
import { normalizeError } from 'src/core/common/utils/errors';
import { Logger } from 'src/core/common/logger/interfaces';
import { DAYS_IN_YEAR } from 'src/core/common/utils/date';
import { Config } from 'src/config';
import { FeatureRawResult, FeatureValue } from '../entities';
import { FeatureFlags } from './FeatureFlags';
import { FeatureFlagsDecorator } from './FeatureFlagsDecorator';

export class CookiesIdFeatureFlagsDecorator extends FeatureFlagsDecorator {
  private cookies: CookiesService;

  private logger: Logger;

  private config: Config;

  private expires = DAYS_IN_YEAR;

  readonly storageKey = 'split_id';

  private currentIdentifier = '';

  constructor(featureFlags: FeatureFlags, cookies: CookiesService, config: Config, logger: Logger) {
    super(featureFlags);
    this.cookies = cookies;
    this.logger = logger;
    this.config = config;
  }

  getExperimentGroup<T extends FeatureValue>(experimentName: string): T | null {
    this.maybeUpdateFeatureFlagsId();
    return super.getExperimentGroup(experimentName);
  }

  getFeatureValue<T extends FeatureValue = FeatureValue>(featureName: string, defaultValue: T): T {
    this.maybeUpdateFeatureFlagsId();
    return super.getFeatureValue(featureName, defaultValue);
  }

  getFeatureRawResult<T extends FeatureValue>(featureName: string): FeatureRawResult<T | null> {
    this.maybeUpdateFeatureFlagsId();
    return super.getFeatureRawResult(featureName);
  }

  isFeatureOn(featureName: string): boolean {
    this.maybeUpdateFeatureFlagsId();
    return super.isFeatureOn(featureName);
  }

  isFeatureOff(featureName: string): boolean {
    this.maybeUpdateFeatureFlagsId();
    return super.isFeatureOff(featureName);
  }

  private maybeUpdateFeatureFlagsId() {
    const newIdentifier = this.getIdentifier();

    if (newIdentifier === this.currentIdentifier) return;

    this.currentIdentifier = newIdentifier;
    super.setIdentifier(newIdentifier);
  }

  private getIdentifier() {
    const identifier = this.getIdentifierFromCookies();

    if (identifier) {
      return identifier;
    }

    return this.createIdentifier();
  }

  private createIdentifier() {
    const identifier = uuidv4();

    try {
      this.cookies.set(this.storageKey, identifier, {
        expires: this.expires,
        domain: this.config.domainRoot,
      });
      return identifier;
    } catch (e) {
      const err = normalizeError(e);

      this.logger.error('Failed to save identifier', {
        data: {
          errorMessage: err.message,
        },
      });

      return identifier;
    }
  }

  private getIdentifierFromCookies(): string {
    try {
      return this.cookies.get(this.storageKey) || '';
    } catch (e) {
      const err = normalizeError(e);

      this.logger.error('Failed to load identifier from storage', {
        data: {
          errorMessage: err.message,
        },
      });
      return '';
    }
  }
}
