import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type AdvisorSinglePageOpenEventPayload = {
  name: string;
};

export class AdvisorSinglePageOpenEvent extends BaseEvent<AdvisorSinglePageOpenEventPayload> {}

export class AdvisorSinglePageOpenEventHandler extends BaseEventHandler<AdvisorSinglePageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof AdvisorSinglePageOpenEvent;
  }

  async track(event: AdvisorSinglePageOpenEvent) {
    const { name } = event.getPayload();

    return this.amplitudeService.logEvent('seo_advisor_page_open', {
      name,
    });
  }
}
