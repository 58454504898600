import { Log, LogsBatch, LogsBatchId, SerializedLogsBatch } from '../../entities';
import { LogsBatchCache } from '../../interfaces';
import { LogsBatchCacheDecorator } from './LogsBatchCacheDecorator';

type LogsBatchCacheWithLocalStorageConfig = {
  storageKey: string;
};

export class LogsBatchCacheWithLocalStorage extends LogsBatchCacheDecorator {
  private config: LogsBatchCacheWithLocalStorageConfig;

  constructor(
    logsBatchCache: LogsBatchCache,
    config: Partial<LogsBatchCacheWithLocalStorageConfig> = {},
  ) {
    const defaultConfig: LogsBatchCacheWithLocalStorageConfig = {
      storageKey: 'nebula_quiz_logs_batch',
    };

    super(logsBatchCache);
    this.config = { ...config, ...defaultConfig };

    this.preloadBatches();
  }

  addPendingLog(log: Log) {
    super.addPendingLog(log);

    this.syncWithStorage();
  }

  dropBatch(logsBatchId: LogsBatchId) {
    super.dropBatch(logsBatchId);

    this.syncWithStorage();
  }

  saveBatch(batch: LogsBatch) {
    super.saveBatch(batch);

    this.syncWithStorage();
  }

  private preloadBatches() {
    try {
      const batchesJson = localStorage.getItem(this.getStorageKey());

      if (!batchesJson) return;

      const serializedBatches: Array<SerializedLogsBatch> = JSON.parse(batchesJson);
      const deserializedBatches = serializedBatches.map((serializedBatch) =>
        LogsBatch.deserialize(serializedBatch),
      );

      deserializedBatches.map((batch) => super.saveBatch(batch));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  private syncWithStorage() {
    try {
      const currentBatches = super.getBatches();
      const serializedBatches = currentBatches.map((batch) => batch.serialize());
      const batchesJson = JSON.stringify(serializedBatches);

      localStorage.setItem(this.getStorageKey(), batchesJson);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  private getStorageKey() {
    return this.config.storageKey;
  }
}
