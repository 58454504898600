import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class CatalogueMainFindAdvisorClickEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class CatalogueMainFindAdvisorClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueMainFindAdvisorClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_catalogue_page_find_advisor_click');
  }
}
