import { useCallback, useState } from 'react';
import { LoadingStatuses } from 'src/core/common/entities';
import { PackageProduct } from 'src/core/payments/entities';
import { ApiError, UnknownError } from 'src/core/common/errors';
import { useLogger } from './useLogger';
import { useServices } from './useServices';

export const useActivateTrial = () => {
  const { userService } = useServices();
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.IDLE);
  const [error, setError] = useState('');

  const logger = useLogger();

  const activateFreeTrial = useCallback(
    async (product: PackageProduct) => {
      if (loadingStatus === LoadingStatuses.PENDING) return;

      clearState();

      try {
        setLoadingStatus(LoadingStatuses.PENDING);
        await userService.activateFreeTrial(product);
        setLoadingStatus(LoadingStatuses.FULFILLED);
      } catch (err) {
        if (err instanceof ApiError) {
          setError(err.originalError.message || '');
          logger.error(err);
        } else {
          setError('Something went wrong. Try again later');
          logger.error(new UnknownError(err));
        }
        setLoadingStatus(LoadingStatuses.FAILED);
      }
    },
    [loadingStatus, userService, logger],
  );

  const clearState = () => {
    setLoadingStatus(LoadingStatuses.IDLE);
    setError('');
  };

  return {
    activateFreeTrial,
    loadingStatus,
    error,
  };
};
