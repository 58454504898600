import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type ChartSinglePageOpenEventPayload = {
  chart: string;
};

export class ChartSinglePageOpenEvent extends BaseEvent<ChartSinglePageOpenEventPayload> {}

export class ChartSinglePageOpenEventHandler extends BaseEventHandler<ChartSinglePageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ChartSinglePageOpenEvent;
  }

  async track(event: ChartSinglePageOpenEvent) {
    const { chart } = event.getPayload();

    return this.amplitudeService.logEvent('seo_chart_page_open', {
      chart,
    });
  }
}
