import { Provider } from 'react-redux';
import { ReactNode } from 'react';
import { useApp } from 'src/core/common/hooks';
import ErrorBoundary from 'src/core/common/components/common/ErrorBoundary';
import ErrorPage from 'src/core/common/components/lp/Error';
import ServicesProvider from 'src/core/common/context';
import { FeatureFlagsProvider } from 'src/core/feature-flags/context';
import AppLoader from 'src/core/common/components/common/AppLoader';
import IconGradientPreloader from 'src/core/svg-sprites/ui/molecules/IconGradientPreloader';
import styles from './PageLayout.module.scss';

type PageLayoutProps = {
  children: ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => {
  const { store, services, featureFlags, isLoaderVisible, featureFlagsLoadingStatus } = useApp();

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Provider store={store}>
        <ServicesProvider value={services}>
          <FeatureFlagsProvider
            featureFlags={featureFlags}
            featureFlagsLoadingStatus={featureFlagsLoadingStatus}
          >
            <main className={styles.container}>
              <IconGradientPreloader />
              {children}
              {isLoaderVisible && <AppLoader />}
            </main>
          </FeatureFlagsProvider>
        </ServicesProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default PageLayout;
