import dayJs, { Dayjs, OptionType } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayJs.extend(localizedFormat);
dayJs.extend(advancedFormat);

export const formatDate = (date: number | string | Date, format: string) =>
  dayJs(date).format(format);

export const createDate = (
  date?: string | number | Date | Dayjs | null | undefined,
  format?: OptionType,
  locale?: string,
  strict?: boolean,
) => {
  return dayJs(date, format, locale, strict);
};

export const validateDate = (date: string): boolean => {
  return dayJs(date).isValid();
};

export const DAYS_IN_YEAR = 365;
export const HOUR_IN_SECONDS = 3600;

export const isBefore = (dateNow: number, dateToCompare: number) => {
  return dayJs(dateNow).isBefore(dayJs(dateToCompare));
};

export const convertNumericMonthToString = (month: number) => dayJs().month(month).format('MMM');

export const getCurrentYear = () => new Date().getFullYear();

export const isValidDate = (
  date: string | number | Date | Dayjs | null | undefined,
): date is Date => dayJs(date).isValid();

export const isSameDay = (date1: string, date2: string) => dayJs(date1).isSame(date2, 'day');
