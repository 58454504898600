import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type ZodiacTraitsMainPageCompatibilityClickEventPayload = {
  zodiac: ZodiacTypes;
  zodiacToCompare: ZodiacTypes;
};

export class ZodiacTraitsMainPageCompatibilityClickEvent extends BaseEvent<ZodiacTraitsMainPageCompatibilityClickEventPayload> {}

export class ZodiacTraitsMainPageCompatibilityClickEventHandler extends BaseEventHandler<ZodiacTraitsMainPageCompatibilityClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacTraitsMainPageCompatibilityClickEvent;
  }

  async track(event: ZodiacTraitsMainPageCompatibilityClickEvent) {
    const { zodiac, zodiacToCompare } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_traits_main_page_compatibility_click', {
      zodiac,
      zodiacToCompare,
    });
  }
}
