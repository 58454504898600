import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class SupportPageOpenEvent extends BaseEvent {}

export class SupportPageOpenEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SupportPageOpenEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_support_page_opened');
  }
}
