import { useCallback, useState } from 'react';
import { useLogger, useServices } from 'src/core/common/hooks';
import { UnknownError } from 'src/core/common/errors';
import { LoadingStatuses, SuggestedLocation } from 'src/core/common/entities';
import { throttle } from 'src/core/common/utils/throttle';

export function useSuggestedLocations({ fetchTimeout } = { fetchTimeout: 500 }) {
  const [suggestedLocations, setSuggestedLocations] = useState<SuggestedLocation[]>([]);
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatuses.IDLE);
  const [error, setError] = useState('');

  const { geoService } = useServices();
  const logger = useLogger();

  const fetchSuggestedLocations = useCallback(
    throttle(fetchTimeout, async (location: string) => {
      try {
        setError('');
        setLoadingStatus(LoadingStatuses.PENDING);
        const features = await geoService.getSuggestedLocations(location);
        setSuggestedLocations(features);
        setLoadingStatus(LoadingStatuses.FULFILLED);
      } catch (e) {
        const err = e instanceof Error ? e : new UnknownError(e);

        logger.error(err);
        setLoadingStatus(LoadingStatuses.FAILED);
        setError(err.message);
      }
    }),
    [geoService, logger, fetchTimeout],
  );

  return {
    suggestedLocations,
    fetchSuggestedLocations,
    loadingStatus,
    error,
  };
}
