import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type ReviewsSinglePageOpenEventPayload = {
  competitor: string;
};

export class ReviewsSinglePageOpenEvent extends BaseEvent<ReviewsSinglePageOpenEventPayload> {}

export class ReviewsSinglePageOpenEventHandler extends BaseEventHandler<ReviewsSinglePageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ReviewsSinglePageOpenEvent;
  }

  async track(event: ReviewsSinglePageOpenEvent) {
    const { competitor } = event.getPayload();
    return this.amplitudeService.logEvent('seo_reviews_page_open', { competitor });
  }
}
