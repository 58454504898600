import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type BlogSinglePageTarotReadMoreClickEventPayload = {
  blog: string;
  card: string;
};

export class BlogSinglePageTarotReadMoreClickEvent extends BaseEvent<BlogSinglePageTarotReadMoreClickEventPayload> {}

export class BlogSinglePageTarotReadMoreClickEventHandler extends BaseEventHandler<BlogSinglePageTarotReadMoreClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogSinglePageTarotReadMoreClickEvent;
  }

  async track(event: BlogSinglePageTarotReadMoreClickEvent) {
    const { blog, card } = event.getPayload();

    return this.amplitudeService.logEvent('seo_blog_page_tarot_read_more_click', {
      blog: convertToSnakeCase(blog),
      card: convertToSnakeCase(card),
    });
  }
}
