import { ReactNode } from 'react';
import { LocationCountry } from '../../entities';

export type LocationCountryCaseProps = {
  value?: LocationCountry | LocationCountry[];
  children?: ReactNode;
  default?: boolean;
};

const LocationCountryCase = ({ children }: LocationCountryCaseProps) => {
  return <>{children}</>;
};

export default LocationCountryCase;
