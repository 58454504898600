import { Logger, LogsBatchCache } from '../interfaces';
import { BatchDestination, BatchLoggerTransport } from './batch-logger-transport';
import { ConsolaLogger } from './ConsolaLogger';
import { ConsoleLoggerTransport } from './ConsoleLoggerTransport';

export class LoggerBuilder {
  private readonly logger: Logger;

  constructor() {
    this.logger = new ConsolaLogger();
  }

  addConsoleTransport() {
    this.logger.addTransport(new ConsoleLoggerTransport());
    return this;
  }

  addBatchTransport(destination: BatchDestination, cache: LogsBatchCache) {
    this.logger.addTransport(new BatchLoggerTransport(destination, cache));
    return this;
  }

  addContext(context: Record<string, unknown> = {}) {
    this.logger.updateContext(context);
    return this;
  }

  getResult(): Logger {
    return this.logger;
  }
}
