import { ChatBotAnswers, ChatBotName, Scenario, Step, StepId } from '../entities';

export class ChatBotService {
  private scenarios = {
    [ChatBotName.CHAT_BOT]: {},
  };

  getScenario(name: ChatBotName): Scenario<ChatBotAnswers> {
    return this.scenarios[name] as Scenario<ChatBotAnswers>;
  }

  getStep(chatBotName: ChatBotName, stepId?: StepId): Step<ChatBotAnswers> | null {
    const scenario = this.getScenario(chatBotName);
    return scenario.getStep(stepId);
  }
}
