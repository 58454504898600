import { RootInitialState } from 'src/core/common/store/rootReducer';

export const getExperts = (state: RootInitialState) => {
  return Object.values(state.experts.data);
};

export const getInitialExpertsStatus = (state: RootInitialState) =>
  state.experts.loadingInitialExpertsStatus;

export const getExpertsStatus = (state: RootInitialState) => state.experts.loadingStatus;

export const getExpertReviews = (state: RootInitialState) => state.experts.expertReview.reviews;

export const getReviewsStatus = (state: RootInitialState) => {
  return state.experts.expertReview.loadingStatus;
};

export const getSelectedExpert = (state: RootInitialState) => state.experts.currentExpert;

export const getRandomExpert = (state: RootInitialState) => state.experts.randomExpert.info;

export const getRandomExpertReviews = (state: RootInitialState) =>
  state.experts.randomExpert.reviews;

export const getRandomExpertStatus = (state: RootInitialState) =>
  state.experts.randomExpert.loadingStatus;
