import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { SingleAdvisorSocialItem } from 'src/advisors/entities';

export type AdvisorSinglePageSocialLinkClickEventPayload = {
  name: string;
  social: SingleAdvisorSocialItem['type'];
};

export class AdvisorSinglePageSocialLinkClickEvent extends BaseEvent<AdvisorSinglePageSocialLinkClickEventPayload> {}

export class AdvisorSinglePageSocialLinkClickEventHandler extends BaseEventHandler<AdvisorSinglePageSocialLinkClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof AdvisorSinglePageSocialLinkClickEvent;
  }

  async track(event: AdvisorSinglePageSocialLinkClickEvent) {
    const { name, social } = event.getPayload();

    return this.amplitudeService.logEvent('seo_advisor_page_social_link_click', {
      name,
      social,
    });
  }
}
