import { ApiError } from './ApiError';

export class AccountExistError extends ApiError {
  public message: string;

  constructor(err: ApiError) {
    super(err);
    this.message = 'Account exists';
  }
}
