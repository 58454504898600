import { ZodiacService } from 'src/core/zodiac/services';
import { LoadingStatuses } from 'src/core/common/entities';
import { RootInitialState } from 'src/core/common/store/rootReducer';
import { UserGender } from '../../entities';

export const getUserPreferences = (state: RootInitialState) => state.user;

export const getZodiac = (state: RootInitialState) => {
  return ZodiacService.getZodiacSignByDate(state.user.date);
};

export const getZodiacCharacterTrait = (state: RootInitialState) => {
  const zodiac = getZodiac(state);
  return ZodiacService.getZodiacCharacterTrait(zodiac, state.user.gender as UserGender);
};

export const getZodiacLuckyDays = (state: RootInitialState) => {
  const zodiac = getZodiac(state);
  return ZodiacService.getZodiacLuckyDays(zodiac);
};

export const getIsRegistrationDone = (state: RootInitialState) =>
  state.user.registrationStatus === LoadingStatuses.FULFILLED;

export const getGender = (state: RootInitialState) => state.user.gender;

export const getSignUpError = (state: RootInitialState) => state.user.signUpError;

export const getSignInError = (state: RootInitialState) => state.user.signInError;

export const getSignInByTokenStatus = (state: RootInitialState) => state.user.signInByTokenStatus;

export const getGoal = (state: RootInitialState) => state.user.goal;

export const getUserEmail = (state: RootInitialState) => state.user.email;

export const getUserFunnel = (state: RootInitialState) => state.user.funnel;
