import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type CatalogueCategoryExpertCardStartChatClickEventPayload = {
  landing: string;
};

export class CatalogueCategoryExpertCardStartChatClickEvent extends BaseEvent<CatalogueCategoryExpertCardStartChatClickEventPayload> {}

export class CatalogueCategoryExpertCardStartChatClickEventHandler extends BaseEventHandler<CatalogueCategoryExpertCardStartChatClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueCategoryExpertCardStartChatClickEvent;
  }

  async track(event: CatalogueCategoryExpertCardStartChatClickEvent) {
    const { landing } = event.getPayload();

    this.googleAnalyticsService.trackGA4({
      name: 'click_from_seo',
    });

    return this.amplitudeService.logEvent('seo_landing_page_start_chat_click', {
      landing: convertToSnakeCase(landing),
    });
  }
}
