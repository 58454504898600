import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type NativeAppPageAppClickEventPayload = {
  context: 'ios' | 'android';
};

export class NativeAppPageAppClickEvent extends BaseEvent<NativeAppPageAppClickEventPayload> {}

export class NativeAppPageAppClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof NativeAppPageAppClickEvent;
  }

  async track(event: NativeAppPageAppClickEvent) {
    const { context } = event.getPayload();

    return this.amplitudeService.logEvent('seo_app_page_app_button_click', {
      context,
    });
  }
}
