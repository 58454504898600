import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type FooterAppClickEventPayload = {
  context: 'ios' | 'android';
};

export class FooterAppClickEvent extends BaseEvent<FooterAppClickEventPayload> {}

export class FooterAppClickEventHandler extends BaseEventHandler<FooterAppClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof FooterAppClickEvent;
  }

  async track(event: FooterAppClickEvent) {
    const { context } = event.getPayload();

    return this.amplitudeService.logEvent('seo_footer_app_button_click', {
      context,
    });
  }
}
