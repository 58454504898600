import { ClarityHeatmapRecordingService } from './ClarityHeatmapRecordingService';

export class ClarityHeatmapRecordingServerService implements ClarityHeatmapRecordingService {
  identifyUser() {
    return false;
  }
  giveCookieConsent() {
    return false;
  }

  triggerCustomEvent() {
    return false;
  }

  addCustomTag() {
    return false;
  }

  markImportantSession() {
    return false;
  }

  abort() {
    return false;
  }
}
