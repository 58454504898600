import { FeatureFlagsConfigLoadingFailedEventHandler } from './FeatureFlagsConfigLoadingFailedEvent';
import { FeatureFlagsConfigLoadedEventHandler } from './FeatureFlagsConfigLoadedEvent';

export * from './FeatureFlagsConfigLoadingFailedEvent';
export * from './FeatureFlagsConfigLoadedEvent';

export const featureFlagsEventHandlers = [
  FeatureFlagsConfigLoadingFailedEventHandler,
  FeatureFlagsConfigLoadedEventHandler,
];
