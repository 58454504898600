import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type HeaderLinkClickEventPayload = {
  link: string;
};

export class HeaderLinkClickEvent extends BaseEvent<HeaderLinkClickEventPayload> {}

export class HeaderLinkClickEventHandler extends BaseEventHandler<HeaderLinkClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof HeaderLinkClickEvent;
  }

  async track(event: HeaderLinkClickEvent) {
    const { link } = event.getPayload();

    return this.amplitudeService.logEvent('seo_header_link_click', {
      link,
    });
  }
}
