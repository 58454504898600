import { SimpleRegistrationFlowOpenEventHandler } from './SimpleRegistrationFlowOpenedEvent';
import { SimpleRegistrationMainPageExThemeClickedEventHandler } from './SimpleRegistrationMainPageExThemeClickedEvent';
import { SimpleRegistrationMainPageExThemeOpenedEventHandler } from './SimpleRegistrationMainPageExThemeOpenedEvent';
import { SimpleRegistrationMainPageExpertsClickedEventHandler } from './SimpleRegistrationMainPageExpertsClickedEvent';
import { SimpleRegistrationMainPageExpertsOpenedEventHandler } from './SimpleRegistrationMainPageExpertsOpenedEvent';
import { SimpleRegistrationMainPageGenderClickedEventHandler } from './SimpleRegistrationMainPageGenderClickedEvent';
import { SimpleRegistrationMainPageGenderOpenedEventHandler } from './SimpleRegistrationMainPageGenderOpenedEvent';

export * from './SimpleRegistrationFlowOpenedEvent';
export * from './SimpleRegistrationMainPageExThemeClickedEvent';
export * from './SimpleRegistrationMainPageExThemeOpenedEvent';
export * from './SimpleRegistrationMainPageExpertsClickedEvent';
export * from './SimpleRegistrationMainPageExpertsOpenedEvent';
export * from './SimpleRegistrationMainPageGenderClickedEvent';
export * from './SimpleRegistrationMainPageGenderOpenedEvent';

export const simpleRegistrationEventHandlers = [
  SimpleRegistrationFlowOpenEventHandler,
  SimpleRegistrationMainPageGenderOpenedEventHandler,
  SimpleRegistrationMainPageExpertsOpenedEventHandler,
  SimpleRegistrationMainPageExThemeOpenedEventHandler,
  SimpleRegistrationMainPageGenderClickedEventHandler,
  SimpleRegistrationMainPageExpertsClickedEventHandler,
  SimpleRegistrationMainPageExThemeClickedEventHandler,
];
