import { RootInitialState } from './rootReducer';

export const loadProgressFromStorage = ():
  | Omit<RootInitialState, 'cookiesAgreement'>
  | undefined => {
  try {
    const serializedStorage = sessionStorage.getItem('askNebula');
    if (serializedStorage === null) {
      return undefined;
    }
    return JSON.parse(serializedStorage);
  } catch (err) {
    return undefined;
  }
};

export const saveProgressToStorage = (store: Omit<RootInitialState, 'cookiesAgreement'>): void => {
  try {
    const serializedStorage = JSON.stringify(store);
    sessionStorage.setItem('askNebula', serializedStorage);
  } catch (e) {
    throw new Error('store serialization failed');
  }
};
