import { RootInitialState } from 'src/core/common/store/rootReducer';
import { getIsTestingEnv, getSystemPlatform } from 'src/core/common/store/system';
import { getGender, getUserFunnel, getUtmSource } from 'src/core/user/store/selectors';
import { FeatureFlagsAttributes } from '../entities';

export const getFeatureFlagsAttributes = (state: RootInitialState): FeatureFlagsAttributes => {
  const gender = getGender(state);
  const isTestingEnv = getIsTestingEnv(state);
  const platform = getSystemPlatform(state);
  const utmSource = getUtmSource(state);
  const funnel = getUserFunnel(state);

  return {
    gender,
    isTestingEnv,
    utmSource,
    platform,
    funnel,
  };
};
