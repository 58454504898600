import { QUIZ_ANALYTIC_EVENTS } from 'src/core/analytics/events';
import { InputControl } from './inputControl';

export type StepId = string;

type Milliseconds = number;

export enum StepType {
  BOT = 'bot',
  USER = 'user',
  SPLIT = 'split',
  CREATE_ACCOUNT = 'create-account',
}

interface BaseStep {
  next?: StepId;
  analytic?: Partial<{
    baseEvent: QUIZ_ANALYTIC_EVENTS;
    baseEventProperties: Record<string, unknown>;
  }>;
}

export interface BotStep<State> extends BaseStep {
  type: StepType.BOT;
  message: string | ((state: State) => string);
  typingDuration: Milliseconds;
}

export interface UserStep<State> extends BaseStep {
  type: StepType.USER;
  variable?: keyof State;
  control: InputControl;
}

export interface SplitStep<State> extends BaseStep {
  type: StepType.SPLIT;
  getNextBasedOnState: (state: State) => StepId;
}

export interface CreateAccountStep extends BaseStep {
  type: StepType.CREATE_ACCOUNT;
}

export type Step<State> = BotStep<State> | UserStep<State> | SplitStep<State> | CreateAccountStep;
