import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type ZodiacCompatibilitySinglePageCtaClickEventPayload = {
  zodiac: ZodiacTypes;
};

export class ZodiacCompatibilitySinglePageCtaClickEvent extends BaseEvent<ZodiacCompatibilitySinglePageCtaClickEventPayload> {}

export class ZodiacCompatibilitySinglePageCtaClickEventHandler extends BaseEventHandler<ZodiacCompatibilitySinglePageCtaClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacCompatibilitySinglePageCtaClickEvent;
  }

  async track(event: ZodiacCompatibilitySinglePageCtaClickEvent) {
    const { zodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_compatibility_page_cta_click', {
      zodiac,
    });
  }
}
