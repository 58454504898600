import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type CatalogueMainTopicClickEventPayload = {
  topic: string;
};

export class CatalogueMainTopicClickEvent extends BaseEvent<CatalogueMainTopicClickEventPayload> {}

export class CatalogueMainTopicClickEventHandler extends BaseEventHandler<CatalogueMainTopicClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueMainTopicClickEvent;
  }

  async track(event: CatalogueMainTopicClickEvent) {
    const { topic } = event.getPayload();

    return this.amplitudeService.logEvent('seo_catalogue_page_topic_click', {
      click_to: topic,
    });
  }
}
