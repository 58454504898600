import { GlossaryMainPageOpenEventHandler } from './GlossaryMainPageOpenEvent';
import { GlossaryMainPageBannerClickEventHandler } from './GlossaryMainPageBannerClickEvent';
import { GlossaryMainPageLinkClickEventHandler } from './GlossaryMainPageLinkClickEvent';
import { GlossaryMainPageTarotSuitClickEventHandler } from './GlossaryMainPageTarotSuitClickEvent';
import { GlossaryMainPageTarotCardClickEventHandler } from './GlossaryMainPageTarotCardClickEvent';
import { GlossaryMainPageAngelNumberClickEventHandler } from './GlossaryMainPageAngelNumberClickEvent';

export * from './GlossaryMainPageOpenEvent';
export * from './GlossaryMainPageBannerClickEvent';
export * from './GlossaryMainPageLinkClickEvent';
export * from './GlossaryMainPageTarotSuitClickEvent';
export * from './GlossaryMainPageTarotCardClickEvent';
export * from './GlossaryMainPageAngelNumberClickEvent';

export const glossaryMainPageEventHandlers = [
  GlossaryMainPageOpenEventHandler,
  GlossaryMainPageBannerClickEventHandler,
  GlossaryMainPageLinkClickEventHandler,
  GlossaryMainPageTarotSuitClickEventHandler,
  GlossaryMainPageTarotCardClickEventHandler,
  GlossaryMainPageAngelNumberClickEventHandler,
];
