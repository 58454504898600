import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class GlossaryRootPageCtaClickEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class GlossaryRootPageCtaClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossaryRootPageCtaClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_meaning_page_cta_click');
  }
}
