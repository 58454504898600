import { ReactElement } from 'react';
import { useLocation } from '../../hooks';

export type LocationRegionSwitchProps = {
  children: ReactElement[] | ReactElement;
};

const LocationRegionSwitch = (props: LocationRegionSwitchProps) => {
  const { region } = useLocation();

  const renderChildren = (child: ReactElement) => {
    if (child.props.value === region) {
      return child;
    }

    if (child.props.default) {
      return child;
    }

    return null;
  };

  const renderArrayChildren = (children: ReactElement[]) => {
    const foundMatchedChildren = children.filter((child) => child.props.value === region);

    if (foundMatchedChildren.length) {
      return foundMatchedChildren;
    }

    const defaultChildren = children.filter((child) => child.props.default);

    if (defaultChildren.length) {
      return defaultChildren;
    }

    return null;
  };

  const content = Array.isArray(props.children)
    ? renderArrayChildren(props.children)
    : renderChildren(props.children);

  return <>{content}</>;
};

export default LocationRegionSwitch;
