import { ReactNode } from 'react';
import Header from 'src/core/common/components/layout/Header';
import styles from './DefaultLayout.module.scss';

export type DefaultLayoutProps = {
  children?: ReactNode;
  onLogoClick?: () => void;
};

const DefaultLayout = ({ children, onLogoClick }: DefaultLayoutProps) => (
  <div className={styles.container}>
    <Header onLogoClick={onLogoClick} />
    <div className={styles.content}>{children}</div>
  </div>
);

export default DefaultLayout;
