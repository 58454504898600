import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type ChartSinglePageStartQuizClickEventPayload = {
  chart: string;
};

export class ChartSinglePageStartQuizClickEvent extends BaseEvent<ChartSinglePageStartQuizClickEventPayload> {}

export class ChartSinglePageStartQuizClickEventHandler extends BaseEventHandler<ChartSinglePageStartQuizClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ChartSinglePageStartQuizClickEvent;
  }

  async track(event: ChartSinglePageStartQuizClickEvent) {
    const { chart } = event.getPayload();

    return this.amplitudeService.logEvent('seo_chart_page_start_quiz_click', {
      chart,
    });
  }
}
