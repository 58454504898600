import { appendQueryParams } from 'src/core/common/utils/url/appendQueryParams';
import { useServices } from 'src/core/common/hooks/useServices';
import { normalizeError } from 'src/core/common/utils/errors';
import { checkIsClientSide } from 'src/core/common/utils/checkIsClientSide';

export const useRedirectWithDeviceId = () => {
  const { analyticsService, logger } = useServices();

  const redirectWithDeviceId = async (url: string) => {
    if (!checkIsClientSide()) return;

    try {
      const deviceId = await analyticsService.getDeviceId();

      const URLToRedirect = new URL(url);
      const paramsObj = {
        amp_device_id: deviceId,
      };

      const URLToRedirectWithParams = appendQueryParams(URLToRedirect, paramsObj);
      window.location.assign(URLToRedirectWithParams.href);
    } catch (e) {
      const error = normalizeError(e);
      logger.error(error);
    }
  };

  return { redirectWithDeviceId };
};
