import { mainAppRoutes } from 'src/core/common/constants/routes';

export function useRedirectToMainApp() {
  function getCookiePolicyPagePath() {
    return mainAppRoutes.cookiePolicy;
  }

  function getTermsPagePath() {
    return mainAppRoutes.terms;
  }

  function getPrivacyPolicyPagePath() {
    return mainAppRoutes.privacyPolicy;
  }

  function getMoneyBackPolicyPagePath() {
    return mainAppRoutes.moneyBackPolicy;
  }

  function getFAQPagePath() {
    return mainAppRoutes.FAQ;
  }

  function getPersonalInfoPolicyPagePath() {
    return mainAppRoutes.personalInfoPolicy;
  }

  function getPaymentTermsPagePath() {
    return mainAppRoutes.paymentTerms;
  }

  function getLiveChatRulesPath() {
    return mainAppRoutes.liveChatRules;
  }

  function getSupportPath() {
    return mainAppRoutes.support;
  }

  function getLoginPath() {
    return mainAppRoutes.login;
  }

  function redirectToExpertsPage() {
    window.location.assign(mainAppRoutes.experts);
  }

  async function redirectToSignupCreditsPage() {
    await window.location.assign(mainAppRoutes.signupCredits);
  }

  async function redirectToSignupFreeTrialPage() {
    await window.location.assign(mainAppRoutes.signupFreeTrial);
  }

  async function redirectToExpertChat(expertId: string) {
    await window.location.assign(`${mainAppRoutes.expertChat}${expertId}`);
  }

  async function redirectToCommandsPage(command: string) {
    await window.location.assign(`${mainAppRoutes.commands}${command}`);
  }

  async function redirectToLoginPage() {
    await window.location.assign(mainAppRoutes.login);
  }

  return {
    redirectToExpertsPage,
    getCookiePolicyPagePath,
    getTermsPagePath,
    getPrivacyPolicyPagePath,
    getMoneyBackPolicyPagePath,
    getFAQPagePath,
    getPaymentTermsPagePath,
    getLiveChatRulesPath,
    getPersonalInfoPolicyPagePath,
    getLoginPath,
    redirectToLoginPage,
    redirectToExpertChat,
    getSupportPath,
    redirectToSignupCreditsPage,
    redirectToSignupFreeTrialPage,
    redirectToCommandsPage,
  };
}
