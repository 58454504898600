import { createAction } from '@reduxjs/toolkit';
import { UserState } from './state';

export const updateUserPreferences = createAction(
  'user/UPDATE_USER_PREFERENCES',
  (preferenceName: keyof UserState, preferenceValue: unknown) => ({
    payload: {
      preferenceName,
      preferenceValue,
    },
  }),
);

export const signInByTokenReset = createAction('user/SIGN_IN_BY_TOKEN/reset');

export const signInPending = createAction('user/SIGN_IN/pending');
export const signInFailed = createAction('user/SIGN_IN/failed', (error: string) => ({
  payload: error,
}));
export const signInFulfilled = createAction('user/SIGN_IN/fulfilled');

export const requestPasswordResetPending = createAction('user/REQUEST_PASSWORD_RESET/pending');
export const requestPasswordResetFailed = createAction(
  'user/REQUEST_PASSWORD_RESET/failed',
  (error: string) => ({
    payload: error,
  }),
);
export const requestPasswordResetFulfilled = createAction('user/REQUEST_PASSWORD_RESET/fulfilled');

export const updateEmailPending = createAction('user/UPDATE_EMAIL/pending');

export const updateEmailFailed = createAction('user/UPDATE_EMAIL/failed');

export const updateEmailFulfilled = createAction<{ email: string }>('user/UPDATE_EMAIL/fulfilled');
