import { PersistentStorageService } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';

export class PersistentStorageServerService implements PersistentStorageService {
  private logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger;
  }

  get() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
    return null;
  }

  async set() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
    return;
  }
}
