import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class ZodiacCompatibilityMainPageCtaClickEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class ZodiacCompatibilityMainPageCtaClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacCompatibilityMainPageCtaClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_zodiac_compatibility_main_page_cta_click');
  }
}
