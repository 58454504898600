import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossarySinglePageBannerClickEventPayload = {
  name: string;
  parentName: string;
};

export class GlossarySinglePageBannerClickEvent extends BaseEvent<GlossarySinglePageBannerClickEventPayload> {}

export class GlossarySinglePageBannerClickEventHandler extends BaseEventHandler<GlossarySinglePageBannerClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossarySinglePageBannerClickEvent;
  }

  async track(event: GlossarySinglePageBannerClickEvent) {
    const { name, parentName } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_page_cta_click', {
      name,
      parent_name: parentName,
    });
  }
}
