import { randomNumber } from 'src/core/common/utils/random';
import { BaseService, CookiesService, HttpClient } from 'src/core/common/services';
import { config } from 'src/config';
import { ApiError, UnknownError } from 'src/core/common/errors';
import { UtmTagsService } from 'src/core/user/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { ObservabilitySystem } from 'src/core/common/observability/interfaces';
import { normalizeError } from 'src/core/common/utils/errors';
import { SpanStatusCode } from 'src/core/common/observability/entities';

export type GoogleAnalyticsAttributionParams = Partial<{
  ga: string;
}>;

export type GoogleAnalyticsTrackParams = Record<string, string | number>;

type GA4Event = {
  name: string;
  params?: Record<string, unknown>;
};

export class GoogleAnalyticsService extends BaseService {
  private readonly utmTagsService: UtmTagsService;

  private readonly cookiesService: CookiesService;

  private readonly logger: Logger;

  constructor(
    httpClient: HttpClient,
    logger: Logger,
    utmTagsService: UtmTagsService,
    cookiesService: CookiesService,
    private readonly observabilitySystem: ObservabilitySystem,
  ) {
    super(httpClient);
    this.utmTagsService = utmTagsService;
    this.cookiesService = cookiesService;
    this.logger = logger;
    this.observabilitySystem = observabilitySystem;
  }

  async trackGA4(event: GA4Event): Promise<void> {
    const span = this.observabilitySystem.startSpan('track_analytics_google_4');
    const clientId = this.getClientId();
    const sessionId = this.getSessionId();

    try {
      span.addEvent('Pending');
      await this.httpClient.post('mp/collect', {
        headers: {
          'content-type': 'text/plain',
        },
        params: {
          measurement_id: config.googleAnalytics.measurementId,
          api_secret: config.googleAnalytics.apiSecret,
        },
        data: {
          client_id: clientId,
          events: [
            {
              name: event.name,
              params: {
                session_id: sessionId,
                engagement_time_msec: config.googleAnalytics.engagementTime,
                ...event.params,
              },
            },
          ],
        },
      });
      span.addEvent('Success');
    } catch (e) {
      const err = normalizeError(e);
      this.logger.error(err);
      span.addEvent('Failed');
      span.recordException(err);
      span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    } finally {
      span.end();
    }
  }

  private getClientId(): string | undefined {
    const clientCookie = this.cookiesService.get(config.googleAnalytics.clientIdKey);
    if (!clientCookie) return this.randomClientId();
    return clientCookie.split('.').slice(2).join('.');
  }

  private randomClientId() {
    return `${this.randomNineDigitNumber()}.${this.randomNineDigitNumber()}`;
  }

  private getSessionId(): string | undefined {
    const sessionCookie = this.cookiesService.get(config.googleAnalytics.sessionIdKey);
    if (!sessionCookie) return this.randomSessionId();
    return sessionCookie.split('.')[2];
  }

  private randomSessionId() {
    return this.randomNineDigitNumber().toString();
  }

  async track(params: GoogleAnalyticsTrackParams) {
    const span = this.observabilitySystem.startSpan('track_analytics_google');
    const utmTags = await this.utmTagsService.getUtmTags();
    try {
      span.addEvent('Pending');
      await this.httpClient.post('collect', {
        params: {
          gclid: utmTags.gclid,
          cs: utmTags.utm_source,
          cm: utmTags.utm_medium,
          cn: utmTags.utm_campaign,
          cc: utmTags.utm_content,
          ck: utmTags.utm_term,
          z: this.randomNineDigitNumber(),
          tid: config.googleAnalyticsId,
          pa: 'checkout',
          t: 'event',
          v: 1,
          ...params,
        },
      });
      span.addEvent('Success');
    } catch (e) {
      const err = normalizeError(e);
      if (err instanceof ApiError) {
        this.logger.error(err);
      } else {
        this.logger.error(new UnknownError(err));
      }
      span.addEvent('Failed');
      span.recordException(err);
      span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    } finally {
      span.end();
    }
  }

  private randomNineDigitNumber() {
    return randomNumber(100000000, 999999999);
  }

  getAttributionParameters(): GoogleAnalyticsAttributionParams {
    const clients = window.ga?.getAll ? window.ga.getAll() : [];
    const desiredClient = clients[0];
    return {
      ga: desiredClient?.get('clientId'),
    };
  }
}
