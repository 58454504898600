import { Context, Span, SpanOptions, TracerProvider } from '@opentelemetry/api';
import { SpanProcessor } from '@opentelemetry/sdk-trace-base';
import { SpanAttribute } from '../entities';
import { ObservabilitySystem } from '../interfaces';

export class ObservabilitySystemDecorator implements ObservabilitySystem {
  constructor(private readonly observabilitySystem: ObservabilitySystem) {}

  setGlobalTracerProvider(provider: TracerProvider): boolean {
    return this.observabilitySystem.setGlobalTracerProvider(provider);
  }

  getTracerProvider(): TracerProvider {
    return this.observabilitySystem.getTracerProvider();
  }

  addSpanProcessor(spanProcessor: SpanProcessor): void {
    return this.observabilitySystem.addSpanProcessor(spanProcessor);
  }

  startActiveSpan<F extends (span: Span) => ReturnType<F>>(
    name: string,
    fn: F,
    options?: SpanOptions,
    context?: Context,
  ): ReturnType<F> {
    return this.observabilitySystem.startActiveSpan<F>(name, fn, options, context);
  }

  setAttributes(attributes: Record<string, SpanAttribute>): ObservabilitySystem {
    return this.observabilitySystem.setAttributes(attributes);
  }

  getAttributes(): Record<string, SpanAttribute> {
    return this.observabilitySystem.getAttributes();
  }

  startSpan(name: string, options?: SpanOptions | undefined, context?: Context | undefined): Span {
    return this.observabilitySystem.startSpan(name, options, context);
  }
}
