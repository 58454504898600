import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type BlogSinglePageOpenEventPayload = {
  blog: string;
};

export class BlogSinglePageOpenEvent extends BaseEvent<BlogSinglePageOpenEventPayload> {}

export class BlogSinglePageOpenEventHandler extends BaseEventHandler<BlogSinglePageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogSinglePageOpenEvent;
  }

  async track(event: BlogSinglePageOpenEvent) {
    const { blog } = event.getPayload();

    return this.amplitudeService.logEvent('seo_blog_page_open', {
      blog: convertToSnakeCase(blog),
    });
  }
}
