import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type DailyHoroscopeMainHoroscopeClickEventPayload = {
  zodiac: ZodiacTypes;
};

export class DailyHoroscopeMainHoroscopeClickEvent extends BaseEvent<DailyHoroscopeMainHoroscopeClickEventPayload> {}

export class DailyHoroscopeMainHoroscopeClickEventHandler extends BaseEventHandler<DailyHoroscopeMainHoroscopeClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof DailyHoroscopeMainHoroscopeClickEvent;
  }

  async track(event: DailyHoroscopeMainHoroscopeClickEvent) {
    const { zodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_daily_horoscope_main_page_horoscope_click', {
      zodiac,
    });
  }
}
