import { StoreEnhancer, StoreEnhancerStoreCreator } from '@reduxjs/toolkit';
import { Action, AnyAction, PreloadedState, Reducer } from 'redux';
import { FeatureFlags } from 'src/core/feature-flags/services';
import { isObjectMatch } from 'src/core/common/utils/isObjectMatch';
import { RootInitialState } from 'src/core/common/store/rootReducer';
import { getFeatureFlagsAttributes } from '../selectors';

export const featureFlagsAttrObserver =
  (featureFlags: FeatureFlags): StoreEnhancer =>
  (createStore: StoreEnhancerStoreCreator): StoreEnhancerStoreCreator =>
  <S = RootInitialState, A extends Action = AnyAction>(
    reducer: Reducer<S, A>,
    preloadedState?: PreloadedState<S>,
  ) => {
    const store = createStore(reducer, preloadedState);

    const updateAttributes = () => {
      const state = store.getState() as unknown;
      const newState = state as RootInitialState;
      const newAttributes = getFeatureFlagsAttributes(newState);
      const currentAttributes = featureFlags.getAttributes();

      const shouldUpdate = !isObjectMatch(currentAttributes, newAttributes);

      if (shouldUpdate) {
        featureFlags.setAttributes(newAttributes);
      }
    };

    updateAttributes();
    store.subscribe(updateAttributes);

    return store;
  };
