import { Context, useContext } from 'react';

export const useSafeContext = <T>(context: Context<T>) => {
  const saveContext = useContext(context);

  if (saveContext === undefined) {
    throw new Error(`Context may be extracted only when used within the relevant provider`);
  }

  return saveContext;
};
