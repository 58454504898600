import { useCallback, useEffect, useRef, useState } from 'react';

type UseIdleModalNamedParams = {
  skip?: boolean;
  open?: () => void;
  confirm?: () => void;
  close?: () => void;
};

export const useIdleModal = (timeoutDelayMs: number, namedParams: UseIdleModalNamedParams = {}) => {
  const { skip, open, confirm, close } = namedParams;

  const [show, setShow] = useState(false);
  const modalShowTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const openModal = useCallback(() => {
    setShow(true);
    if (open) {
      open();
    }
  }, [open]);

  const confirmModal = useCallback(() => {
    setShow(false);
    if (confirm) {
      confirm();
    }
  }, [confirm]);

  const closeModal = useCallback(() => {
    setShow(false);
    if (close) {
      close();
    }
  }, [close]);

  const clearModalShowTimer = useCallback(() => {
    if (modalShowTimer.current) {
      clearTimeout(modalShowTimer.current);
    }
  }, []);

  const startModalShowTimer = useCallback(() => {
    if (skip) {
      return;
    }
    modalShowTimer.current = setTimeout(() => {
      openModal();
    }, timeoutDelayMs);
  }, [openModal, timeoutDelayMs, skip]);

  const resetModalShowTimer = useCallback(() => {
    clearModalShowTimer();
    startModalShowTimer();
  }, [clearModalShowTimer, startModalShowTimer]);

  useEffect(() => {
    startModalShowTimer();
    return () => clearModalShowTimer();
  }, [clearModalShowTimer, startModalShowTimer]);

  return {
    showModal: show,
    openModal,
    confirmModal,
    closeModal,
    clearModalShowTimer,
    startModalShowTimer,
    resetModalShowTimer,
  };
};
