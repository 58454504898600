import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossarySinglePageLinkClickEventPayload = {
  name: string;
  clickedName: string;
  parentName: string;
};

export class GlossarySinglePageLinkClickEvent extends BaseEvent<GlossarySinglePageLinkClickEventPayload> {}

export class GlossarySinglePageLinkClickHandler extends BaseEventHandler<GlossarySinglePageLinkClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossarySinglePageLinkClickEvent;
  }

  async track(event: GlossarySinglePageLinkClickEvent) {
    const { name, parentName, clickedName } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_page_link_click', {
      name,
      clicked_name: clickedName,
      parent_name: parentName,
    });
  }
}
