import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { Services } from 'src/core/common/context';
import { featureFlagsAttrObserver } from 'src/core/feature-flags/store';
import rootReducer, { RootInitialState } from './rootReducer';
import { loadProgressFromStorage, saveProgressToStorage } from './storage';

function loadStoreData(): Partial<RootInitialState> {
  const persistedProgress = loadProgressFromStorage();

  return {
    ...persistedProgress,
  };
}

export function createStore(services: Services) {
  const { featureFlags, appEnvironmentService, utmTagsService } = services;

  const storeData = loadStoreData();
  const userUtmTags = { tags: utmTagsService.getUtmTags() };
  const system = {
    isTestingEnv: appEnvironmentService.isTestingEnv(),
    platform: appEnvironmentService.getPlatform(),
    isInnAppBrowser: appEnvironmentService.isInAppBrowser(),
  };

  const preloadedState: Partial<RootInitialState> = {
    ...storeData,
    system,
    userUtmTags,
  };

  const thunkWithContext = thunk.withExtraArgument(services);
  const enhancers = [featureFlagsAttrObserver(featureFlags)];

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: [thunkWithContext],
    enhancers,
  });

  store.subscribe(() => {
    const progressState = store.getState();

    saveProgressToStorage(progressState);
  });

  return store;
}
