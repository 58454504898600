import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type CatalogueCategoryBrowseAdvisorsClickEventPayload = {
  landing: string;
};

export class CatalogueCategoryBrowseAdvisorsClickEvent extends BaseEvent<CatalogueCategoryBrowseAdvisorsClickEventPayload> {}

export class CatalogueCategoryBrowseAdvisorsClickEventHandler extends BaseEventHandler<CatalogueCategoryBrowseAdvisorsClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueCategoryBrowseAdvisorsClickEvent;
  }

  async track(event: CatalogueCategoryBrowseAdvisorsClickEvent) {
    const { landing } = event.getPayload();

    this.googleAnalyticsService.trackGA4({
      name: 'click_from_seo',
    });

    return this.amplitudeService.logEvent('seo_landing_page_browse_advisors_click', {
      landing: convertToSnakeCase(landing),
    });
  }
}
