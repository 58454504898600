import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class SimpleRegistrationMainPageExThemeOpenedEvent extends BaseEvent {}

export class SimpleRegistrationMainPageExThemeOpenedEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SimpleRegistrationMainPageExThemeOpenedEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('registration_ex_theme_screen_open');
  }
}
