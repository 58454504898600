import { UserAuthenticatedSessionDto } from '../services/dto';
import { UserAuthenticatedSession } from '../entities';

export function userAuthenticatedSessionDtoToEntity(
  data: UserAuthenticatedSessionDto,
): UserAuthenticatedSession {
  return {
    accessExpiresAt: data.access_expires_at,
    user: data.user,
    refreshExpiresAt: data.refresh_expires_at,
  };
}
