import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type BlogSinglePageCardClickEventPayload = {
  blog: string;
  clickedBlog: string;
};

export class BlogSinglePageCardClickEvent extends BaseEvent<BlogSinglePageCardClickEventPayload> {}

export class BlogSinglePageCardClickEventHandler extends BaseEventHandler<BlogSinglePageCardClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogSinglePageCardClickEvent;
  }

  async track(event: BlogSinglePageCardClickEvent) {
    const { blog, clickedBlog } = event.getPayload();

    return this.amplitudeService.logEvent('seo_blog_page_card_click', {
      blog: convertToSnakeCase(blog),
      clicked_blog: convertToSnakeCase(clickedBlog),
    });
  }
}
