import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type SeoFreeReadingModalClickEventPayload = {
  context: 'close' | 'confirm';
};

export class SeoFreeReadingModalClickEvent extends BaseEvent<SeoFreeReadingModalClickEventPayload> {}

export class SeoFreeReadingModalClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SeoFreeReadingModalClickEvent;
  }

  async track(event: SeoFreeReadingModalClickEvent) {
    const { context } = event.getPayload();

    return this.amplitudeService.logEvent('seo_free_reading_modal_click', {
      context,
    });
  }
}
