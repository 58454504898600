import { GlossaryRootPageOpenEventHandler } from './GlossaryRootPageOpenEvent';
import { GlossaryRootPageGlossaryClickEventHandler } from './GlossaryRootPageGlossaryClick';
import { GlossaryRootPageCtaClickEventHandler } from './GlossaryRootPageBannerClickEvent';

export * from './GlossaryRootPageOpenEvent';
export * from './GlossaryRootPageGlossaryClick';
export * from './GlossaryRootPageBannerClickEvent';
export const glossaryRootPageEventHandlers = [
  GlossaryRootPageOpenEventHandler,
  GlossaryRootPageGlossaryClickEventHandler,
  GlossaryRootPageCtaClickEventHandler,
];
