import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class SimpleRegistrationMainPageGenderOpenedEvent extends BaseEvent {}

export class SimpleRegistrationMainPageGenderOpenedEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SimpleRegistrationMainPageGenderOpenedEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('registration_gender_start_screen_open');
  }
}
