import cn from 'classnames';
import { ReactNode } from 'react';
import Logo, { LogoProps } from 'src/core/common/components/common/Logo';
import HeaderMenu from './HeaderMenu';
import styles from './Header.module.scss';

export type HeaderProps = {
  logoProps?: Omit<LogoProps, 'onClick'>;
  className?: string;
  shouldShowMenu?: boolean;
  id?: string;
  children?: ReactNode;
  onLogoClick?: () => void;
  menuClassName?: string;
};

const Header = ({
  className,
  id = '',
  shouldShowMenu = true,
  onLogoClick,
  children,
  menuClassName = '',
  logoProps = {},
}: HeaderProps) => {
  return (
    <header className={cn(styles.header, className)} id={id}>
      <Logo {...logoProps} onClick={onLogoClick} />
      {shouldShowMenu && <HeaderMenu className={cn(styles.menu, menuClassName)} />}
      {children}
    </header>
  );
};

export default Header;
