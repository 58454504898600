import { UtmTagsService } from 'src/core/user/services';
import { CookiesService } from 'src/core/common/services';
import { ObservabilitySystem } from 'src/core/common/observability/interfaces';
import { normalizeError } from 'src/core/common/utils/errors';
import { SpanStatusCode } from 'src/core/common/observability/entities';

export type EverflowAttributionParams = Partial<{
  everflow_transaction_id: string;
}>;

export class EverflowService {
  private readonly DEFAULT_EVERFLOW_OFFER_ID = '1';

  private utmTagsService: UtmTagsService;

  private cookiesService: CookiesService;

  constructor(
    utmTagsService: UtmTagsService,
    cookiesService: CookiesService,
    private readonly observabilitySystem: ObservabilitySystem,
  ) {
    this.utmTagsService = utmTagsService;
    this.cookiesService = cookiesService;
  }

  getAttributionParameters(): EverflowAttributionParams | undefined {
    const span = this.observabilitySystem.startSpan(
      'track_analytics_everflow_attribution_parameters',
    );
    span.addEvent('Pending');
    try {
      const transactionId =
        this.getTransactionIdFromEF() ||
        this.getTransactionIdFromCookies() ||
        this.getTransactionIdFromUtmTags();
      span.addEvent('Success');

      return {
        everflow_transaction_id: transactionId,
      };
    } catch (e) {
      const err = normalizeError(e);
      span.addEvent('Failed');
      span.recordException(err);
      span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    } finally {
      span.end();
    }
  }

  private getTransactionIdFromEF(): string | undefined {
    const utmTags = this.utmTagsService.getUtmTags();
    const offerId = utmTags.oid || this.DEFAULT_EVERFLOW_OFFER_ID;
    return window.EF?.getTransactionId(offerId);
  }

  private getTransactionIdFromCookies(): string | undefined {
    return this.cookiesService.get('everflow_transaction_id');
  }

  private getTransactionIdFromUtmTags(): string | undefined {
    const utmTags = this.utmTagsService.getUtmTags();
    return utmTags._ef_transaction_id;
  }
}
