import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type EventPayload = {
  context: string;
};

export class SimpleRegistrationMainPageGenderClickedEvent extends BaseEvent<EventPayload> {}

export class SimpleRegistrationMainPageGenderClickedEventHandler extends BaseEventHandler<EventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SimpleRegistrationMainPageGenderClickedEvent;
  }

  async track(event: SimpleRegistrationMainPageGenderClickedEvent) {
    const { context } = event.getPayload();

    return this.amplitudeService.logEvent('registration_gender_start_screen_click', {
      context,
    });
  }
}
