import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossarySinglePageAngelNumberClickEventPayload = {
  number: string;
};

export class GlossarySinglePageAngelNumberClickEvent extends BaseEvent<GlossarySinglePageAngelNumberClickEventPayload> {}

export class GlossarySinglePageAngelNumberClickEventHandler extends BaseEventHandler<GlossarySinglePageAngelNumberClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossarySinglePageAngelNumberClickEvent;
  }

  async track(event: GlossarySinglePageAngelNumberClickEvent) {
    const { number } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_page_angel_number_click', {
      number,
    });
  }
}
