import { BaseService } from 'src/core/common/services';

export class ChatService extends BaseService {
  async createChat(expertId: string) {
    const { data } = await this.httpClient.post<{ uuid: string }>('/live-chats', {
      data: { astrologer_id: expertId },
    });

    return data;
  }
}
