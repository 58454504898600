import { FeatureDefinition } from 'src/core/feature-flags/entities';
import { FeatureFlags } from 'src/core/feature-flags/services';

type FeatureFlagsHandlers = {
  onLoaded: (features: Record<string, FeatureDefinition>) => void;
  onUpdated?: (features: Record<string, FeatureDefinition>) => void;
  onFailed: (error: Error) => void;
};

export const useFeatureFlagsListeners = (
  featureFlags: FeatureFlags,
  { onLoaded, onUpdated, onFailed }: FeatureFlagsHandlers,
) => {
  featureFlags.onFeaturesLoaded((features) => onLoaded(features));

  if (onUpdated) {
    featureFlags.onFeaturesUpdated((features) => onUpdated(features));
  }

  featureFlags.onFeaturesLoadingFailed((error) => onFailed(error));
};
