import { useEffect, useState } from 'react';
import { SECOND } from '../constants';
import { loaderEnabledRoutes } from '../constants/routes';
import { Logger } from '../logger/interfaces';
import { useRouter } from './useRouter';

export const useFeatureFlagsLoader = (logger: Logger) => {
  const router = useRouter();

  const [isLoaderVisible, setIsLoaderVisible] = useState(() => {
    return loaderEnabledRoutes.some((route) => router.pathname.includes(route));
  });

  const hideLoader = () => setIsLoaderVisible(false);

  // Hide loader after 7 seconds of features loading
  useEffect(() => {
    if (!isLoaderVisible) {
      return;
    }

    const timeoutId = setTimeout(() => {
      logger.warn('Feature flags loader closed. Timeout reached');
      hideLoader();
    }, 7 * SECOND);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isLoaderVisible]);

  return { isLoaderVisible, hideLoader };
};
