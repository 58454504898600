import { normalizeError } from 'src/core/common/utils/errors';
import { BaseService, HttpClient } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { ObservabilitySystem } from 'src/core/common/observability/interfaces';
import { SpanStatusCode } from 'src/core/common/observability/entities';

export class BackendAnalyticsService extends BaseService {
  private readonly logger: Logger;

  constructor(
    httpClient: HttpClient,
    logger: Logger,
    private readonly observabilitySystem: ObservabilitySystem,
  ) {
    super(httpClient);
    this.logger = logger;
    this.observabilitySystem = observabilitySystem;
  }

  async setSplitExperimentProperties(props: { experimentName: string; groupName: string }) {
    const { experimentName, groupName } = props;
    const span = this.observabilitySystem.startSpan(
      'track_analytics_backend_set_split_experiment_properties',
    );
    try {
      span.addEvent('Pending');
      await this.httpClient.post('/analytics/split/group', {
        data: {
          test_name: experimentName,
          group_name: groupName,
        },
      });
      span.addEvent('Success');
    } catch (e) {
      const err = normalizeError(e);
      this.logger.error(err);
      span.addEvent('Failed');
      span.recordException(err);
      span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    } finally {
      span.end();
    }
  }
}
