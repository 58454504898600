export abstract class BaseEvent<Payload = unknown> {
  private readonly payload: Payload;

  constructor(payload: Payload) {
    this.payload = payload;
  }

  getPayload(): Payload {
    return this.payload;
  }
}
