import { useCallback } from 'react';
import { useRedirectToMainApp } from './useRedirectToMainApp';

export function useCommands() {
  const { redirectToCommandsPage } = useRedirectToMainApp();

  const createCommand = useCallback(async (command: string) => {
    await redirectToCommandsPage(command);
  }, []);

  return {
    createCommand,
  };
}
