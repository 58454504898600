import { SWRConfig } from 'swr';
import { ReactNode } from 'react';
import { QueryCacheProviderOptions, QueryCacheOptionalFallback } from '../../entities';

export type QueryCacheProviderProps = {
  children: ReactNode;
  defaultOptions: QueryCacheProviderOptions;
} & QueryCacheOptionalFallback;

export const QueryCacheProvider = ({
  defaultOptions,
  children,
  fallback,
}: QueryCacheProviderProps) => (
  <SWRConfig value={{ ...defaultOptions, fallback }}>{children}</SWRConfig>
);

export default QueryCacheProvider;
