import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class SignInByTokenSucceedEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class SignInByTokenSucceedEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SignInByTokenSucceedEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('authenticate_by_token_succeed');
  }
}
