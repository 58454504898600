import { Logger } from 'src/core/common/logger/interfaces';
import { AnalyticsService, AttributionParameters } from './AnalyticsService';

export class AnalyticsServerService implements AnalyticsService {
  logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger;
  }

  track() {
    return null;
  }

  launch() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  accountCreated() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  getAttributionParameters(): AttributionParameters {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
    return {
      fbp: '',
      fbc: '',
    };
  }

  trackQuizEvent() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  privateDataCollectionAgreed() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  setCurrentQuizName() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  setUserId() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  async getDeviceId() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
    return '';
  }

  async getSessionId() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
    return '';
  }

  lead() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  paymentCardChosen() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  paymentPaypalChosen() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  paymentRetried() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  paymentError() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  paymentSuccess() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  extendedLpOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  extendedLpGetReport() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  billingLpOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  billingLpGetReport() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  billingLpAdditionalDiscountPopup() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  billingLpGetAdditionalDiscount() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  additionalDiscountLpOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  additionalDiscountLpGetReport() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  numerologyLpOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  numerologyLpGetReport() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  numerologyLpSkip() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotLpOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotLpGetReport() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotLpSkip() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  subscriptionLandingOpen() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  subscriptionLandingClicked() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  appnebulaCatalogueLpOpen() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  appnebulaCatalogueExpertClick() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  appnebulaCatalogueExpertLpOpen() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  appnebulaCatalogueExploreAllClick() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  appnebulaFirstQuestionLpOpen() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationLpOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationLpClicked() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationFormClicked() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationCatalogueLpOpen() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationExpertLpOpen() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationStartChatClicked() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  setUserExperimentGroupName() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueLpOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueGetStartedClicked() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueLpStartChatClicked() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueEmailFormOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueGoogleFormOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueLpEmailConfirmOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueLpExpertOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueLpPrelandingOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueMatchedExpertsOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueAllExpertsOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCatalogueEmailConsentClick() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertsCataloguePaymentOpened() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  chatOnboardingLpOpened(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  mainQuizLpOpened(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationEmailConsentClick() {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  updateUtmTags(): Promise<void> {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
    return Promise.resolve(undefined);
  }

  smsInputScreenClicked(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationOfferEmailConfirmClicked(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationOfferEmailConfirmSkipped(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationOfferEmailChangeEmail(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  appPageAppButtonClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  appPageOpened(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  zodiacPairCompatibilityPageOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  zodiacPairCompatibilityPageZodiacSignClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoMainPageOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoMainPageExpertsCardClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoMainPageStartChatClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoMainPageBrowseAdvisorsClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoMainPageFindAdvisorClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoSinglePageOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoSinglePageExpertsCardClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoSinglePageStartChatClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  geoSinglePageBrowseAdvisorsClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }
  signUpPageOpened(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  signUpPageLoginClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  signUpPageSignUpClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationWelcomeBackClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationWelcomeBackEmailError(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationWelcomeBackPasswordError(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationWelcomeBackLoginSuccess(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationWelcomeBackForgotYourPasswordClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationPasswordRecoveryOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationPasswordRecoveryClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationCheckEmailOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  registrationCheckEmailClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoMainPageOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoMainPageExpertsCardClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoMainPageStartChatClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoMainPageBrowseAdvisorsClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoMainPageFindAdvisorClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoSinglePageOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoSinglePageExpertsCardClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoSinglePageStartChatClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  tarotGeoSinglePageBrowseAdvisorsClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  trackUserAge(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  seoBannerClick(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertLandingPageScreenOpened(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertLandingPageScreenClicked(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertCatalogueLandingPageScreenOpened(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  startFreeChatScreenClicked(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  expertPagePaginationButtonClicked(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }

  notFoundPageOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }
  serverErrorPageOpen(): void {
    this.logger.info(`${this.constructor.name}. This method was called on server`);
  }
}
