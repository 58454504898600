import { ChartSinglePageOpenEventHandler } from './ChartSingle/ChartSinglePageOpenEvent';
import { ChartSinglePageCtaClickEventHandler } from './ChartSingle/ChartSinglePageCtaClickEvent';
import { ChartSinglePageStartQuizClickEventHandler } from './ChartSingle/ChartSinglePageStartQuizClickEvent';

export * from './ChartSingle/ChartSinglePageOpenEvent';
export * from './ChartSingle/ChartSinglePageCtaClickEvent';
export * from './ChartSingle/ChartSinglePageStartQuizClickEvent';

export const chartPageEventHandlers = [
  ChartSinglePageOpenEventHandler,
  ChartSinglePageCtaClickEventHandler,
  ChartSinglePageStartQuizClickEventHandler,
];
