import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type BlogMainCategoryClickEventPayload = {
  category: string;
};

export class BlogMainCategoryClickEvent extends BaseEvent<BlogMainCategoryClickEventPayload> {}

export class BlogMainCategoryClickEventHandler extends BaseEventHandler<BlogMainCategoryClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogMainCategoryClickEvent;
  }

  async track(event: BlogMainCategoryClickEvent) {
    const { category } = event.getPayload();
    return this.amplitudeService.logEvent('seo_blog_main_category_click', {
      category: convertToSnakeCase(category),
    });
  }
}
