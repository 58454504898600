import { BaseEvent, BaseEventHandler } from 'src/core/analytics/events';

export class ChatIceBreakingTipsClickedEvent extends BaseEvent {}

export class ChatIceBreakingTipsClickedEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ChatIceBreakingTipsClickedEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('expert_catalogue_draft_message_click');
  }
}
