import { useSelector } from 'react-redux';
import { getIsInnAppBrowser } from '../store/system';

export function useSystem() {
  // TODO maybe change to Platform
  const isInAppBrowser = useSelector(getIsInnAppBrowser);

  return {
    isInAppBrowser,
  };
}
