/* eslint-disable max-len */
import { FormikHelpers } from 'formik';
import { ReactNode } from 'react';
import { QUIZ_ANALYTIC_EVENTS } from 'src/core/analytics/events';
import {
  EmailConsentDataHelpers,
  FacebookLoginData,
  SignUpGoogleResponseData,
} from 'src/core/auth/entities';
import { EmojiName } from 'src/core/common/components/common/Emoji/emojiMap';
import { Coordinates, LoadingStatuses } from 'src/core/common/entities';
import { RootInitialState } from 'src/core/common/store/rootReducer';
import { Expert } from 'src/core/experts/entities';
import { LocationCountry } from 'src/core/location/entities';
import { UserState } from 'src/core/user/store/state';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type MarkdownText = string;

export enum AreaOfGuidance {
  LOVE_AND_RELATIONSHIP = 'Love & Relationship',
  DESTINY_AND_LIFE_PATH = 'Destiny & Life path',
  CAREER_AND_WORK = 'Career & Work',
  FUTURE_TELLING = 'Future Telling',
  SPIRIT_GUIDANCE = 'Spirit Guidance',
  OTHER = 'Other',
}

export enum FlowName {
  QUIZ = 'quiz',
  SHORT_QUIZ = 'short_quiz',
  COMPATIBILITY_REPORT = 'compatibility_report',
  CHAT_BOT = 'chat_bot',
  QUIZ_FREE = 'quiz_free_credits_100',
  SIMPLE_REGISTRATION = 'simple_registration',
  SIMPLE_SIMPLE_REGISTRATION = 'simple_simple_registration',
  EXPERTS_CATALOGUE = 'experts_catalogue',
  EXPERTS_CATALOGUE_TAROT = 'experts_catalogue_tarot',
  EXPERTS_CATALOGUE_LOVE_SPELL = 'experts_catalogue_love_spell',
  EXPERTS_CATALOGUE_FORTUNE_TELLING = 'experts_catalogue_fortune_telling',
  EXPERTS_CATALOGUE_BEST_PSYCHIC = 'experts_catalogue_best_psychic',
  EXPERTS_CATALOGUE_NUMEROLOGY = 'experts_catalogue_numerology',
  EXPERTS_CATALOGUE_ASTROLOGY = 'experts_catalogue_astrology',
  H0ME = 'homepage',
  PSYCHICS = 'psychics_page',
  PSYCHICS_CATEGORY = 'psychics_category_page',
  BLOG_MAIN = 'blog_main_page',
  ZODIAC_COMPATIBILITY_MAIN_PAGE = 'zodiac_compatibility_main_page',
  GEO_MAIN_PAGE = 'geo_main_page',
  GEO_PAGE = 'geo_page',
  TAROT_GEO_MAIN_PAGE = 'tarot_geo_main_page',
  TAROT_GEO_PAGE = 'tarot_geo_page',
  DAILY_HOROSCOPE_MAIN_PAGE = 'daily_horoscope_main_page',
  DAILY_HOROSCOPE_PAGE = 'daily_horoscope_page',
  ADVISOR_PAGE = 'advisor_page',
  APPNEBULA = 'appnebula',
}

export enum StepTypes {
  ANSWER = 'ANSWER',
  SIGN_UP = 'SIGN_UP',
  SINGLE_ANSWER_V2 = 'SINGLE_ANSWER_V2',
  CHAT_QUESTION = 'CHAT_QUESTION',
  SUMMARY = 'SUMMARY',
  BLURRED_SIGNUP_EXPERIMENT = 'BLURRED_SIGNUP_EXPERIMENT',
  SIGN_UP_WITH_GENDER = 'SIGN_UP_WITH_GENDER',
  CATEGORIZED_EXPERTS_CATALOGUE = 'CATEGORIZED_EXPERTS_CATALOGUE',
  SIGN_IN = 'SIGN_IN',
  ANSWER_PRIORITY = 'ANSWER_PRIORITY',
  CONGRATULATIONS = 'CONGRATULATIONS',
  DATE = 'DATE',
  PLACE = 'PLACE',
  TIME = 'TIME',
  PROCESSING_WITH_POPUP = 'PROCESSING_WITH_POPUP',
  COMPOUNDED_TITLE_INFORMATIONAL = 'COMPOUNDED_TITLE_INFORMATIONAL',
  GENDER = 'GENDER',
  ANIMATION_INFORMATION = 'ANIMATION_INFORMATION',
  CONTEXT_ANIMATION_INFORMATION = 'CONTEXT_ANIMATION_INFORMATION',
  DISAPPEARING_INFORMATION = 'DISAPPEARING_INFORMATION',
  ABOUT_APP = 'ABOUT_APP',
  EMAIL = 'EMAIL',
  EMAIL_CONSENT = 'EMAIL_CONSENT',
  EMAIL_CONSENT_WITH_REDIRECT = 'EMAIL_CONSENT_WITH_REDIRECT',
  EMAIL_WITH_DATE = 'EMAIL_WITH_DATE',
  INTRO = 'INTRO',
  INTRO_PARTNER = 'INTRO_PARTNER',
  SINGLE_ANSWER = 'SINGLE_ANSWER',
  SINGLE_ANSWER_WITH_FEEDBACK = 'SINGLE_ANSWER_WITH_FEEDBACK',
  SINGLE_ANSWER_WITH_MODAL = 'SINGLE_ANSWER_WITH_MODAL',
  VARIOUS_TOPICS = 'VARIOUS_TOPICS',
  SINGLE_HORIZONTAL_ANSWER = 'SINGLE_HORIZONTAL_ANSWER',
  MULTI_ANSWER = 'MULTI_ANSWER',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  NAME = 'NAME',
  OFFER = 'OFFER',
  THANK_YOU = 'THANK_YOU',
  PROBLEM = 'PROBLEM',
  PASSWORD = 'PASSWORD',
  EXPERT_PROCESSING = 'EXPERT_PROCESSING',
  PROCESSING = 'PROCESSING',
  PROCESSING_WITH_REDIRECT = 'PROCESSING_WITH_REDIRECT',
  REVIEW = 'REVIEW',
  CONTEXT_REVIEW = 'CONTEXT_REVIEW',
  SPLIT = 'SPLIT',
  SUPPORT = 'SUPPORT',
  TESTIMONIAL = 'TESTIMONIAL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  TOPIC_TO_DISCUSS = 'TOPIC_TO_DISCUSS',
  TYPE_OF_READING = 'TYPE_OF_READING',
  EXPERTS_CATALOGUE = 'EXPERTS_CATALOGUE',
  EXPERTS_CATALOGUE_CHAT = 'EXPERTS_CATALOGUE_CHAT',
  BLURRED_INFORMATION = 'BLURRED_INFORMATION',
  CHECK_IN_INTRO = 'CHECK_IN_INTRO',
  CHECK_IN_INTRO_WITH_IMAGE = 'CHECK_IN_INTRO_WITH_WOMAN',
  CONTEXT_CHECK_IN_INTRO = 'CONTEXT_CHECK_IN_INTRO',
  RESONATE = 'RESONATE',
  PREFERENCE_INTRO = 'PREFERENCE_INTRO',
  CONTEXT_PREFERENCE_INTRO = 'CONTENT_PREFERENCE_INTRO',
  CONTEXT_PREFERENCE_INTRO_PROFILE = 'CONTEXT_PREFERENCE_INTRO_PROFILE',
  PREFERENCE_INTRO_WITH_IMAGE = 'PREFERENCE_INTRO_WITH_WOMAN',
  RECOGNIZE_GOAL = 'RECOGNIZE_GOAL',
  ANIMATED_MESSAGE = 'ANIMATED_MESSAGE',
  MULTI_ANSWER_WITH_EMOJI = 'MULTI_ANSWER_WITH_EMOJI',
  MENTIONED_IN = 'MENTIONED_IN',
  READING_BENEFITS = 'READING_BENEFITS',
  FINAL_SCREEN = 'FINAL_SCREEN',
  NOT_ALONE = 'NOT_ALONE',
  WITH_CIRCLE_CHARTS = 'WITH_CIRCLE_CHARTS',
  CHAT = 'CHAT',
  CHAT_EXPERIMENT = 'CHAT_EXPERIMENT',
  PRIVACY = 'PRIVACY',
  PRIVACY_SLIDER = 'PRIVACY_SLIDER',
  BLURRED_SIGNUP = 'BLURRED_SIGNUP',
  BLURRED_PASSWORD = 'BLURRED_PASSWORD',
  BLURRED_PASSWORD_EXPERIMENT = 'BLURRED_PASSWORD_EXPERIMENT',
  RELATE_STATEMENT = 'RELATE_STATEMENT',
  USER_NAME = 'USER_NAME',
  FREE_OFFER = 'FREE_OFFER',
}

export enum ComponentTypes {
  DEFAULT = 'DEFAULT',
  COMPOUNDED_TITLE = 'COMPOUNDED_TITLE',
  GOAL = 'GOAL',
  DATE_DRUM = 'DATE_DRUM',
  MULTI_ANSWER_CHECKBOX = 'MULTI_ANSWER_CHECKBOX',
  MULTI_ANSWER_CHECKBOX_WITH_ICON = 'MULTI_ANSWER_CHECKBOX_WITH_ICON',
  WITH_REDIRECT = 'WITH_REDIRECT',
}

export type QuizCallbackStepData = {
  id: string;
  getStepData: (data: RootInitialState) => QuizStepData;
};

export type QuizStep = QuizCallbackStepData | QuizStepData;

export interface QuizData {
  name: FlowName;
  steps: Record<string, QuizStep>;
  baseUrl: string;
  nextStage: string;
  previousStage: string;
  initialId: string;
  lastId: string;
}

interface AnalyticData {
  baseEvent: QUIZ_ANALYTIC_EVENTS;
  baseEventProperties: Record<string, unknown>;
  clickEvent: QUIZ_ANALYTIC_EVENTS;
}

export type ProgressBarStepProps = {
  progressBarStyle?: 'split' | 'solid';
  progressBarName?: 'profile' | 'personality' | 'preferences';
  totalProfileSteps?: number;
  totalPersonalitySteps?: number;
  totalPreferencesSteps?: number;
  combinedCurrentStep?: number;
};

interface BaseStepData {
  id: string;
  screenType: StepTypes;
  content: Record<string, unknown> | (() => Record<string, unknown>);
  stepOrder?: number;
  index: number;
  totalSteps?: number;
  canNotBackToScreen?: boolean;
  analytic?: Partial<AnalyticData>;
  progressBarProps?: ProgressBarStepProps;
  next: string;
  prev: string | null;
}

export enum ConfidenceLevel {
  VeryHigh = 'Very High',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export type QuizStepData =
  | AnswerStepData
  | ThankYouStepData
  | AnswerPriorityStepData
  | SignUpStepData
  | SignUpWithGenderStepData
  | SignInStepData
  | CompoundedTitleInformationalStepData
  | CheckInIntroStepData
  | ContextCheckInIntroStepData
  | ContextPreferenceIntroStepData
  | CheckInIntroWithImageStepData
  | ResonateStepData
  | PreferenceIntroStepData
  | PreferenceIntroWithImageStepData
  | RecognizeGoalStepData
  | SingleAnswerWithModalStepData
  | GenderStepData
  | AnimationInformationStepData
  | DisappearingInformationStepData
  | BlurredInformationStepData
  | BlurredSignUpStepData
  | BlurredPasswordStepData
  | PlaceStepData
  | DateStepData
  | TimeStepData
  | ProcessingStepData
  | ProcessingWithRedirectStepData
  | SingleAnswerStepData
  | VariousTopicsStepData
  | SingleHorizontalAnswerStepData
  | MultiAnswerStepData
  | EmailStepData
  | EmailConsentStepData
  | EmailConsentWithRedirectStepData
  | EmailWithDateStepData
  | OfferStepData
  | AboutAppStepData
  | PasswordStepData
  | ProblemStepData
  | ReviewsStepData
  | ContextReviewsStepData
  | SplitStepData
  | TestimonialStepData
  | NameStepData
  | PhoneNumberStepData
  | TopicToDiscussStepData
  | TypeOfReadingStepData
  | ExpertsCatalogueStepData
  | MentionedInStepData
  | ReadingBenefitsStepData
  | SummaryScreenStepData
  | NotAloneStepData
  | WithCircleChartsStepData
  | ChatStepData
  | ChatExperimentStepData
  | PrivacyStepData
  | PrivacySliderStepData
  | ContextAnimationInformationStepData
  | RelateStatementStepData
  | ChatQuestionStepData
  | SingleAnswerWithFeedbackStepData
  | CongratulationsStepData
  | CategorizedExpertsCatalogueStepData
  | ContextPreferenceIntroProfileStepData
  | SummaryStepData
  | AnimatedMessageStepData
  | ProcessingWithPopupStepData
  | SingleAnswerV2StepData
  | ExpertProcessingStepData
  | UserNameStepData
  | FreeOfferStepData;

export type QuizScreenProps =
  | CongratulationsScreenProps
  | SummaryScreenProps
  | CategorizedExpertsCatalogueScreenProps
  | ExpertProcessingScreenProps
  | AnimatedMessageScreenProps
  | ProcessingWithPopupScreenProps
  | ContextPreferenceIntroProfileScreenProps
  | ChatQuestionScreenProps
  | SingleAnswerWithFeedbackScreenProps
  | AnimationInformationScreenProps
  | SingleAnswerV2ScreenProps
  | CheckInIntroScreenProps
  | ContextCheckInIntroScreenProps
  | ContextPreferenceIntroScreenProps
  | CheckInIntroWithImageScreenProps
  | ResonateScreenProps
  | PreferenceIntroScreenProps
  | PreferenceIntroWithImageScreenProps
  | RecognizeGoalScreenProps
  | SingleAnswerWithModalScreenProps
  | DisappearingInformationScreenProps
  | ThankYouScreenProps
  | VariousTopicsScreenProps
  | DateScreenProps
  | PlaceScreenProps
  | SignUpScreenProps
  | SignUpWithGenderScreenProps
  | SignInScreenProps
  | TestimonialScreenProps
  | TimeScreenProps
  | EmailScreenProps
  | EmailConsentScreenProps
  | EmailConsentWithRedirectScreenProps
  | EmailWithDateScreenProps
  | GenderScreenProps
  | MultipleAnswerScreenProps
  | NameScreenProps
  | OfferScreenProps
  | AboutAppScreenProps
  | PasswordScreenProps
  | ProblemScreenProps
  | ProcessingScreenProps
  | ProcessingWithRedirectScreenProps
  | ReviewScreenProps
  | ContextReviewScreenProps
  | SingleAnswerScreenProps
  | SingleHorizontalAnswerScreenProps
  | SplitStepData
  | PhoneNumberScreenProps
  | TopicToDiscussScreenProps
  | TypeOfReadingScreenProps
  | ExpertsCatalogueScreenProps
  | BlurredInformationScreenProps
  | BlurredSignUpScreenProps
  | BlurredPasswordScreenProps
  | MentionedInScreenProps
  | ReadingBenefitsScreenProps
  | SummaryScreenScreenProps
  | NotAloneScreenProps
  | WithCircleChartsScreenProps
  | ChatScreenProps
  | ChatExperimentScreenProps
  | PrivacyScreenProps
  | PrivacySliderScreenProps
  | ContextAnimationInformationScreenProps
  | RelateStatementScreenProps
  | UserNameScreenProps
  | FreeOfferScreenProps;

export interface SplitStepData extends BaseStepData {
  screenType: StepTypes.SPLIT;
  storageField: string;
  getStep: (userState: UserState) => string;
}

export interface AnimationInformationStepData extends BaseStepData {
  screenType: StepTypes.ANIMATION_INFORMATION;
  componentType: ComponentTypes;
  getGoal?: (goal: string) => string;
  content: {
    animatedImage?: ReactNode;
    titleFirstLine?: string;
    titleSecondLine?: string;
    titleMiddle?: string;
    hightLightText?: string;
    titleEnd?: string;
    text?: string;
    next: string;
    back: string;
  };
}

export interface ContextAnimationInformationStepData extends BaseStepData {
  screenType: StepTypes.CONTEXT_ANIMATION_INFORMATION;
  componentType: ComponentTypes;
  getGoal: (goal: string) => string;
  getTitle: (goal: string) => {
    titleFirstLine?: string;
    titleSecondLine?: string;
    titleMiddle?: string;
    hightLightText?: string;
    titleEnd?: string;
    text?: string;
  };
  content: {
    animatedImage?: ReactNode;
    calmingText: string;
    next: string;
    back: string;
    screenTitle: string;
  };
}

export interface DisappearingInformationStepData extends BaseStepData {
  screenType: StepTypes.DISAPPEARING_INFORMATION;
  content: {
    image?: string;
    text?: string;
    titleStart?: string;
    highlightText?: string;
    titleEnd?: string;
    subtitle?: string;
    list?: Array<{
      title: string;
      text: string;
      image: string;
    }>;
  };
}

export interface BlurredInformationStepData extends BaseStepData {
  screenType: StepTypes.BLURRED_INFORMATION;
  content: {
    tags: Array<{
      image: string;
      title: string;
      subtitle: string;
    }>;
    title: string;
    list: Array<{
      text: string;
      image: string;
    }>;
    next: string;
  };
}

export interface BlurredSignUpStepData extends BaseStepData {
  screenType: StepTypes.BLURRED_SIGNUP;
  otherEvent?: {
    clickExplanation: QUIZ_ANALYTIC_EVENTS;
    openModal: QUIZ_ANALYTIC_EVENTS;
    closeModal: QUIZ_ANALYTIC_EVENTS;
  };
  dataKey: string;
  getGoal: (goal: string) => string;
  content: {
    title: string;
    subtitle: string;
    button: string;
    explanation: string;
    modal: {
      title: string;
      subtitle: string;
      list: Array<{ text: string; icon: string }>;
      button: string;
      guarantee: string;
    };
  };
}

export interface BlurredPasswordStepData extends BaseStepData {
  screenType: StepTypes.BLURRED_PASSWORD;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    description?: string;
    button: string;
    label?: string;
  };
}

export interface CheckInIntroStepData extends BaseStepData {
  screenType: StepTypes.CHECK_IN_INTRO;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    summary: {
      header: string;
      subheader: string;
      zodiacSignTitle: string;
      characterTraitTitle: string;
      luckyDaysTitle: string;
    };
    insight: {
      header: string;
      mainTextContent: string;
    };
    continueButtonText: string;
  };
}

export interface ContextCheckInIntroStepData extends BaseStepData {
  screenType: StepTypes.CONTEXT_CHECK_IN_INTRO;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    spoiler: string;
    summary: {
      header: string;
      subheader: string;
      zodiacSignTitle: string;
      characterTraitTitle: string;
      luckyDaysTitle: string;
    };
    insight: {
      header: string;
      mainTextContent: string;
    };
    continueButtonText: string;
  };
}

export interface CheckInIntroWithImageStepData extends BaseStepData {
  screenType: StepTypes.CHECK_IN_INTRO_WITH_IMAGE;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    summary: {
      header: string;
      subheader: string;
      zodiacSignTitle: string;
      characterTraitTitle: string;
      luckyDaysTitle: string;
    };
    insight: {
      mainTextContent: string;
    };
    image: {
      src: string;
      alt: string;
      name: string;
      rating: number;
      reviews: number;
    };
    continueButtonText: string;
  };
}

export interface ResonateStepData extends BaseStepData {
  screenType: StepTypes.RESONATE;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    image: string;
    imageAlt: string;
    title?: string;
    hideTitles?: boolean;
  };
}

export interface PreferenceIntroStepData extends BaseStepData {
  screenType: StepTypes.PREFERENCE_INTRO;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    summary: {
      header: string;
      subheader: string;
      areaOfImprovementTitle: string;
      goalToAchieveTitle: string;
    };
    mainTextContent: string;
    continueButtonText: string;
  };
}

export interface ThankYouStepData extends BaseStepData {
  screenType: StepTypes.THANK_YOU;
  hasButtonBack?: boolean;
  otherEvent?: QUIZ_ANALYTIC_EVENTS;
  content: {
    title: string;
    subtitle: string;
    emotionalStateTitle: string;
    emotionalStateSubtitle: string;
    goalToAchieveTitle: string;
    goalToAchieveSubtitle: string;
    areaOfImprovementTitle: string;
    areaOfImprovementSubtitle: string;
    continueButtonText: string;
  };
}

export interface ContextPreferenceIntroProfileStepData extends BaseStepData {
  screenType: StepTypes.CONTEXT_PREFERENCE_INTRO_PROFILE;
  dataKey: string;
  componentType?: ComponentTypes;
  hasButtonBack?: boolean;
  content: {
    title: string;
    subtitle: string;
    zodiacNameTitle: string;
    goalToAchieveTitle: string;
    relationshipStatusTitle: string;
    bottomText: string;
    continueButtonText: string;
  };
}

export interface ContextPreferenceIntroStepData extends BaseStepData {
  screenType: StepTypes.CONTEXT_PREFERENCE_INTRO;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    title: string;
    subtitle: string;
    emotionalStateTitle: string;
    emotionalStateLabel: string;
    areaOfImprovementTitle: string;
    areaOfImprovementLabel: string;
    goalToAchieveTitle: string;
    goalToAchieveLabel: string;
    bottomText: string;
    continueButtonText: string;
  };
}

export interface PreferenceIntroWithImageStepData extends BaseStepData {
  screenType: StepTypes.PREFERENCE_INTRO_WITH_IMAGE;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    summary: {
      header: string;
      subheader: string;
      areaOfImprovementTitle: string;
      goalToAchieveTitle: string;
    };
    image: {
      src: string;
      alt: string;
      name: string;
      rating: number;
      reviews: number;
    };
    mainTextContent: string;
    continueButtonText: string;
  };
}

export interface MentionedInStepData extends BaseStepData {
  screenType: StepTypes.MENTIONED_IN;
  hasButtonBack?: boolean;
  content: {
    title: string;
    subtitle: string;
    quotesImage: {
      src: string;
      alt: string;
    };
    testimonial: string;
    testifierImage: {
      src: string;
      alt: string;
    };
    mentionedInText: string;
    mentionedInImage: {
      src: string;
      alt: string;
    };
    next: string;
  };
}

export interface ReadingBenefitsStepData extends BaseStepData {
  screenType: StepTypes.READING_BENEFITS;
  hasButtonBack?: boolean;
  content: {
    title: string;
    subtitle: string;
    benefits: [string, string?, string?];
    continueButtonText: string;
    mainImage: {
      src: string;
      alt: string;
    };
    auxillaryImage: {
      src: string;
      alt: string;
    };
  };
}

export interface SummaryScreenStepData extends BaseStepData {
  screenType: StepTypes.FINAL_SCREEN;
  hasButtonBack?: boolean;
  content: {
    title: string;
    subtitle: string;
    continueButtonText: string;
    goalToAchieve: {
      title: string;
      value: string;
      emoji: string;
    };
    readingType: {
      title: string;
      value: string;
      emoji: string;
    };
    psychicType: {
      title: string;
      value: string;
      emoji: string;
    };
    currentState: {
      title: string;
      emoji: string;
      points: [string, string?, string?];
      image: string;
    };
    withGuidance: {
      title: string;
      emoji: string;
      points: [string, string?, string?];
      image: string;
    };
  };
}

export interface NotAloneStepData extends BaseStepData {
  screenType: StepTypes.NOT_ALONE;
  hasButtonBack?: boolean;
  content: {
    title: string;
    subtitle: string;
    afterTitle: string;
    continueButtonText: string;
    cards: {
      name: string;
      description: string;
      image: {
        src: string;
        alt: string;
      };
      stats: {
        rating: number;
        reviews: number;
        consultations: number;
        yearsOfExperience: number;
      };
      fieldsOfExpertise: string[];
    }[];
  };
}

export interface WithCircleChartsStepData extends BaseStepData {
  screenType: StepTypes.WITH_CIRCLE_CHARTS;
  hasButtonBack?: boolean;
  content: {
    title: string;
    subtitle: string;
    continueButtonText: string;
    topChart: {
      text: string;
      value: number;
      title: string;
      description: string;
    };
    bottomChart: {
      text: string;
      value: number;
      title: string;
      description: string;
    };
  };
}

export interface PrivacyStepData extends BaseStepData {
  screenType: StepTypes.PRIVACY;
  hasButtonBack?: boolean;
  content: {
    title: string;
    image: {
      src: string;
      alt: string;
    };
    points: Array<{
      text: string;
      emoji: string;
    }>;
    continueButtonText: string;
  };
}

export interface SummaryStepData extends BaseStepData {
  screenType: StepTypes.SUMMARY;
  dataKey: string;
  content: {
    title: string;
    description: string;
    continueButtonText: string;
    sections: SectionData[];
  };
}

export interface PrivacySliderStepData extends BaseStepData {
  screenType: StepTypes.PRIVACY_SLIDER;
  hasButtonBack?: boolean;
  content: {
    continueButtonText: string;
    points: Array<{
      image: {
        src: string;
        alt: string;
      };
      title: string;
      subtitle: string;
    }>;
  };
}

export interface RecognizeGoalStepData extends BaseStepData {
  screenType: StepTypes.RECOGNIZE_GOAL;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    title: string;
    continueButtonText: string;
    goal: {
      header: string;
      text: string;
      disclaimerText: string;
    };
  };
}

export interface FreeOfferStepData extends BaseStepData {
  screenType: StepTypes.FREE_OFFER;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    title: string;
    continueButtonText: string;
    bullets: string[];
    giftImage: {
      src: string;
      alt: string;
      width: number;
      height: number;
    };
  };
}

export interface CongratulationsStepData extends BaseStepData {
  screenType: StepTypes.CONGRATULATIONS;
  dataKey: string;
  content: {
    title: string;
    description: string;
    cards: Array<{
      title: string;
      description: string;
      image: string;
    }>;
  };
}

export interface AnimatedMessageStepData extends BaseStepData {
  screenType: StepTypes.ANIMATED_MESSAGE;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    messages: Array<{
      avatar: string;
      name: string;
      text: string;
    }>;
    backgroundImage: string;
    title: string;
    description: string;
    continueButtonText: string;
  };
}

export interface SingleAnswerWithModalStepData extends BaseStepData {
  screenType: StepTypes.SINGLE_ANSWER_WITH_MODAL;
  dataKey: string;
  showCustomAnswer?: boolean;
  otherOpenEvent: QUIZ_ANALYTIC_EVENTS;
  otherClickEvent: QUIZ_ANALYTIC_EVENTS;
  content: {
    title: string;
    answers: Array<AnswerData>;
    progressTitle?: string;
    illustration?: string;
    description?: string;
    tip?: string;
    tipHighlight?: string;
    tipEnd?: string;
    subtitle?: string;
    emoji?: EmojiName;
    hideTitles?: boolean;
    testimonial?: {
      name: string;
      date: string;
      text: string;
    };
    customAnswer?: {
      title: string;
      placeholder: string;
      button: string;
    };
  };
}

export interface GenderStepData extends BaseStepData {
  screenType: StepTypes.GENDER;
  dataKey: string;
  hasButtonBack?: boolean;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
    shouldShowNonBinary?: boolean;
    emoji?: {
      title: string;
      subtitle: string;
    };
  };
}

export interface PlaceStepData extends BaseStepData {
  screenType: StepTypes.PLACE;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
  };
  locationDataKey: string;
  placeDataKey: string;
}

export interface TimeStepData extends BaseStepData {
  screenType: StepTypes.TIME;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
    tip: string;
  };
}

export interface ProcessingStepData extends BaseStepData {
  screenType: StepTypes.PROCESSING;
  content: {
    title?: string;
    titleStart?: string;
    titleEnd?: string;
    additionalTitleStart?: string;
    additionalTitleEnd?: string;
    tip?: string;
    description?: string;
    stages: Array<string>;
  };
}

export interface ProcessingWithPopupStepData extends BaseStepData {
  screenType: StepTypes.PROCESSING_WITH_POPUP;
  content: {
    title: string;
    nextButtonText: string;
    backButtonText: string;
    stepsProgress: Array<{ percentage: number; contentName: string }>;
    stepContent: Record<
      string,
      {
        listText: string;
        title?: string;
        openEvent?: QUIZ_ANALYTIC_EVENTS;
        clickEvent?: QUIZ_ANALYTIC_EVENTS;
      }
    >;
  };
}

export interface ExpertProcessingStepData extends BaseStepData {
  screenType: StepTypes.EXPERT_PROCESSING;
  content: {
    title: string;
    subtitle: string;
    picture: string;
    stages: Array<string>;
    loaderDuration: number;
  };
}

export interface ProcessingWithRedirectStepData extends BaseStepData {
  screenType: StepTypes.PROCESSING_WITH_REDIRECT;
  content: {
    title: string;
    stages: Array<string>;
  };
}

export interface AnswerData {
  text: string;
  value?: string;
  answerKey?: string;
  image?: string;
  emoji?: EmojiName | string;
  feedback?: {
    title: string;
    description: string;
  };
}
export interface AnswerStepData extends BaseStepData {
  screenType: StepTypes.ANSWER;
  dataKey: string;
  hasProgressBar?: boolean;
  hasVerticalOrientation?: boolean;
  content: {
    title: string;
    tip?: string;
    emoji?: string;
    subtitle?: string;
    illustration?: string;
    answers: Array<AnswerData>;
  };
}

export interface CompoundedTitleInformationalStepData extends BaseStepData {
  screenType: StepTypes.COMPOUNDED_TITLE_INFORMATIONAL;
  showPartnerSign?: boolean;
  content: {
    animatedImage?: ReactNode;
    image?: ReactNode;
    titleFirstLine?: string;
    titleSecondLine?: string;
    titleMiddle?: string;
    titleEnd?: string;
    titleLastLine?: string;
    subtitle?: string;
    next: string;
    back: string;
  };
}

export interface AnswerPriorityStepData extends BaseStepData {
  screenType: StepTypes.ANSWER_PRIORITY;
  dataKey: string;
  hasProgressBar?: boolean;
  hasVerticalOrientation?: boolean;
  content: {
    title: string;
    subtitle: string;
    answers: Array<AnswerData>;
  };
}

export interface SignUpStepData extends BaseStepData {
  screenType: StepTypes.SIGN_UP;
  dataKey: string;
  getGoal: (goal: string) => string;
  content: {
    title: string;
    subtitle: string;
  };
}

export interface SignUpWithGenderStepData extends BaseStepData {
  screenType: StepTypes.SIGN_UP_WITH_GENDER;
  dataKey: string;
  getGoal: (goal: string) => string;
  content: {
    title: string;
    subtitle: string;
  };
}

export interface SignInStepData extends BaseStepData {
  screenType: StepTypes.SIGN_IN;
  dataKey: string;
  getGoal: (goal: string) => string;
  content: {
    title: string;
    subtitle: string;
  };
}

export interface PasswordStepData extends BaseStepData {
  screenType: StepTypes.PASSWORD;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
  };
}

export interface VariousTopicsStepData extends BaseStepData {
  screenType: StepTypes.VARIOUS_TOPICS;
  dataKey: string;
  showCustomAnswer?: boolean;
  otherEvent?: QUIZ_ANALYTIC_EVENTS;
  content: {
    title: string;
    description: string;
    gridItems: Array<{
      image: string;
      label: string;
    }>;
  };
}

export interface SingleAnswerStepData extends BaseStepData {
  screenType: StepTypes.SINGLE_ANSWER;
  dataKey: string;
  showCustomAnswer?: boolean;
  content: {
    title: string;
    answers: Array<AnswerData>;
    progressTitle?: string;
    illustration?: string;
    description?: string;
    tip?: string;
    tipHighlight?: string;
    tipEnd?: string;
    afterAnswersText?: string;
    subtitle?: string;
    emoji?: string;
    hideTitles?: boolean;
    testimonial?: {
      name: string;
      date: string;
      text: string;
    };
    customAnswer?: {
      title: string;
      placeholder: string;
      button: string;
    };
  };
}

export interface SingleAnswerV2StepData extends BaseStepData {
  screenType: StepTypes.SINGLE_ANSWER_V2;
  dataKey: string;
  showCustomAnswer?: boolean;
  content: {
    title: string;
    answers: Array<AnswerData>;
    progressTitle?: string;
    illustration?: string;
    description?: string;
    tip?: string;
    tipHighlight?: string;
    tipEnd?: string;
    afterAnswersText?: string;
    subtitle?: string;
    emoji?: string;
    hideTitles?: boolean;
    testimonial?: {
      name: string;
      date: string;
      text: string;
    };
    customAnswer?: {
      title: string;
      placeholder: string;
      button: string;
    };
  };
}

export interface SingleAnswerWithFeedbackStepData extends BaseStepData {
  screenType: StepTypes.SINGLE_ANSWER_WITH_FEEDBACK;
  dataKey: string;
  otherEvent: QUIZ_ANALYTIC_EVENTS;
  content: {
    feedbackDisplayMode: 'immediate' | 'word-by-word';
    title: string;
    answers: Array<AnswerData>;
    giftOfferSection?: string;
    tip?: string;
    afterAnswersText?: string;
    subtitle?: string;
    hideTitles?: boolean;
    testimonial?: {
      name: string;
      date: string;
      text: string;
    };
    customAnswer?: {
      title: string;
      placeholder: string;
      button: string;
    };
    buttonLabel: string;
  };
}

export interface ChatQuestionStepData extends BaseStepData {
  screenType: StepTypes.CHAT_QUESTION;
  otherEvent: QUIZ_ANALYTIC_EVENTS;
  popupEvents: {
    openEvent: QUIZ_ANALYTIC_EVENTS;
    clickEvent: QUIZ_ANALYTIC_EVENTS;
  };
  dataKey: string;
  showCustomAnswer?: boolean;
  content: {
    title: string;
    tip?: string;
    stageTitle: string;
    stages: Array<string>;
    progressStages: Array<{
      minimalWordCount: number;
      progress: number;
      label: string;
    }>;
    buttonLabel: string;
    popupContent: {
      title: string;
      description: string;
      backButton: string;
      nextButton: string;
    };
  };
}

export interface DateStepData extends BaseStepData {
  screenType: StepTypes.DATE;
  dataKey: string;
  componentType?: ComponentTypes;
  content: {
    hideTitles?: boolean;
    title: string;
    subtitle?: string;
    illustration?: string;
    tip?: string;
    emoji?: string;
    label?: string;
    progressTitle?: string;
  };
}

export interface RelateStatementStepData extends BaseStepData {
  screenType: StepTypes.RELATE_STATEMENT;
  dataKey: string;
  content: {
    title: string;
    answers: Array<AnswerData>;
    statement: string;
    icon: string;
    hideTitles?: boolean;
  };
}

export interface UserNameStepData extends BaseStepData {
  screenType: StepTypes.USER_NAME;
  dataKey: string;
  content: {
    title: string;
    namePlaceholder?: string;
    tip?: string;
    continueButtonText: string;
    skipButtonText?: string;
    hideTitles?: boolean;
  };
}

export interface ProblemStepData extends BaseStepData {
  screenType: StepTypes.PROBLEM;
  getGoal?: (goal: string) => string;
  dataKey: string;
  content: {
    titleStart: string;
    titleEnd: string;
    answers: Array<AnswerData>;
    description?: string;
    subtitle?: string;
    emoji?: string;
    testimonial?: {
      name: string;
      date: string;
      text: string;
    };
  };
}

export interface SingleHorizontalAnswerStepData extends BaseStepData {
  screenType: StepTypes.SINGLE_HORIZONTAL_ANSWER;
  dataKey: string;
  content: {
    title: string;
    answers: Array<AnswerData>;
    illustration: string;
  };
}

export interface MultiAnswerStepData extends BaseStepData {
  screenType: StepTypes.MULTI_ANSWER;
  dataKey: string;
  componentType?: ComponentTypes;
  analytic?: Partial<AnalyticData> & {
    answerEvent?: QUIZ_ANALYTIC_EVENTS;
  };
  otherOpenEvent?: QUIZ_ANALYTIC_EVENTS;
  otherClickEvent?: QUIZ_ANALYTIC_EVENTS;
  content: {
    withoutOwnAnswer?: boolean;
    withHint?: boolean;
    ownAnswerModal?: boolean;
    ownAnswerField?: boolean;
    hideTitles?: boolean;
    title: string;
    titleLastLine?: string;
    answers: Array<AnswerData>;
    illustration?: string;
    tip?: string;
    tipHighlight?: string;
    tipEnd?: string;
    emoji?: string;
    description?: string;
    testimonial?: {
      name: string;
      date: string;
      text: string;
    };
  };
}

export interface EmailStepData extends BaseStepData {
  screenType: StepTypes.EMAIL;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    illustration: string;
    tip: MarkdownText;
    emoji?: {
      title: string;
    };
  };
}

export interface EmailWithDateStepData extends BaseStepData {
  screenType: StepTypes.EMAIL_WITH_DATE;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
  };
}

export interface EmailConsentStepData extends BaseStepData {
  screenType: StepTypes.EMAIL_CONSENT;
  dataKey: string;
  content: {
    title: string;
    subtitle?: string;
    image?: string;
    emoji?: {
      title: string;
    };
  };
}

export interface EmailConsentWithRedirectStepData extends BaseStepData {
  screenType: StepTypes.EMAIL_CONSENT_WITH_REDIRECT;
  otherClickEvent: QUIZ_ANALYTIC_EVENTS;
  dataKey: string;
  content: {
    title: string;
    subtitle?: string;
    image?: string;
    emoji?: {
      title: string;
    };
  };
}

export interface NameStepData extends BaseStepData {
  screenType: StepTypes.NAME;
  content: {
    title: string;
    subtitle: string;
    illustration?: string;
  };
  dataKey: string;
}

export interface OfferStepData extends BaseStepData {
  getGoal: (goal: string) => string;
  screenType: StepTypes.OFFER;
  componentType?: ComponentTypes;
  otherEvent?: QUIZ_ANALYTIC_EVENTS;
}

export interface AboutAppStepData extends BaseStepData {
  screenType: StepTypes.ABOUT_APP;
}

export interface ReviewsStepData extends BaseStepData {
  screenType: StepTypes.REVIEW;
  content: {
    reviews?: string;
    rating: string;
    title: string;
    next: string;
    reviewer?: string;
    subtitle?: string;
  };
}

export interface ContextReviewsStepData extends BaseStepData {
  screenType: StepTypes.CONTEXT_REVIEW;
  content: {
    reviews?: string;
    rating: string;
    title: string;
    next: string;
    reviewer?: string;
    subtitle?: string;
    disclaimer: string;
  };
}

export interface TestimonialStepData extends BaseStepData {
  screenType: StepTypes.TESTIMONIAL;
  content: {
    text: string;
    avatar?: string;
    name: string;
    address?: string;
  };
}

export interface PhoneNumberStepData extends BaseStepData {
  screenType: StepTypes.PHONE_NUMBER;
  dataKey: string;
}

export interface TopicToDiscussStepData extends BaseStepData {
  screenType: StepTypes.TOPIC_TO_DISCUSS;
  dataKey: string;
  otherOpenEvent: QUIZ_ANALYTIC_EVENTS;
  otherClickEvent: QUIZ_ANALYTIC_EVENTS;
  content: {
    progressTitle: string;
    title: string;
    modalTitle: string;
    modalButton: string;
    list: Array<{ text: AreaOfGuidance; icon: string }>;
  };
}

export interface TypeOfReadingStepData extends BaseStepData {
  screenType: StepTypes.TYPE_OF_READING;
  dataKey: string;
  content: {
    progressTitle: string;
    title: string;
    subtitle: string;
    list: Array<{ text: string; icon: string; value: string }>;
  };
}

export interface ExpertsCatalogueStepData extends BaseStepData {
  screenType: StepTypes.EXPERTS_CATALOGUE;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    button: string;
    modalTitle: string;
    modalDescription: string;
    modalButton: string;
    modalIcon: string;
  };
}

export interface CategorizedExpertsCatalogueStepData extends BaseStepData {
  screenType: StepTypes.CATEGORIZED_EXPERTS_CATALOGUE;
  dataKey: string;
  content: {
    title: string;
    subtitle: string;
    button: string;
  };
}

export interface ChatStepData extends BaseStepData {
  screenType: StepTypes.CHAT;
  otherClickEvent: QUIZ_ANALYTIC_EVENTS;
}

export interface ChatExperimentStepData extends BaseStepData {
  screenType: StepTypes.CHAT_EXPERIMENT;
  otherClickEvent: QUIZ_ANALYTIC_EVENTS;
}

export type CheckInIntroScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  zodiac: ZodiacTypes;
  userPreferences: UserState;
  characterTrait: string;
  luckyDays: Array<number>;
} & CheckInIntroStepData;

export type ContextCheckInIntroScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  zodiac: ZodiacTypes;
  userPreferences: UserState;
  characterTrait: string;
  luckyDays: Array<number>;
} & ContextCheckInIntroStepData;

export type CheckInIntroWithImageScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  zodiac: ZodiacTypes;
  userPreferences: UserState;
  characterTrait: string;
  luckyDays: Array<number>;
} & CheckInIntroWithImageStepData;

export type PreferenceIntroScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  userPreferences: UserState;
  areaOfImprovement: string;
  goalToAchieve: string;
  zodiac: string;
} & PreferenceIntroStepData;

export type ContextPreferenceIntroProfileScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  goalToAchieve: string;
  relationshipStatus: string;
  zodiac: string;
} & ContextPreferenceIntroProfileStepData;

export type ContextPreferenceIntroScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  zodiac: string;
} & ContextPreferenceIntroStepData;

export type PreferenceIntroWithImageScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  userPreferences: UserState;
  areaOfImprovement: string;
  goalToAchieve: string;
  zodiac: string;
} & PreferenceIntroWithImageStepData;

export type ThankYouScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  redirectToChat: () => void;
} & ThankYouStepData;

export type MentionedInScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
} & MentionedInStepData;

export type ReadingBenefitsScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
} & ReadingBenefitsStepData;

export type SummaryScreenScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
} & SummaryScreenStepData;

export type NotAloneScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
} & NotAloneStepData;

export type WithCircleChartsScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
} & WithCircleChartsStepData;

export type PrivacyScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
} & PrivacyStepData;

export type PrivacySliderScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
} & PrivacySliderStepData;

export type GenderScreenProps = {
  isDisabled?: boolean;
  onChosen: (choose: string, answerKey?: string) => void;
  onBack: () => void;
} & GenderStepData;

export type AnimationInformationScreenProps = {
  onContinue: () => void;
  onBack: () => void;
  goal: string;
  zodiac: string;
} & AnimationInformationStepData;

export type ContextAnimationInformationScreenProps = {
  onContinue: () => void;
  onBack: () => void;
  goal: string;
  titleFirstLine?: string;
  titleSecondLine?: string;
  titleMiddle?: string;
  hightLightText?: string;
  titleEnd?: string;
  text?: string;
  zodiac: string;
} & ContextAnimationInformationStepData;

export type DisappearingInformationScreenProps = {
  onContinue: () => void;
  onBack: () => void;
} & DisappearingInformationStepData;

export type BlurredInformationScreenProps = {
  onContinue: () => void;
  onBack: () => void;
} & BlurredInformationStepData;

export type BlurredSignUpScreenProps = {
  onBack: () => void;
  initialValue?: string;
  signUpByGoogleStatus: LoadingStatuses;
  signUpByFacebookStatus: LoadingStatuses;
  errorMessage: string;
  goal?: string;
  signUpWithGoogle: (data: SignUpGoogleResponseData) => Promise<boolean>;
  signUpWithFacebook: (data: FacebookLoginData) => Promise<void>;
  goNext: (data: { email: string }, helpers: FormikHelpers<{ email: string }>) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  onClickExplanation: () => void;
} & BlurredSignUpStepData;

export type BlurredPasswordScreenProps = {
  onBack: () => void;
  initialValue?: string;
  errorMessage: string;
  signUp: (
    password: string,
    helpers: {
      setErrors: (errors: Partial<{ password: string }>) => void;
    },
  ) => Promise<void>;
  email: string;
} & BlurredPasswordStepData;

export type MultipleAnswerScreenProps = {
  onBack: () => void;
  onChosen: (chooses: Array<string>) => void;
  onCustomOpen: () => void;
  onCustomChosen: (customAnswerText: string) => void;
  goNext: (chooses: Array<string>) => void;
  initialValue?: Array<string>;
} & MultiAnswerStepData;

export type NameScreenProps = {
  initialValue?: string;
  onSubmit: (name: string, helpers: FormikHelpers<{ name: string }>) => Promise<void>;
  onBack: () => void;
} & NameStepData;

export type PlaceScreenProps = {
  onBack: () => void;
  onChosen: (coordinates: Coordinates, location: string) => void;
  initialCoordinates?: Coordinates;
  initialLocation?: string;
} & PlaceStepData;

export type ProcessingScreenProps = {
  onFinished: () => void;
  userPreferences: UserState;
  goal: string;
} & ProcessingStepData;

export type ProcessingWithPopupScreenProps = {
  onFinished: () => void;
  onBack: () => Promise<void>;
  onPopupOpen: (event: QUIZ_ANALYTIC_EVENTS) => void;
  onPopupClick: (event: QUIZ_ANALYTIC_EVENTS, choice: string) => void;
} & ProcessingWithPopupStepData;

export type ExpertProcessingScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
} & ExpertProcessingStepData;

export type SingleAnswerScreenProps = {
  onBack: () => void;
  onChosen: (choose?: string, key?: string) => Promise<void>;
} & SingleAnswerStepData;

export type SingleAnswerV2ScreenProps = {
  onBack: () => void;
  onChosen: (choose: string, key?: string) => void;
} & SingleAnswerV2StepData;

export type VariousTopicsScreenProps = {
  onBack: () => void;
  onContinue: () => Promise<void>;
  userPreferences: UserState;
} & VariousTopicsStepData;

export type SingleAnswerWithFeedbackScreenProps = {
  onBack: () => void;
  onSelected: (value: string) => void;
  onChosen: (choose: string, key?: string) => void;
} & SingleAnswerWithFeedbackStepData;

export type ChatQuestionScreenProps = {
  onBack: () => void;
  onChosen: (choose: string, key?: string) => void;
  onButtonClick: (choose: string) => void;
  onPopupButtonClick: (buttonLabel: string) => void;
  onPopupOpen: () => void;
} & ChatQuestionStepData;

export type DateScreenProps = {
  onBack: () => void;
  onSubmit: (date: string) => void;
} & DateStepData;

export type RecognizeGoalScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  userPreferences: UserState;
} & RecognizeGoalStepData;

export type FreeOfferScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  userPreferences: UserState;
} & FreeOfferStepData;

export type AnimatedMessageScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  userPreferences: UserState;
} & AnimatedMessageStepData;

export type CongratulationsScreenProps = {
  onContinue: () => Promise<void>;
  onBack: () => void;
  userPreferences: UserState;
} & CongratulationsStepData;

export type SingleAnswerWithModalScreenProps = {
  onBack: () => void;
  onChosen: (choose: string, key?: string) => void;
  onCustomOpen: () => void;
  onCustomChosen: (customAnswer: string, key: string) => void;
} & SingleAnswerWithModalStepData;

export type ResonateScreenProps = {
  onContinue: (withAnswer?: string) => void;
  onBack: () => void;
} & ResonateStepData;

export type RelateStatementScreenProps = {
  onBack: () => void;
  onChosen: (choose: string, key?: string) => void;
} & RelateStatementStepData;

export type UserNameScreenProps = {
  onBack: () => void;
  onSubmit: (name: string) => void;
  onSkip: () => void;
} & UserNameStepData;

export type SingleHorizontalAnswerScreenProps = {
  onBack: () => void;
  onChosen: (choose: string) => void;
} & SingleHorizontalAnswerStepData;

export type TestimonialScreenProps = {
  onBack: () => void;
  onContinue: () => void;
} & TestimonialStepData;

export type TimeScreenProps = {
  onSelected: (time?: string) => void;
  onBack: () => void;
} & TimeStepData;

export type EmailModalFormHelpers = FormikHelpers<{
  email: string;
  password?: string;
}>;

export type EmailFormHelpers = FormikHelpers<{
  email: string;
  emailConsent: boolean;
  password?: string;
}>;

export type EmailScreenProps = {
  onBack: () => void;
  initialValue?: string;
  onSubmit: (
    data: { email: string; emailConsent?: boolean },
    helpers: EmailFormHelpers | EmailModalFormHelpers,
  ) => Promise<void>;
} & EmailStepData;

export type EmailConsentScreenProps = {
  onSubmit: (isAgree: boolean) => void;
  redirect: () => void;
  onBack: () => void;
  goNext: (choice?: string) => void;
  showEmailForm?: boolean;
  onSubmitEmail: (data: { email: string }, helpers: EmailConsentDataHelpers) => void;
} & EmailConsentStepData;

export type EmailConsentWithRedirectScreenProps = {
  onSubmit: (isAgree: boolean) => void;
  onBack: () => void;
  goNext: (choice?: string) => void;
  redirect: () => void;
} & EmailConsentWithRedirectStepData;

export type EmailWithDateScreenProps = {
  onBack: () => void;
  initialValue?: string;
  onSubmit: (email: string, helpers: EmailFormHelpers | EmailModalFormHelpers) => Promise<void>;
  onDateChange: (date: string) => void;
} & EmailWithDateStepData;

export type OfferScreenProps = {
  goNext: () => void;
  goBack: () => void;
  goal: string;
  redirectToChat: () => void;
} & OfferStepData;

export type AboutAppScreenProps = {
  goNext: () => void;
  goBack: () => void;
  name: string;
} & AboutAppStepData;

export type SignUpScreenProps = {
  onBack: () => void;
  initialValue?: string;
  signUpByGoogleStatus: LoadingStatuses;
  errorMessage: string;
  goal?: string;
  signUpWithGoogle: (data: SignUpGoogleResponseData) => Promise<boolean>;
  goNext: (data: { email: string }, helpers: FormikHelpers<{ email: string }>) => void;
  expert: Expert | null;
} & SignUpStepData;

export type SignUpWithGenderScreenProps = {
  goNext: (
    data: { email: string; gender: string },
    helpers: FormikHelpers<{ email: string; gender: string }>,
  ) => void;
  onBack: () => void;
  initialValue?: string;
  signUpByGoogleStatus: LoadingStatuses;
  errorMessage: string;
  goal?: string;
  signUpWithGoogle: (data: SignUpGoogleResponseData) => Promise<boolean>;
  expert: Expert | null;
} & SignUpWithGenderStepData;

export type SignInScreenProps = {
  initialValue?: string;
  userEmail: string;
  singInError: string;
  signInLoadingStatus: LoadingStatuses;
  onBack: () => void;
  goNext: (
    data: { email: string; password: string },
    helpers: FormikHelpers<{ email: string; password: string }>,
  ) => void;
  trackValidationError: (field: 'password' | 'email') => void;
  trackForgotPasswordClick: () => void;
} & SignInStepData;

export type PasswordScreenProps = {
  onBack: () => void;
  initialValue?: string;
  errorMessage: string;
  signUp: (
    password: string,
    helpers: {
      setErrors: (errors: Partial<{ password: string }>) => void;
    },
  ) => Promise<void>;
  email: string;
} & PasswordStepData;

export type ProcessingWithRedirectScreenProps = {
  onFinished: () => void;
} & ProcessingWithRedirectStepData;

export type ProblemScreenProps = {
  onBack: () => void;
  onChosen: (choose: string) => void;
  userPreferences: UserState;
  zodiac: string;
  goal: string;
} & ProblemStepData;

export type ReviewScreenProps = {
  onContinue: () => void;
  goBack: () => void;
  goal: string;
  isDesktop?: boolean;
} & ReviewsStepData;

export type ContextReviewScreenProps = {
  onContinue: () => void;
  goBack: () => void;
  goal: string;
} & ContextReviewsStepData;

export type PhoneNumberScreenProps = {
  onSubmit: (phoneNumber: string) => void;
  skip: () => void;
  userCountry?: LocationCountry;
} & PhoneNumberStepData;

export type TopicToDiscussScreenProps = {
  onChosen: (value: string) => void;
  sendCustomAnswer: (value: string) => void;
  onOtherClick: () => void;
  goBack: () => void;
} & TopicToDiscussStepData;

export type TypeOfReadingScreenProps = {
  onChosen: (data: { value: string; text: string }) => void;
  goBack: () => void;
} & TypeOfReadingStepData;

export type ExpertsCatalogueScreenProps = {
  goBack: () => void;
  experts: Array<Expert>;
  chooseExpert: (id: string) => void;
  loadingStatus: LoadingStatuses;
  isInitialExpertsLoaded: boolean;
  fetchMoreExperts: () => void;
} & ExpertsCatalogueStepData;

export type CategorizedExpertsCatalogueScreenProps = {
  goBack: () => void;
  goNext: (id: string) => void;
  userPreferences: UserState;
} & CategorizedExpertsCatalogueStepData;

export type ChatScreenProps = {
  onBack: () => void;
  onContinue: () => void;
  expert: Expert | null;
  incomingMessage: string;
  outgoingMessage: string;
  hasChatSessionEverBeen: boolean;
  iceBreakingTipSelected: (context?: string) => void;
  sendMessage: (data: { text: string }) => void;
} & ChatStepData;

export type SummaryScreenProps = {
  goNext: () => void;
  goBack: () => void;
} & SummaryStepData;

export type ChatExperimentScreenProps = {
  onBack: () => void;
  onContinue: () => void;
  expert: Expert | null;
  incomingMessage: string;
  outgoingMessage: string;
  hasChatSessionEverBeen: boolean;
  iceBreakingTipSelected: (context?: string) => void;
  sendMessage: (data: { text: string }) => void;
} & ChatExperimentStepData;

export enum SectionType {
  FOCUS_ON_GOALS_GRADIENT = 'FOCUS_ON_GOALS_GRADIENT',
  QUIZ_SUMMARY = 'QUIZ_SUMMARY',
  USER_SELECTED_PREFERENCES = 'USER_SELECTED_PREFERENCES',
  FOCUS_ON_GOALS_CARD = 'FOCUS_ON_GOALS_CARD',
  APP_FEEDBACK = 'APP_FEEDBACK',
}

export type SectionData =
  | FocusOnGoalsGradientSectionData
  | QuizSummarySectionData
  | UserSelectedPreferencesSectionData
  | FocusOnGoalsCardSectionData
  | AppFeedbackSectionData;

export type FocusOnGoalsGradientSectionData = {
  sectionType: SectionType.FOCUS_ON_GOALS_GRADIENT;
  content: FocusOnGoalsGradientSectionProps;
};

export type QuizSummarySectionData = {
  sectionType: SectionType.QUIZ_SUMMARY;
  content: QuizSummarySectionProps;
};

export type UserSelectedPreferencesSectionData = {
  sectionType: SectionType.USER_SELECTED_PREFERENCES;
  content: UserSelectedPreferencesProps;
};

export type FocusOnGoalsCardSectionData = {
  sectionType: SectionType.FOCUS_ON_GOALS_CARD;
  content: FocusOnGoalsSectionCardSectionProps;
};

export type AppFeedbackSectionData = {
  sectionType: SectionType.APP_FEEDBACK;
  content: SectionFeedbackProps;
};

export type FocusOnGoalsGradientSectionProps = {
  title: string;
  buttonLabel: string;
  label: string;
  image: {
    src: string;
    alt: string;
  };
  paragraph: string;
  onContinue?: () => void;
};

export type FocusOnGoalsSectionCardSectionProps = {
  directive: string;
  title: string;
  subtitle: string;
  image: {
    src: string;
    alt: string;
  };
  list: Array<{
    heading: string;
    text: string;
  }>;
  label: string;
  onContinue?: () => void;
  buttonLabel: string;
};

export type QuizSummarySectionProps = {
  zodiac: string;
  userName: string;
  gender: string;
  dateOfBirth: string;
  relationshipStatus: string;
  emotionalState: string;
  decisionMakingApproach: string;
  anxietyLevel: ConfidenceLevel;
  confidence: ConfidenceLevel;
  seeks: string;
  labels: {
    dateOfBirth: string;
    relationshipStatus: string;
    emotionalState: string;
    decisionMakingApproach: string;
    anxietyLevel: string;
    confidence: string;
    seeks: string;
  };
};

export type SectionFeedbackProps = {
  onContinue?: () => void;
  appTitle: string;
  appReviewsText: string;
  appRatingText: string;
  askPsychicButtonText: string;
  successStoriesLabel: string;
  feedbackSinceText: string;
};

export type UserSelectedPreferencesProps = {
  title: string;
  image: {
    src: string;
    alt: string;
  };
  list: Array<{
    heading: string;
    text: string;
  }>;
};
