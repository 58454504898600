import { GrowthBook } from '@growthbook/growthbook';
import { normalizeError } from 'src/core/common/utils/errors';
import { AppEnvironmentService } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { Config } from 'src/config';
import { checkIsClientSide } from 'src/core/common/utils/checkIsClientSide';
import {
  ExperimentDescription,
  ExperimentRawResult,
  FeatureDefinition,
  FeatureFlagsAttributes,
  FeatureFlagsConfig,
  FeatureRawResult,
  FeatureValue,
} from '../entities';
import { FeatureFlags } from './FeatureFlags';

export class GrowthBookFeatureFlags implements FeatureFlags {
  private growthBookInstance: GrowthBook;

  private appEnvironmentService: AppEnvironmentService;

  private appConfig: Config;

  private logger: Logger;

  private trackExperimentListeners: Array<
    (experiment: ExperimentDescription<any>, result: ExperimentRawResult<any>) => void
  > = [];

  private featureUsageListeners: Array<
    (featureName: string, result: FeatureRawResult<any>) => void
  > = [];

  private stateUpdateListeners: Array<() => void> = [];

  private featuresUpdateListeners: Array<(features: Record<string, FeatureDefinition>) => void> =
    [];

  private featuresLoadedListeners: Array<(features: Record<string, FeatureDefinition>) => void> =
    [];

  private featuresLoadingFailedListeners: Array<(loadingError: Error) => void> = [];

  constructor(
    passedConfig: FeatureFlagsConfig = {},
    appEnvironmentService: AppEnvironmentService,
    appConfig: Config,
    logger: Logger,
  ) {
    const notifyTrackExperimentCallback = this.notifyTrackExperimentListeners.bind(this);
    const notifyFeatureUsageListeners = this.notifyFeatureUsageListeners.bind(this);
    const notifyStateUpdateListeners = this.notifyStateUpdateListeners.bind(this);

    this.appEnvironmentService = appEnvironmentService;
    this.appConfig = appConfig;
    this.logger = logger;

    const config = {
      ...passedConfig,
      apiHost: this.appConfig.growthbook.apiHost,
      clientKey: this.appConfig.growthbook.clientKey,
      // Skip all experiments
      qaMode: this.appEnvironmentService.isTestingEnv(),
    };

    this.growthBookInstance = new GrowthBook({
      ...config,
      trackingCallback: notifyTrackExperimentCallback,
      onFeatureUsage: notifyFeatureUsageListeners,
    });

    this.growthBookInstance.setRenderer(notifyStateUpdateListeners);
  }

  loadFeatures(): void {
    const defaultFeatures = this.getFeatures();
    this.growthBookInstance
      .loadFeatures()
      .then(() => {
        const loadedFeatures = this.getFeatures();
        const features = {
          ...defaultFeatures,
          ...loadedFeatures,
        };

        this.setFeatures(features);
        this.notifyFeaturesLoadedListeners(features);
        this.logger.info('FeatureFlags loaded', {
          data: this.getFeatures(),
        });
      })
      .catch((e) => {
        const error = normalizeError(e);
        this.logger.error(error);

        this.notifyFeaturesLoadingFailedListeners(error);
      });
  }

  getFeatures(): FeatureFlagsConfig['features'] {
    return this.growthBookInstance.getFeatures() as FeatureFlagsConfig['features'];
  }

  setForcedFeatureValues(featureValues: Record<string, unknown>) {
    const map = new Map(Object.entries(featureValues));
    this.growthBookInstance.setForcedFeatures(map);
  }

  getAttributes(): FeatureFlagsAttributes {
    return this.growthBookInstance.getAttributes();
  }

  setAttributes(attributes: FeatureFlagsAttributes): void {
    const currentAttributes = this.getAttributes();
    const newAttributes = {
      ...currentAttributes,
      ...attributes,
    };
    this.growthBookInstance.setAttributes(newAttributes);
  }

  setIdentifier(identifier: string) {
    this.setAttributes({
      id: identifier,
    });
  }

  setStateUpdateHandler(callback: () => void) {
    this.stateUpdateListeners.push(callback);
  }

  isFeatureOn(featureName: string) {
    return this.growthBookInstance.isOn(featureName);
  }

  isFeatureOff(featureName: string) {
    return this.growthBookInstance.isOff(featureName);
  }

  getFeatureValue<T extends FeatureValue = FeatureValue>(featureName: string, defaultValue: T): T {
    return this.growthBookInstance.getFeatureValue<T>(featureName, defaultValue) as T;
  }

  getFeatureRawResult<T extends FeatureValue>(featureName: string): FeatureRawResult<T | null> {
    return this.growthBookInstance.evalFeature(featureName);
  }

  getExperimentGroup<T extends FeatureValue = string>(experimentName: string): T | null {
    const result = this.getFeatureRawResult<T>(experimentName);

    if (!result || !result.value) {
      if (checkIsClientSide()) {
        this.logger.warn('Experiment not found', {
          data: experimentName,
        });
      }

      return null;
    }

    return result.value;
  }

  getAllExperimentsGroup(): Record<string, string> {
    const features = this.getFeatures();

    if (!features) return {};

    return Object.keys(features).reduce((acc: Record<string, string>, featureName) => {
      const feature = this.getFeatureRawResult(featureName);

      if (!feature.experiment) return acc;

      const experimentGroup = this.getExperimentGroup(featureName);

      if (!experimentGroup) return acc;

      acc[featureName] = experimentGroup;
      return acc;
    }, {});
  }

  onTrackExperiment<T extends FeatureValue = FeatureValue>(
    callback: (experiment: ExperimentDescription<T>, result: ExperimentRawResult<T>) => void,
  ) {
    this.trackExperimentListeners.push(callback);
  }

  private notifyTrackExperimentListeners(
    experiment: ExperimentDescription,
    result: ExperimentRawResult,
  ) {
    const features = this.getFeatures();

    const preventTrack =
      !!features && features[experiment.key] && features[experiment.key].shouldNotTrack;

    if (preventTrack) return;

    this.trackExperimentListeners.forEach((listener) => {
      try {
        listener(experiment, result);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }

  onFeatureUsage<T extends FeatureValue>(
    callback: (featureName: string, result: FeatureRawResult<T>) => void,
  ) {
    this.featureUsageListeners.push(callback);
  }

  onFeaturesUpdated(callback: (features: Record<string, FeatureDefinition>) => void) {
    this.featuresUpdateListeners.push(callback);
  }

  onFeaturesLoaded(callback: (features: Record<string, FeatureDefinition>) => void) {
    this.featuresLoadedListeners.push(callback);
  }

  onFeaturesLoadingFailed(error: (error: Error) => void) {
    this.featuresLoadingFailedListeners.push(error);
  }

  private setFeatures(features: Record<string, FeatureDefinition>) {
    this.growthBookInstance.setFeatures(features);
    this.notifyFeaturesUpdateListeners(features);
  }

  private notifyFeatureUsageListeners(featureName: string, result: FeatureRawResult) {
    this.featureUsageListeners.forEach((listener) => {
      try {
        listener(featureName, result);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }

  private notifyStateUpdateListeners() {
    this.stateUpdateListeners.forEach((listener) => {
      try {
        listener();
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }

  private notifyFeaturesUpdateListeners(features: Record<string, FeatureDefinition>) {
    this.featuresUpdateListeners.forEach((listener) => {
      try {
        listener(features);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }

  private notifyFeaturesLoadedListeners(features: Record<string, FeatureDefinition>) {
    this.featuresLoadedListeners.forEach((listener) => {
      try {
        listener(features);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }

  private notifyFeaturesLoadingFailedListeners(loadingError: Error) {
    this.featuresLoadingFailedListeners.forEach((listener) => {
      try {
        listener(loadingError);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }
}
