import { createAction } from '@reduxjs/toolkit';
import { config } from 'src/config';
import { SECOND } from 'src/core/common/constants';
import { AppThunk } from 'src/core/common/store';
import { delay } from 'src/core/common/utils/delay';
import { randomNumber } from 'src/core/common/utils/random';
import { ExpertId } from 'src/core/experts/entities';
import { getChatCount, getIncomingMessage } from './selectors';

export const messageSent = createAction<{ expertId: ExpertId; text: string }>('chat/MESSAGE_SENT');

export const messageReceived =
  createAction<{ expertId: ExpertId; text: string }>('chat/MESSAGE_RECEIVED');
export const chatCountIncreased = createAction(`chat/CHAT_COUNT_INCREASED`);

export const chatSessionUpdated = createAction<ExpertId>('chat/SESSION_UPDATED');

export const messageReceivedRequest =
  (expertId: ExpertId): AppThunk =>
  async (dispatch, getState) => {
    const incomingMessage = getIncomingMessage(expertId)(getState());
    const chatCount = getChatCount(getState());

    if (!incomingMessage) {
      await delay(3 * SECOND);

      const randomIndex = randomNumber(0, config.expertMessages.length - 1);
      const textMessage = config.expertMessages[chatCount] ?? config.expertMessages[randomIndex];

      dispatch(messageReceived({ expertId, text: textMessage }));
      dispatch(chatCountIncreased());
    }
  };
