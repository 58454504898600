import { ApiError } from './ApiError';

export class InvalidCredentialsError extends ApiError {
  public message: string;

  constructor(err: ApiError) {
    super(err);
    this.message = 'Invalid email or password';
  }
}
