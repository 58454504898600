import { createReducer } from '@reduxjs/toolkit';
import { ChatBotState } from './state';
import {
  addAnswer,
  addExpertName,
  addMessage,
  chatEnded,
  resetChatEnded,
  setActiveChatBotName,
  setBotIsNotTyping,
  setBotIsTyping,
  setChatBotScenarioStepId,
} from './actions';

export const chatBotInitialState: ChatBotState = {
  botIsTyping: false,
  messages: [],
  answers: {},
  chatIsEnded: false,
};

export const chatBotReducer = createReducer(chatBotInitialState, (builder) => {
  builder
    .addCase(setActiveChatBotName, (state, { payload }) => ({
      ...state,
      activeChatBotName: payload.name,
    }))
    .addCase(setChatBotScenarioStepId, (state, { payload }) => ({
      ...state,
      currentStepId: payload.stepId,
    }))
    .addCase(addMessage, (state, { payload }) => ({
      ...state,
      messages: [...state.messages, payload],
    }))
    .addCase(addExpertName, (state, { payload }) => ({
      ...state,
      answers: {
        expertName: payload,
      },
    }))
    .addCase(setBotIsTyping, (state) => ({
      ...state,
      botIsTyping: true,
    }))
    .addCase(setBotIsNotTyping, (state) => ({
      ...state,
      botIsTyping: false,
    }))
    .addCase(addAnswer, (state, { payload }) => ({
      ...state,
      answers: {
        ...state.answers,
        [payload.question]: payload.answer,
      },
    }))
    .addCase(chatEnded, (state) => ({
      ...state,
      chatIsEnded: true,
    }))
    .addCase(resetChatEnded, (state) => ({
      ...state,
      chatIsEnded: false,
    }));
});
