import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class BlogMainFindAdvisorClickEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class BlogMainFindAdvisorClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogMainFindAdvisorClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_blog_main_page_find_advisor_click');
  }
}
