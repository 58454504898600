import type { AmplitudeClient } from 'amplitude-js';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { ProductName, ProductNameType } from 'src/core/payments/entities';
import { Config } from 'src/config';
import { UtmTags } from 'src/core/user/entities';
import { UtmTagsService } from 'src/core/user/services';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';
import { ObservabilitySystem } from 'src/core/common/observability/interfaces';
import { SpanStatusCode } from 'src/core/common/observability/entities';
import { isSuccessfulStatusCode } from 'src/core/common/utils/http/isSuccessfulStatusCode';
import { QUIZ_ANALYTIC_EVENTS } from '../../events';
import { AmplitudeService } from './AmplitudeService';

export class AmplitudeClientService implements AmplitudeService {
  private instance?: AmplitudeClient;

  constructor(
    private utmTagsService: UtmTagsService,
    private readonly config: Config,
    private readonly observabilitySystem: ObservabilitySystem,
  ) {
    this.utmTagsService = utmTagsService;
    this.config = config;
    this.observabilitySystem = observabilitySystem;

    this.updateUtmTags(this.utmTagsService.getUtmTags());
  }

  async updateUtmTags(utmTags: UtmTags) {
    await this.setUserProperties({
      media_source: utmTags.utm_source,
      ...utmTags,
    });
  }

  async getDeviceId(): Promise<string> {
    const instance = await this.getInstance();
    return instance.options.deviceId || '';
  }

  async getSessionId(): Promise<string> {
    const instance = await this.getInstance();
    return `${instance.getSessionId()}`;
  }

  async setUserProperties(userData: Record<string, unknown>) {
    const instance = await this.getInstance();
    instance.setUserProperties(userData);
  }

  async setUserId(userId: string) {
    const instance = await this.getInstance();
    instance.setUserId(userId);
  }

  async launchFirstTime() {
    return this.logEvent('launch_first_time', { funnel_stage: 'quiz' });
  }

  async launch() {
    return this.logEvent('launch', { funnel_stage: 'quiz' });
  }

  async trackQuizEvent(quizEvent: QUIZ_ANALYTIC_EVENTS, eventProperties: Record<string, any> = {}) {
    return this.logEvent(quizEvent, eventProperties);
  }

  async privateDataCollectionAgreed() {
    return this.logEvent('flow_intro_accept_cookies');
  }

  async setCurrentQuizName(quizName: string) {
    const instance = await this.getInstance();
    const identify = new instance.Identify();
    identify.setOnce('flow', quizName);
    return instance.identify(identify);
  }

  async paymentCardChosen() {
    return this.logEvent('payment_card_chosen', {
      funnel_stage: 'quiz',
    });
  }

  async paymentPaypalChosen() {
    return this.logEvent('payment_paypal_chosen', {
      funnel_stage: 'quiz',
    });
  }

  async paymentError(reason: string) {
    return this.logEvent('payment_error', {
      reason,
      funnel_stage: 'quiz',
    });
  }

  async paymentRetried() {
    return this.logEvent('payment_retry', {
      funnel_stage: 'quiz',
    });
  }

  async paymentSuccess({ productId }: { productId: string }) {
    return this.logEvent('payment_success', {
      product_id: productId,
      funnel_stage: 'quiz',
    });
  }

  async extendedLpOpened() {
    this.setUserProperties({
      landing_page_id: 'lp_extended-landing',
    });
    return this.logEvent('lp_extended_open');
  }

  async extendedLpGetReport() {
    return this.logEvent('lp_extended_get_report_click');
  }

  async billingLpOpened() {
    this.setUserProperties({
      landing_page_id: 'lp_billing',
    });
    return this.logEvent('lp_billing_open');
  }

  async billingLpGetReport() {
    return this.logEvent('lp_billing_get_report_click');
  }

  async billingLpAdditionalDiscountPopup() {
    return this.logEvent('lp_billing_extra_popup');
  }

  async billingLpGetAdditionalDiscount() {
    return this.logEvent('lp_billing_extra_click');
  }

  async additionalDiscountLpOpened() {
    this.setUserProperties({
      landing_page_id: 'lp_additional-discount',
    });
    return this.logEvent('lp_addition_discount_open');
  }

  async additionalDiscountLpGetReport() {
    return this.logEvent('lp_addition_discount_get_click');
  }

  async numerologyLpOpened(pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'lp_numerology',
    });
    return this.logEvent('lp_upsale_screen_open', {
      page_num: pageOrder,
      product_id: ProductName.NUMEROLOGY_CALCULATOR,
    });
  }

  async numerologyLpGetReport(pageOrder: number) {
    return this.logEvent('lp_upsale_get_report_click', {
      page_num: pageOrder,
      product_id: ProductName.NUMEROLOGY_CALCULATOR,
    });
  }

  async numerologyLpSkip(pageOrder: number) {
    return this.logEvent('lp_upsale_skip_click', {
      page_num: pageOrder,
      product_id: ProductName.NUMEROLOGY_CALCULATOR,
    });
  }

  async tarotLpOpened(pageOrder: number) {
    this.setUserProperties({
      landing_page_id: 'lp_tarot',
    });
    return this.logEvent('lp_upsale_screen_open', {
      page_num: pageOrder,
      product_id: ProductName.TAROT_SPREAD_FOR_2022,
    });
  }

  async tarotLpGetReport(pageOrder: number) {
    return this.logEvent('lp_upsale_get_report_click', {
      page_num: pageOrder,
      product_id: ProductName.TAROT_SPREAD_FOR_2022,
    });
  }

  async tarotLpSkip(pageOrder: number) {
    return this.logEvent('lp_upsale_skip_click', {
      page_num: pageOrder,
      product_id: ProductName.TAROT_SPREAD_FOR_2022,
    });
  }

  async subscriptionLandingOpen() {
    return this.logEvent('subscription_landing_open');
  }

  async subscriptionLandingClicked(productId: ProductNameType) {
    return this.logEvent('subscription_landing_click', {
      product_id: productId,
    });
  }

  async appnebulaCatalogueLpOpen() {
    return this.logEvent('appnebula_catalogue_open');
  }

  async appnebulaCatalogueExpertClick(value: string) {
    return this.logEvent('appnebula_catalogue_click', {
      context: value,
    });
  }

  async appnebulaCatalogueExpertLpOpen(value: string) {
    return this.logEvent('appnebula_catalogue_expert_open', {
      context: value,
    });
  }

  async appnebulaCatalogueExploreAllClick() {
    return this.logEvent('appnebula_explore_all_advisors');
  }

  async appnebulaFirstQuestionLpOpen() {
    return this.logEvent('appnebula_first_question_open');
  }

  async registrationLpOpened(value: string) {
    return this.logEvent('registration_screen_open', {
      context: value,
    });
  }

  async registrationLpClicked(value: string) {
    return this.logEvent('registration_screen_clicked', {
      context: value,
    });
  }

  async registrationFormClicked(value: string) {
    return this.logEvent('registration_emailform_clicked', {
      context: value,
    });
  }

  registrationCatalogueLpOpen() {
    return this.logEvent('registration_catalogue_open');
  }

  registrationExpertLpOpen(expertId: string) {
    return this.logEvent('registration_expert_open', {
      expert_id: expertId,
    });
  }

  registrationStartChatClicked(expertId: string) {
    return this.logEvent('registration_start_chat', {
      expert_id: expertId,
    });
  }

  setUserExperimentGroupName(experimentName: string, groupName: string) {
    this.setUserProperties({
      [`quiz_${experimentName}`]: groupName,
    });
  }

  expertsCatalogueLpOpened() {
    return this.logEvent('catalogue_screen_open');
  }

  expertsCatalogueGetStartedClicked() {
    return this.logEvent('catalogue_getstarted_click');
  }

  expertsCatalogueLpStartChatClicked(id: string) {
    return this.logEvent('catalogue_start_chat', {
      expert_id: id,
    });
  }

  expertsCatalogueEmailFormOpened() {
    return this.logEvent('catalogue_emailform_open', {
      event_value: 'common',
    });
  }

  expertsCatalogueGoogleFormOpened() {
    return this.logEvent('catalogue_emailform_open', {
      event_value: 'google',
    });
  }

  expertsCatalogueLpEmailConfirmOpened() {
    return this.logEvent('catalogue_email_consent_open');
  }

  expertsCatalogueLpPrelandingOpened() {
    return this.logEvent('catalogue_prelanding_open');
  }

  expertsCatalogueLpExpertOpened(id: string) {
    return this.logEvent('catalogue_expert_open', {
      expert_id: id,
    });
  }

  expertsCatalogueMatchedExpertsOpened() {
    return this.logEvent('catalogue_matched_experts');
  }

  expertsCatalogueAllExpertsOpened() {
    return this.logEvent('catalogue_all_experts');
  }

  expertsCataloguePaymentOpened() {
    return this.logEvent('catalogue_payment_open');
  }

  expertsCatalogueEmailConsentClick(value: boolean) {
    return this.logEvent('catalogue_email_consent_clicked', {
      context: value ? 'opted_in' : 'refused',
    });
  }

  registrationEmailConsentClick(value: boolean) {
    return this.logEvent('registration_email_consent_clicked', {
      context: value ? 'opted_in' : 'refused',
    });
  }

  smsInputScreenClicked(data: { eventValue: 'opted_in' | 'closed' }) {
    return this.logEvent('sms_input_screen_clicked', {
      event_value: data.eventValue,
      context: 'after_password',
      funnel_stage: 'quiz',
    });
  }

  registrationOfferEmailConfirmClicked(context: string) {
    return this.logEvent('registration_offer_email_confirm_clicked', {
      context,
    });
  }

  registrationOfferEmailConfirmSkipped(context: string) {
    return this.logEvent('registration_offer_email_confirm_skipped', {
      context,
    });
  }

  registrationOfferEmailChangeEmail(context: string) {
    return this.logEvent('registration_offer_email_change_email', {
      context,
    });
  }

  appPageAppButtonClick() {
    return this.logEvent('apppage_app_button_click');
  }

  appPageOpened() {
    return this.logEvent('apppage_open');
  }

  zodiacPairCompatibilityPageOpen(zodiac: ZodiacTypes, zodiacToCompare: ZodiacTypes) {
    return this.logEvent('seo_zodiac_pair_compatibility_page_open', {
      zodiac,
      zodiac_to_compare: zodiacToCompare,
    });
  }

  zodiacPairCompatibilityPageZodiacSignClick(data: {
    zodiac: ZodiacTypes;
    zodiacToCompare: ZodiacTypes;
    clickedZodiac: ZodiacTypes;
  }) {
    return this.logEvent('seo_zodiac_pair_compatibility_page_other_zodiac_sign_click', {
      zodiac: data.zodiac,
      zodiac_to_compare: data.zodiacToCompare,
      zodiac_clicked: data.clickedZodiac,
    });
  }
  geoMainPageOpen() {
    return this.logEvent('seo_geo_main_page_open');
  }

  geoMainPageExpertsCardClick() {
    return this.logEvent('seo_geo_main_page_expert_card_click');
  }

  geoMainPageStartChatClick() {
    return this.logEvent('seo_geo_main_page_start_chat_click');
  }

  geoMainPageBrowseAdvisorsClick() {
    return this.logEvent('seo_geo_main_page_browse_advisors_click');
  }

  geoMainPageFindAdvisorClick() {
    return this.logEvent('seo_geo_main_page_find_advisor_click');
  }

  geoSinglePageOpen(location: string) {
    return this.logEvent('seo_geo_page_open', {
      location: convertToSnakeCase(location),
    });
  }

  geoSinglePageExpertsCardClick(location: string) {
    return this.logEvent('seo_geo_page_expert_card_click', {
      location: convertToSnakeCase(location),
    });
  }

  geoSinglePageStartChatClick(location: string) {
    return this.logEvent('seo_geo_page_start_chat_click', {
      location: convertToSnakeCase(location),
    });
  }

  geoSinglePageBrowseAdvisorsClick(location: string) {
    return this.logEvent('seo_geo_page_browse_advisors_click', {
      location: convertToSnakeCase(location),
    });
  }

  signUpPageOpened() {
    return this.logEvent('seo_signup_page_opened');
  }

  signUpPageLoginClick() {
    return this.logEvent('seo_sign_up_page_login_click');
  }

  signUpPageSignUpClick() {
    return this.logEvent('seo_sign_up_page_signup_click');
  }

  registrationWelcomeBackClick() {
    return this.logEvent('registration_welcome_back_click');
  }

  registrationWelcomeBackEmailError() {
    return this.logEvent('registration_welcome_back_email_error');
  }

  registrationWelcomeBackPasswordError() {
    return this.logEvent('registration_welcome_back_password_error');
  }

  registrationWelcomeBackLoginSuccess() {
    return this.logEvent('registration_welcome_back_login_success');
  }

  registrationWelcomeBackForgotYourPasswordClick() {
    return this.logEvent('registration_welcome_back_forgot_your_password_click');
  }

  registrationPasswordRecoveryOpen() {
    return this.logEvent('registration_password_recovery_open');
  }

  registrationPasswordRecoveryClick() {
    return this.logEvent('registration_password_recovery_click');
  }

  registrationCheckEmailOpen() {
    return this.logEvent('registration_check_email_open');
  }

  registrationCheckEmailClick() {
    return this.logEvent('registration_check_email_click');
  }

  tarotGeoMainPageOpen() {
    return this.logEvent('seo_tarot_geo_main_page_open');
  }

  tarotGeoMainPageExpertsCardClick() {
    return this.logEvent('seo_tarot_geo_main_page_expert_card_click');
  }

  tarotGeoMainPageStartChatClick() {
    return this.logEvent('seo_tarot_geo_main_page_start_chat_click');
  }

  tarotGeoMainPageBrowseAdvisorsClick() {
    return this.logEvent('seo_tarot_geo_main_page_browse_advisors_click');
  }

  tarotGeoMainPageFindAdvisorClick() {
    return this.logEvent('seo_tarot_geo_main_page_find_advisor_click');
  }

  tarotGeoSinglePageOpen(location: string) {
    return this.logEvent('seo_tarot_geo_page_open', {
      location: convertToSnakeCase(location),
    });
  }

  tarotGeoSinglePageExpertsCardClick(location: string) {
    return this.logEvent('seo_tarot_geo_page_expert_card_click', {
      location: convertToSnakeCase(location),
    });
  }

  tarotGeoSinglePageStartChatClick(location: string) {
    return this.logEvent('seo_tarot_geo_page_start_chat_click', {
      location: convertToSnakeCase(location),
    });
  }

  tarotGeoSinglePageBrowseAdvisorsClick(location: string) {
    return this.logEvent('seo_tarot_geo_page_browse_advisors_click', {
      location: convertToSnakeCase(location),
    });
  }

  expertLandingPageScreenOpened(category: string) {
    return this.logEvent('expert_landing_page_screen_open', {
      context: category,
    });
  }

  expertLandingPageScreenClicked(category: string) {
    return this.logEvent('expert_landing_page_screen_click', {
      context: category,
    });
  }

  expertCatalogueLandingPageScreenOpened() {
    return this.logEvent('expert_catalogue_landing_screen_open');
  }

  startFreeChatScreenClicked() {
    return this.logEvent('start_free_chat_screen_click');
  }

  expertPagePaginationButtonClicked(page: number) {
    return this.logEvent('next_expert_page_button_click', {
      context: page,
    });
  }

  seoBannerClick() {
    return this.logEvent('seo_banner_click');
  }

  trackUserAge(age: number) {
    this.setUserProperties({
      user_age: age,
    });
  }

  notFoundPageOpen() {
    return this.logEvent('seo_not_found_page_open');
  }

  serverErrorPageOpen() {
    return this.logEvent('seo_server_error_page_open');
  }

  private async getInstance(): Promise<AmplitudeClient> {
    if (this.instance) return this.instance;
    const { default: amplitude } = await import('amplitude-js');
    this.instance = amplitude.getInstance();
    this.instance.init(this.config.amplitudeApiKey, undefined, {
      domain: this.config.domainRoot,
      apiEndpoint: this.config.amplitudeProxyUrl,
      deviceIdFromUrlParam: true,
    });
    return this.instance;
  }

  async logEvent(eventName: string, data?: unknown) {
    const span = this.observabilitySystem.startSpan('track_analytics_amplitude', {
      attributes: { eventName },
    });

    span.addEvent('Pending');

    const instance = await this.getInstance();

    instance.logEvent(
      eventName,
      data,
      (code, response, details) => {
        if (isSuccessfulStatusCode(code)) {
          span.addEvent('Succeed', {
            code,
            response,
            reason: details && details.reason,
          });
          span.end();
        }
      },
      (code, body, details) => {
        span.addEvent('Failed', {
          code,
          body,
          reason: details && details.reason,
        });
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: body,
        });
        span.end();
      },
    );
  }
}
