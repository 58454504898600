import { PersistentStorageService } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { PreferredTheme } from 'src/core/theme/entities';
import { ThemeService } from 'src/core/theme/services';

export class ThemeClientService implements ThemeService {
  private persistentStorage: PersistentStorageService;
  private logger: Logger;

  constructor(persistentStorage: PersistentStorageService, logger: Logger) {
    this.persistentStorage = persistentStorage;
    this.logger = logger;
  }

  getInitialTheme() {
    return document.body.dataset.theme as PreferredTheme;
  }

  async setTheme(theme: PreferredTheme) {
    await this.persistentStorage.set('theme', theme);
    document.body.dataset.theme = theme;
  }
}
