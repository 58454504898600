import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossaryMainPageLinkClickEventPayload = {
  name: string;
  clickedName: string;
};

export class GlossaryMainPageLinkClickEvent extends BaseEvent<GlossaryMainPageLinkClickEventPayload> {}

export class GlossaryMainPageLinkClickEventHandler extends BaseEventHandler<GlossaryMainPageLinkClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossaryMainPageLinkClickEvent;
  }

  async track(event: GlossaryMainPageLinkClickEvent) {
    const { name, clickedName } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_main_page_link_click', {
      name,
      clicked_name: clickedName,
    });
  }
}
