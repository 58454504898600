import { config } from 'src/config';
import { Expert } from 'src/core/experts/entities';
import { convertCredits } from 'src/core/common/entities';
import { ExpertDto } from '../services/dto';

export function mapChatOffersDtoToPrices(chatOffersDto: ExpertDto['chat_offers']) {
  const onlineOffer = chatOffersDto.find((offer) => offer.type === 'online');
  const chatPrice = onlineOffer ? onlineOffer.price : config.defaultChatPrice;

  const offlineOffer = chatOffersDto.find((offer) => offer.type === 'offline');
  const offlineQuestionPrice = offlineOffer
    ? offlineOffer.price
    : config.defaultOfflineQuestionPrice;

  return {
    chatPrice,
    chatPriceInCredits: convertCredits(chatPrice),
    offlineQuestionPrice,
    offlineQuestionPriceInCredits: convertCredits(offlineQuestionPrice),
  };
}

export function mapExpertDtoToEntity(expert: ExpertDto): Expert {
  const chatOffers = Array.isArray(expert.chat_offers) ? expert.chat_offers : [];

  const { chatPrice, chatPriceInCredits, offlineQuestionPrice, offlineQuestionPriceInCredits } =
    mapChatOffersDtoToPrices(chatOffers);

  return {
    ...expert,
    specialization: 'Love & Relationship expert',
    chatPrice,
    chatPriceInCredits,
    offlineQuestionPrice,
    offlineQuestionPriceInCredits,
  };
}
