import { Config } from 'src/config';
import { YEAR_IN_DAYS } from 'src/core/common/constants';
import { UtmTags } from 'src/core/user/entities';
import { isValidUuid } from 'src/core/common/utils/string';
import { CookiesService } from '../cookies';

export class CrossDomainStorage {
  private readonly config: Config;

  private readonly cookies: CookiesService;

  constructor(config: Config) {
    this.config = config;

    this.cookies = new CookiesService({
      domain: config.domainRoot,
    });
  }

  isOptOutSellUserData() {
    return !!this.cookies.get('doNotSellMyData');
  }

  isTestingTokenPresent() {
    const token = this.cookies.get(this.config.testingTokenCookieKey) || '';

    return isValidUuid(token);
  }

  optOutSellUserData() {
    this.cookies.set('doNotSellMyData', 'true', { expires: YEAR_IN_DAYS });
  }

  saveUtmTags(utmTags: UtmTags) {
    const stringifiedTags = JSON.stringify(utmTags);

    this.cookies.set('nebula_utm_tags', stringifiedTags, {
      expires: YEAR_IN_DAYS,
    });
  }

  getUtmTags(): UtmTags {
    const rawUtmTags = this.cookies.get('nebula_utm_tags');
    return rawUtmTags ? JSON.parse(rawUtmTags) : {};
  }

  async saveLastUserEmail(email: string) {
    const key = 'nebula_last_email';
    await this.cookies.set(key, email, {
      expires: YEAR_IN_DAYS,
    });
  }

  async getLastUserEmail() {
    const key = 'nebula_last_email';
    return this.cookies.get(key) || '';
  }

  setNebulaId(value: number) {
    this.cookies.set('nebula_id', value.toString(), { expires: YEAR_IN_DAYS });
  }
}
