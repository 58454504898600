import { createAction } from '@reduxjs/toolkit';
import { ChatBotAnswers, ChatBotMessage, ChatBotName } from '../entities';

export const setActiveChatBotName = createAction<{ name: ChatBotName }>(
  'chatBot/SET_ACTIVE_CHAT_BOT_NAME',
);

export const setChatBotScenarioStepId = createAction<{ stepId?: string }>(
  'chatBot/SET_SCENARIO_STEP_ID',
);

export const addExpertName = createAction<string>('chatBot/ADD_EXPERT_NAME');

export const addMessage = createAction<ChatBotMessage>('chatBot/ADD_MESSAGE');

export const addAnswer = createAction<{
  question: keyof ChatBotAnswers;
  answer: ChatBotAnswers[keyof ChatBotAnswers];
}>('chatBot/ADD_ANSWER');

export const setBotIsTyping = createAction('chatBot/SET_BOT_IS_TYPING');

export const setBotIsNotTyping = createAction('chatBot/SET_BOT_IS_NOT_TYPING');

export const chatEnded = createAction('chatBot/CHAT_ENDED');

export const resetChatEnded = createAction('chatBot/RESET_CHAT_ENDED');
