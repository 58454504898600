/* eslint-disable max-len */
import Head from 'next/head';
import 'src/core/common/assets/styles/index.scss';
import 'slick-carousel/slick/slick.scss';
import 'src/core/common/components/common/FavoriteControl/FavoriteControl.scss';
import Script from 'next/script';
import type { AppProps as AppPropsType } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';
import MetaDataDefault from 'src/core/common/components/common/MetaDataDefault';
import PageLayout from 'src/core/common/components/layout/PageLayout';
import { config } from 'src/config';
import QueryCacheProvider from 'src/core/queryCache/components/QueryCacheProvider';
import { QueryCacheOptionalFallback } from 'src/core/queryCache/entities';
import sprite from 'public/images/sprites/sprite.svg';
import createStylesCache from 'src/core/common/utils/createStylesCache';
import { prepareHtml } from 'src/core/common/utils/prepareHtml';

const clientSideStylesCache = createStylesCache();

export type AppProps = {
  emotionCache?: EmotionCache;
} & AppPropsType<QueryCacheOptionalFallback>;

const App = ({ Component, pageProps, emotionCache = clientSideStylesCache }: AppProps) => {
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <link href={sprite} rel="preload" as="image" type="image/svg+xml" />
        <meta
          name="trustpilot-one-time-domain-verification-id"
          content="ea688f7d-9420-4940-b046-f90ff48348a7"
        />
        <meta name="facebook-domain-verification" content="ld6fauq8wysgqi8nxb014927mbq37x" />
      </Head>
      <MetaDataDefault />
      <PageLayout>
        <QueryCacheProvider
          defaultOptions={config.defaultQueryCacheProviderOptions}
          fallback={pageProps.fallback}
        >
          <Component {...pageProps} />
        </QueryCacheProvider>
      </PageLayout>

      {/* _AutofillCallbackHandler for in-app browsers */}
      <Script
        strategy="beforeInteractive"
        id="autofill-callback-handler"
        type="text/javascript"
        dangerouslySetInnerHTML={prepareHtml(
          'window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () {};',
        )}
      />
      {/* End _AutofillCallbackHandler for in-app browsers */}

      {/* OneTrust Cookies Consent Notice start for asknebula.com */}

      <Script
        id="one-trust-cookie-consent"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script={config.oneTrust.key}
      />

      <Script
        id="one-trust-cookie-consent-init"
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: `function OptanonWrapper() {}` }}
      />
      {/* OneTrust Cookies Consent Notice end for asknebula.com */}

      <Script
        id="page-view-gtm"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            originalLocation: document.location.protocol + '//' +
            document.location.hostname +
            document.location.pathname +
            document.location.search
            })`,
        }}
      />

      {/* Google Tag Manager */}
      <Script
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
            var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-PR37WVW');`,
        }}
      />
      {/* End Google Tag Manager */}

      {/* Microsoft Clarity - HeatMap and SessionRecording */}
      <Script
        id="clarityHeatmap"
        security="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: config.clarityId
            ? `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            t.id="clarityScript";y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${config.clarityId}");`
            : '',
        }}
      />
      {/* End Microsoft Clarity - HeatMap and SessionRecording  */}

      {/* Solid Payment Form */}
      {/*<Script defer src="https://cdn.solidgate.com/js/solid-form.js" strategy="lazyOnload" />*/}
      {/* End Solid Payment Form */}

      <noscript>
        <iframe
          title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-PR37WVW"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <noscript>You need to enable JavaScript to run this app.</noscript>
    </CacheProvider>
  );
};

export default App;
