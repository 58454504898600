import { BaseEvent, BaseEventHandler } from 'src/core/analytics/events';

export class BecomeAPsychicClickEvent extends BaseEvent<void> {}

export class BecomeAPsychicClickEventHandler extends BaseEventHandler<void> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BecomeAPsychicClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_become_psychic_page_click_to_become');
  }
}
