export * from './Quiz';

export const answerKeys = {
  findPartner: 'Find my perfect partner',
  fixRelationship: 'Fix my relationship',
  understandBetter: 'Understand myself better',
  findEx: 'find out about my ex',
  giveAdvice: 'give advice for my love life',
  giveGuidance: 'give guidance on my future',
  exploreCareerPath: 'explore my career path',
  resolveIssue: 'resolve my issue with spell casting',
  allAbove: 'all above',

  // Experiment
  getAdvice: 'get advice for my love life',
  receiveGuidance: 'receive guidance on my future',
  getClarity: 'get clarity through spirit guidance',
  achieveAllAbove: 'achieve all of the above',
} as const;

export type AnswerKey = typeof answerKeys[keyof typeof answerKeys];
