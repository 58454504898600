import { UtmTagsService } from './UtmTagsService';

export class UtmTagsServerService implements UtmTagsService {
  getUtmSource() {
    return undefined;
  }

  getUtmTags() {
    return {};
  }

  updateUtmTags() {
    return {};
  }
}
