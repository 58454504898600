import SmartLookClient from 'smartlook-client';
import { FeatureFlags } from 'src/core/feature-flags/services';
import { UtmTagsService } from 'src/core/user/services';

export class SessionRecorder {
  private apiKey: string;

  private isInitialized = false;

  private userIdentifier?: string;

  private shouldRecord = false;

  private utmTagsService: UtmTagsService;

  private featureFlags: FeatureFlags;

  constructor(apiKey: string, utmTagsService: UtmTagsService, featureFlags: FeatureFlags) {
    this.apiKey = apiKey;
    this.utmTagsService = utmTagsService;
    this.featureFlags = featureFlags;
  }

  start() {
    if (this.isInitialized || !this.shouldRecord) return;

    SmartLookClient.init(this.apiKey);
    this.isInitialized = true;

    // Check if user identifier passed before and identify user
    if (this.userIdentifier) {
      SmartLookClient.identify(this.userIdentifier, {});
    }
  }

  private recordIp() {
    SmartLookClient.record({ ips: true });
  }

  identifyUser(id: string, data: Record<string, string | number | boolean> = {}) {
    if (!this.userIdentifier) {
      this.userIdentifier = id;
    }

    this.recordIp();

    SmartLookClient.identify(this.userIdentifier, {
      ...data,
      ...this.utmTagsService.getUtmTags(),
    });
  }

  setShouldRecord(shouldRecord: boolean) {
    this.shouldRecord = shouldRecord;
  }

  setProperties(properties: Record<string, string | number | boolean>) {
    SmartLookClient.properties(properties);
  }

  track(eventName: string, props: Record<string, string | number | boolean>) {
    SmartLookClient.track(eventName, props);
  }
}
