import { SignInByTokenPendingEventHandler } from './SignInByTokenPendingEvent';
import { SignInByTokenSucceedEventHandler } from './SignInByTokenSucceedEvent';
import { SignInByTokenFailedEventHandler } from './SignInByTokenFailedEvent';

export * from './SignInByTokenPendingEvent';
export * from './SignInByTokenSucceedEvent';
export * from './SignInByTokenFailedEvent';

export const authEventHandlers = [
  SignInByTokenPendingEventHandler,
  SignInByTokenSucceedEventHandler,
  SignInByTokenFailedEventHandler,
];
