import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type CatalogueMainCategoryClickEventPayload = {
  category: string;
};

export class CatalogueMainCategoryClickEvent extends BaseEvent<CatalogueMainCategoryClickEventPayload> {}

export class CatalogueMainCategoryClickEventHandler extends BaseEventHandler<CatalogueMainCategoryClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueMainCategoryClickEvent;
  }

  async track(event: CatalogueMainCategoryClickEvent) {
    const { category } = event.getPayload();

    return this.amplitudeService.logEvent('seo_catalogue_page_category_click', {
      click_to: category,
    });
  }
}
