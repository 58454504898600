import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type BlogSinglePageBackToMainClickEventPayload = {
  blog: string;
};

export class BlogSinglePageBackToMainClickEvent extends BaseEvent<BlogSinglePageBackToMainClickEventPayload> {}

export class BlogSinglePageBackToMainClickEventHandler extends BaseEventHandler<BlogSinglePageBackToMainClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogSinglePageBackToMainClickEvent;
  }

  async track(event: BlogSinglePageBackToMainClickEvent) {
    const { blog } = event.getPayload();

    return this.amplitudeService.logEvent('seo_blog_page_back_to_main_click', {
      blog: convertToSnakeCase(blog),
    });
  }
}
