import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type DailyHoroscopeSingleCompatibilityClickEventPayload = {
  zodiac: ZodiacTypes;
};

export class DailyHoroscopeSingleCompatibilityClickEvent extends BaseEvent<DailyHoroscopeSingleCompatibilityClickEventPayload> {}

export class DailyHoroscopeSingleCompatibilityClickEventHandler extends BaseEventHandler<DailyHoroscopeSingleCompatibilityClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof DailyHoroscopeSingleCompatibilityClickEvent;
  }

  async track(event: DailyHoroscopeSingleCompatibilityClickEvent) {
    const { zodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_daily_horoscope_page_compatibility_click', {
      zodiac,
    });
  }
}
