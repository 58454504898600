import { ReactNode } from 'react';
import { LocationRegion } from '../../entities';

export type LocationRegionCaseProps = {
  value?: LocationRegion;
  children?: ReactNode;
  default?: boolean;
};

const LocationRegionCase = ({ children }: LocationRegionCaseProps) => {
  return <>{children}</>;
};

export default LocationRegionCase;
