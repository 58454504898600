import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossaryMainPageTarotCardClickEventPayload = {
  clickedCard: string;
};

export class GlossaryMainPageTarotCardClickEvent extends BaseEvent<GlossaryMainPageTarotCardClickEventPayload> {}

export class GlossaryMainPageTarotCardClickEventHandler extends BaseEventHandler<GlossaryMainPageTarotCardClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossaryMainPageTarotCardClickEvent;
  }

  async track(event: GlossaryMainPageTarotCardClickEvent) {
    const { clickedCard } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_main_page_tarot_card_click', {
      clicked_card: clickedCard,
    });
  }
}
