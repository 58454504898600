import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossaryMainPageTarotSuitClickEventPayload = {
  clickedSuit: string;
};

export class GlossaryMainPageTarotSuitClickEvent extends BaseEvent<GlossaryMainPageTarotSuitClickEventPayload> {}

export class GlossaryMainPageTarotSuitClickEventHandler extends BaseEventHandler<GlossaryMainPageTarotSuitClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossaryMainPageTarotSuitClickEvent;
  }

  async track(event: GlossaryMainPageTarotSuitClickEvent) {
    const { clickedSuit } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_main_page_tarot_suit_click', {
      clicked_suit: clickedSuit,
    });
  }
}
