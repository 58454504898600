import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type CatalogueCategoryFindAdvisorClickEventPayload = {
  landing: string;
};

export class CatalogueCategoryFindAdvisorClickEvent extends BaseEvent<CatalogueCategoryFindAdvisorClickEventPayload> {}

export class CatalogueCategoryFindAdvisorClickEventHandler extends BaseEventHandler<CatalogueCategoryFindAdvisorClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueCategoryFindAdvisorClickEvent;
  }

  async track(event: CatalogueCategoryFindAdvisorClickEvent) {
    const { landing } = event.getPayload();

    this.googleAnalyticsService.trackGA4({
      name: 'click_from_seo',
    });

    return this.amplitudeService.logEvent('seo_landing_page_find_advisor_click', {
      landing: convertToSnakeCase(landing),
    });
  }
}
