import { ZodiacCompatibilityMainPageOpenEventHandler } from './main/ZodiacCompatibilityMainPageOpenEvent';
import { ZodiacCompatibilityMainPageCtaClickEventHandler } from './main/ZodiacCompatibilityMainPageCtaClickEvent';
import { ZodiacCompatibilityMainPageExpertCardClickEventHandler } from './main/ZodiacCompatibilityMainPageExpertCardClickEvent';
import { ZodiacCompatibilityMainPageZodiacClickEventHandler } from './main/ZodiacCompatibilityMainPageZodiacClickEvent';
import { ZodiacCompatibilityMainPageExpertStartChatClickEventHandler } from './main/ZodiacCompatibilityMainPageExpertStartChatClickEvent';
import { ZodiacCompatibilityMainPageStartQuizClickEventHandler } from './main/ZodiacCompatibilityMainPageStartQuizClickEvent';

import { ZodiacCompatibilitySinglePageCtaClickEventHandler } from './single/ZodiacCompatibilitySinglePageCtaClickEvent';
import { ZodiacCompatibilitySinglePageOpenEventHandler } from './single/ZodiacCompatibilitySinglePageOpenEvent';
import { ZodiacCompatibilitySinglePageStartQuizClickEventHandler } from './single/ZodiacCompatibilitySinglePageStartQuizClickEvent';
import { ZodiacCompatibilitySinglePageZodiacClickEventHandler } from './single/ZodiacCompatibilitySinglePageZodiacClickEvent';

export * from './main/ZodiacCompatibilityMainPageOpenEvent';
export * from './main/ZodiacCompatibilityMainPageCtaClickEvent';
export * from './main/ZodiacCompatibilityMainPageExpertCardClickEvent';
export * from './main/ZodiacCompatibilityMainPageZodiacClickEvent';
export * from './main/ZodiacCompatibilityMainPageExpertStartChatClickEvent';
export * from './main/ZodiacCompatibilityMainPageStartQuizClickEvent';

export * from './single/ZodiacCompatibilitySinglePageOpenEvent';
export * from './single/ZodiacCompatibilitySinglePageCtaClickEvent';
export * from './single/ZodiacCompatibilitySinglePageStartQuizClickEvent';
export * from './single/ZodiacCompatibilitySinglePageZodiacClickEvent';

export const zodiacCompatibilityPagesEventHandlers = [
  ZodiacCompatibilityMainPageOpenEventHandler,
  ZodiacCompatibilityMainPageCtaClickEventHandler,
  ZodiacCompatibilityMainPageExpertCardClickEventHandler,
  ZodiacCompatibilityMainPageZodiacClickEventHandler,
  ZodiacCompatibilityMainPageExpertStartChatClickEventHandler,
  ZodiacCompatibilityMainPageStartQuizClickEventHandler,

  ZodiacCompatibilitySinglePageZodiacClickEventHandler,
  ZodiacCompatibilitySinglePageStartQuizClickEventHandler,
  ZodiacCompatibilitySinglePageCtaClickEventHandler,
  ZodiacCompatibilitySinglePageOpenEventHandler,
];
