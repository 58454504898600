import { formatDate } from 'src/core/common/utils/date';
import { ContentManagerMetaData } from 'src/core/contentManager/entities';
import { DailyHoroscope } from '../entities';
import { DailyHoroscopeTypeDto } from '../services/dto';

export const mapDailyHoroscopeDtoToEntity = (horoscope: DailyHoroscopeTypeDto) => {
  const mappedHoroscope: DailyHoroscope = {
    type: horoscope.type,
    focuses: horoscope.categories,
    essentials: horoscope.focus,
    text: horoscope.text_blocks,
    date: formatDate(new Date(Date.now()), 'MMMM D, YYYY'),
  };

  return mappedHoroscope;
};

export const appendCurrentDateToMetaDataTitle = (metaData: ContentManagerMetaData) => {
  return {
    ...metaData,
    metaTitle: `${metaData.metaTitle} for today, ${formatDate(
      new Date(Date.now()),
      'MMMM D, YYYY',
    )}`,
  };
};
