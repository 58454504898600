import { BecomeAPsychicClickEventHandler } from './BecomeAPsychicClickEvent';
import { BecomeAPsychicOpenEventHandler } from './BecomeAPsychicOpenEvent';

export * from './BecomeAPsychicClickEvent';
export * from './BecomeAPsychicOpenEvent';

export const becomeAPsychicPageEventHandlers = [
  BecomeAPsychicClickEventHandler,
  BecomeAPsychicOpenEventHandler,
];
