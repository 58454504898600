import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type ReviewsSingleReviewClickEventPayload = {
  competitor: string;
  clickedCompetitor: string;
};

export class ReviewsSingleReviewClickEvent extends BaseEvent<ReviewsSingleReviewClickEventPayload> {}

export class ReviewsSingleReviewClickEventHandler extends BaseEventHandler<ReviewsSingleReviewClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ReviewsSingleReviewClickEvent;
  }

  async track(event: ReviewsSingleReviewClickEvent) {
    const { competitor, clickedCompetitor } = event.getPayload();
    return this.amplitudeService.logEvent('seo_reviews_page_review_click', {
      competitor,
      clicked_competitor: clickedCompetitor,
    });
  }
}
