import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

type EventPayload = {
  error: Error;
};

export class SignInByTokenFailedEvent extends BaseEvent<EventPayload> {}

export class SignInByTokenFailedEventHandler extends BaseEventHandler<EventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SignInByTokenFailedEvent;
  }

  async track(event: SignInByTokenFailedEvent) {
    const { error } = event.getPayload();

    return this.amplitudeService.logEvent('authenticate_by_token_failed', {
      reason: error.message,
    });
  }
}
