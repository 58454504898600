import { NativeAppPageOpenEventHandler } from './NativeAppPageOpenEvent';
import { NativeAppPageAppClickEventHandler } from './NativeAppPageAppClickEvent';

export * from './NativeAppPageOpenEvent';
export * from './NativeAppPageAppClickEvent';

export const nativeAppPageEventHandlers = [
  NativeAppPageOpenEventHandler,
  NativeAppPageAppClickEventHandler,
];
