import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type SimpleRegistrationFlowOpenedEventPayload = {
  funnel: string;
};

export class SimpleRegistrationFlowOpenedEvent extends BaseEvent<SimpleRegistrationFlowOpenedEventPayload> {}

export class SimpleRegistrationFlowOpenEventHandler extends BaseEventHandler<SimpleRegistrationFlowOpenedEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SimpleRegistrationFlowOpenedEvent;
  }

  async track(event: SimpleRegistrationFlowOpenedEvent) {
    const { funnel } = event.getPayload();

    return this.amplitudeService.logEvent('funnel_open_asknebula', {
      funnel,
    });
  }
}
