import { generateUuid } from 'src/core/common/utils/generateUuid';
import { Log } from './Log';
import { SerializedLogsBatch } from './SerializedLogsBatch';

export type LogsBatchId = string;

type LogsBatchProperties = {
  id: LogsBatchId;
  logs: Array<Log>;
  createdAt: number;
  retryAttempt: number;
  sentAt: number;
  maxSize: number;
};

export class LogsBatch {
  private constructor(private properties: LogsBatchProperties) {}

  getId() {
    return this.properties.id;
  }

  getLogs(): Array<Log> {
    return Log.sort(this.properties.logs);
  }

  getCreatedAt() {
    return this.properties.createdAt;
  }

  getRetryAttempt() {
    return this.properties.retryAttempt;
  }

  getMaxSize() {
    return this.properties.maxSize;
  }

  isPending() {
    return !this.properties.sentAt;
  }

  isOverflowed() {
    return this.getLogs().length >= this.getMaxSize();
  }

  increaseRetryAttempt() {
    this.properties.retryAttempt += 1;
  }

  addLog(log: Log) {
    this.properties.logs.push(log);
  }

  getSentAt() {
    return this.properties.sentAt;
  }

  setSentAt(sentAt: number) {
    this.properties.sentAt = sentAt;
  }

  static sort(batches: Array<LogsBatch>) {
    return batches.sort((batch1, batch2) => batch1.getCreatedAt() - batch2.getCreatedAt());
  }

  static create(properties: { logs: Array<Log>; maxSize: number }) {
    return new LogsBatch({
      id: generateUuid(),
      maxSize: properties.maxSize,
      logs: properties.logs,
      createdAt: Date.now(),
      retryAttempt: 0,
      sentAt: 0,
    });
  }

  static deserialize(data: SerializedLogsBatch): LogsBatch {
    const logs = data.logs.map(Log.deserialize);

    return new LogsBatch({
      id: data.id,
      maxSize: data.maxSize,
      logs,
      retryAttempt: data.retryAttempt,
      createdAt: data.createdAt,
      sentAt: 0,
    });
  }

  serialize(): SerializedLogsBatch {
    return {
      id: this.getId(),
      logs: this.getSerializeLogs(),
      createdAt: this.getCreatedAt(),
      retryAttempt: this.getRetryAttempt(),
      maxSize: this.getMaxSize(),
    };
  }

  private getSerializeLogs() {
    return this.getLogs().map((log) => log.serialize());
  }
}
