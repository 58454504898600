import { normalizeError } from 'src/core/common/utils/errors';
import { Logger } from 'src/core/common/logger/interfaces';

enum FacebookEvents {
  PURCHASE = 'Purchase',
}

export class FacebookService {
  private logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger;
  }

  purchase(price: number, orderId: string) {
    return this.track(
      FacebookEvents.PURCHASE,
      {
        currency: 'USD',
        value: price,
      },
      {
        eventID: orderId,
      },
    );
  }

  private track(
    name: FacebookEvents,
    params: Record<string, unknown>,
    metadata?: Record<string, unknown>,
  ) {
    try {
      window.fbq('track', name, params, metadata);
    } catch (err) {
      const error = normalizeError(err);
      this.logger.error(error);
    }
  }
}
