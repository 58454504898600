import { SVGProps } from 'react';
import cn from 'classnames';
import { IconNames, GradientNames } from 'src/core/svg-sprites/entities';
import sprite from 'public/images/sprites/sprite.svg';
import { AnyString } from 'src/core/common/entities';
import styles from './Icon.module.scss';

export type IconProps = {
  name: IconNames;
  title?: string;
  className?: string;
  fill?: `url(#${GradientNames})` | AnyString;
} & Omit<SVGProps<SVGSVGElement>, 'name' | 'fill'>;
export const Icon = ({ name, title, className = '', ...rest }: IconProps) => {
  const role = title ? 'img' : undefined;
  const ariaHidden = !title;

  return (
    <svg
      {...rest}
      aria-hidden={ariaHidden}
      width="16px"
      height="16px"
      role={role}
      className={cn(styles.root, className)}
    >
      {title ? <title>{title}</title> : null}
      <use xlinkHref={`${sprite}#${name}`} />
    </svg>
  );
};

export default Icon;
