import { IResource as OTelIResource, Resource as OTelResource } from '@opentelemetry/resources';
import { SEMRESATTRS_DEPLOYMENT_ENVIRONMENT, SEMRESATTRS_SERVICE_NAME } from './SemanticAttributes';

export class Resource implements OTelIResource {
  private oTelResource: OTelResource;

  constructor() {
    this.oTelResource = OTelResource.default();
  }

  setAppName(appName: string): this {
    this.merge(new OTelResource({ [SEMRESATTRS_SERVICE_NAME]: appName }));
    return this;
  }

  setEnv(env: string): this {
    this.merge(new OTelResource({ [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: env }));
    return this;
  }

  get asyncAttributesPending() {
    return this.oTelResource.asyncAttributesPending;
  }

  get attributes() {
    return this.oTelResource.attributes;
  }

  async waitForAsyncAttributes(): Promise<void> {
    if (!this.oTelResource.waitForAsyncAttributes) return;

    await this.oTelResource.waitForAsyncAttributes();
  }

  merge(other: OTelIResource | null): OTelIResource {
    this.oTelResource = this.oTelResource.merge(other);

    return this;
  }
}
