import { createReducer } from '@reduxjs/toolkit';
import { SystemState } from './state';

export const systemInitialState: SystemState = {
  isTestingEnv: false,
  platform: 'unknown',
  isInnAppBrowser: false,
};

export const systemReducer = createReducer(systemInitialState, () => {});
