import { CookieConsentService } from 'src/core/common/services/cookieConsent/CookieConsentService';
import { CookiesService } from 'src/core/common/services/cookies';
import { Config } from 'src/config';
import { normalizeError } from 'src/core/common/utils/errors';
import { Logger } from 'src/core/common/logger/interfaces';

export class OneTrustCookieConsentService implements CookieConsentService {
  private readonly config: Config;

  private readonly cookies: CookiesService;

  private logger: Logger;

  constructor(config: Config, logger: Logger) {
    this.config = config;

    this.cookies = new CookiesService({
      domain: config.domainRoot,
    });

    this.logger = logger;
  }

  toggleReconsent() {
    try {
      window.OneTrust?.ToggleInfoDisplay();
    } catch (err) {
      const error = normalizeError(err);
      this.logger.error(error);
    }
  }
}
