export const appendQueryParams = (url: URL, params: Record<string, string>) => {
  const prevParams = Object.fromEntries(url.searchParams);

  const combinedSearchParams = new URLSearchParams({
    ...prevParams,
    ...params,
  });

  url.search = combinedSearchParams.toString();
  return url;
};
