import { SVGProps } from 'react';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './Logo.module.scss';

export type LogoProps = Omit<SVGProps<SVGSVGElement>, 'name'> & {
  size?: 'medium' | 'small';
  variant?: 'light' | 'dark';
};

const Logo = ({ size = 'medium', variant = 'light', ...otherProps }: LogoProps) => {
  if (variant === 'dark') {
    return <Icon name="nebula" className={styles.logoDark} title="NEBULA" {...otherProps} />;
  }

  if (size === 'small') {
    return <Icon name="nebula-small" className={styles.logoSmall} title="NEBULA" {...otherProps} />;
  }

  return <Icon name="nebula" className={styles.logo} title="NEBULA" {...otherProps} />;
};

export default Logo;
