import { BaseEvent, BaseEventHandler } from 'src/core/analytics/events';

export type FeatureFlagsConfigLoadingFailedEventProperties = {
  error: Error;
};

export class FeatureFlagsConfigLoadingFailedEvent extends BaseEvent<FeatureFlagsConfigLoadingFailedEventProperties> {}

export class FeatureFlagsConfigLoadingFailedEventHandler extends BaseEventHandler<FeatureFlagsConfigLoadingFailedEventProperties> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof FeatureFlagsConfigLoadingFailedEvent;
  }

  async track(event: FeatureFlagsConfigLoadingFailedEvent) {
    const { error } = event.getPayload();

    return this.amplitudeService.logEvent('growthbook_config_download_failed', {
      reason: error.message,
    });
  }
}
