import { RootInitialState } from '../rootReducer';

export const getIsTestingEnv = (state: RootInitialState) => {
  return state.system.isTestingEnv;
};

export const getIsInnAppBrowser = (state: RootInitialState) => {
  return state.system.isInnAppBrowser;
};

export const getSystemPlatform = (state: RootInitialState) => {
  return state.system.platform;
};
