import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type SupportBotPageChatActionEventPayload = {
  value: 'open' | 'close';
};

export class SupportBotPageChatActionEvent extends BaseEvent<SupportBotPageChatActionEventPayload> {}

export class SupportBotPageChatActionEventHandler extends BaseEventHandler<SupportBotPageChatActionEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SupportBotPageChatActionEvent;
  }

  async track(event: SupportBotPageChatActionEvent) {
    const { value } = event.getPayload();
    return this.amplitudeService.logEvent('seo_support_bot_page_chat_action', {
      event_value: value,
    });
  }
}
