import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type ZodiacCompatibilitySinglePageZodiacClickEventPayload = {
  zodiac: ZodiacTypes;
  clickedZodiac: ZodiacTypes;
};

export class ZodiacCompatibilitySinglePageZodiacClickEvent extends BaseEvent<ZodiacCompatibilitySinglePageZodiacClickEventPayload> {}

export class ZodiacCompatibilitySinglePageZodiacClickEventHandler extends BaseEventHandler<ZodiacCompatibilitySinglePageZodiacClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacCompatibilitySinglePageZodiacClickEvent;
  }

  async track(event: ZodiacCompatibilitySinglePageZodiacClickEvent) {
    const { zodiac, clickedZodiac } = event.getPayload();

    return this.amplitudeService.logEvent(
      'seo_zodiac_compatibility_page_partner_zodiac_sign_click',
      {
        zodiac,
        clicked_zodiac: clickedZodiac,
      },
    );
  }
}
