import { useState } from 'react';
import { UnknownError } from 'src/core/common/errors';

import { checkIsClientSide } from '../utils/checkIsClientSide';

import { useLogger } from './useLogger';

export function useLocalStorage<T>(key: string, initialValue: T): [T, (s: T) => void] {
  const logger = useLogger();

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (!checkIsClientSide()) {
        return initialValue;
      }

      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      const error = err instanceof Error ? err : new UnknownError(err);
      logger.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      if (!checkIsClientSide()) {
        return initialValue;
      }

      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (err) {
      const error = err instanceof Error ? err : new UnknownError(err);
      logger.error(error);
    }
  };

  return [storedValue, setValue];
}
