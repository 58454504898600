import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type ReviewsSingleStartReadingClickEventPayload = {
  competitor: string;
};

export class ReviewsSingleStartReadingClickEvent extends BaseEvent<ReviewsSingleStartReadingClickEventPayload> {}

export class ReviewsSingleStartReadingClickEventHandler extends BaseEventHandler<ReviewsSingleStartReadingClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ReviewsSingleStartReadingClickEvent;
  }

  async track(event: ReviewsSingleStartReadingClickEvent) {
    const { competitor } = event.getPayload();
    return this.amplitudeService.logEvent('seo_reviews_page_start_reading_click', { competitor });
  }
}
