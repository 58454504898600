import { BaseEvent, BaseEventHandler } from 'src/core/analytics/events';

export class ChatOpenedEvent extends BaseEvent {}

export class ChatOpenedEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ChatOpenedEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('expert_catalogue_chat_screen_open');
  }
}
