import { ChatOpenedEventHandler } from './ChatOpenedEvent';
import { ChatMessageSentEventHandler } from './ChatMessageSentEvent';
import { ChatIceBreakingTipsClickedEventHandler } from './ChatIceBreakingTipsClickedEvent';
import { ChatExpertChangedEventHandler } from './ChatExpertChanged';

export * from './ChatOpenedEvent';
export * from './ChatMessageSentEvent';
export * from './ChatIceBreakingTipsClickedEvent';
export * from './ChatExpertChanged';

export const chatEventHandlers = [
  ChatOpenedEventHandler,
  ChatMessageSentEventHandler,
  ChatIceBreakingTipsClickedEventHandler,
  ChatExpertChangedEventHandler,
];
