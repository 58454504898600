import { useEffect, useLayoutEffect, useState } from 'react';
import { Services, initServices } from 'src/core/common/context';
import { createStore } from 'src/core/common/store';
import {
  FeatureFlagsConfigLoadedEvent,
  FeatureFlagsConfigLoadingFailedEvent,
} from 'src/core/feature-flags/events';

import { fetchLocationRequest } from 'src/core/location/store';
import { LoadingStatuses } from '../entities';
import { useFeatureFlagsLoader } from './useFeatureFlagsLoader';
import { useFeatureFlagsListeners } from './useFeatureFlagsListeners';

export const useApp = () => {
  const [featureFlagsLoadingStatus, setFeatureFlagsLoadingStatus] = useState(
    LoadingStatuses.PENDING,
  );
  const [appServices] = useState<Services>(() => initServices());
  const [appStore] = useState<ReturnType<typeof createStore>>(createStore(appServices));
  const { featureFlags, analyticsService, logger, clarityHeatmapRecordingService } = appServices;

  const { isLoaderVisible, hideLoader } = useFeatureFlagsLoader(logger);

  useFeatureFlagsListeners(featureFlags, {
    onLoaded: (features) => {
      hideLoader();
      setFeatureFlagsLoadingStatus(LoadingStatuses.FULFILLED);
      analyticsService.track(new FeatureFlagsConfigLoadedEvent({ config: features }));
    },
    onFailed: (error) => {
      hideLoader();
      setFeatureFlagsLoadingStatus(LoadingStatuses.FAILED);
      logger.error(error);
      analyticsService.track(new FeatureFlagsConfigLoadingFailedEvent({ error }));
    },
  });

  useLayoutEffect(() => {
    appServices.analyticsService.launch();
  }, [appServices]);

  useEffect(() => {
    appStore.dispatch(fetchLocationRequest());
  }, [appStore]);

  useEffect(() => {
    const fetchDeviceId = async () => {
      const customId = await analyticsService.getDeviceId();
      clarityHeatmapRecordingService.giveCookieConsent();
      clarityHeatmapRecordingService.identifyUser({ customId });
      clarityHeatmapRecordingService.triggerCustomEvent('askenebula_quiz');
    };

    fetchDeviceId();
  }, [clarityHeatmapRecordingService, analyticsService]);

  return {
    isLoaderVisible,
    store: appStore,
    services: appServices,
    featureFlags,
    featureFlagsLoadingStatus,
  };
};
