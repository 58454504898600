export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const TWO_MINUTES_IN_MS = 2 * 60 * SECOND;
export const FIVE_MINUTES_IN_MS = 5 * 60 * SECOND;
export const TEN_MINUTES_IN_MS = 10 * 60 * SECOND;

export const YEAR_IN_DAYS = 365;

export const DAY_IN_SECONDS = 86400;
export const FOUR_HOURS_IN_SECONDS = 14400;
export const YEAR_IN_SECONDS = YEAR_IN_DAYS * DAY_IN_SECONDS;
