import { AccountExistError, ApiError, InvalidCredentialsError } from 'src/core/common/errors';
import {
  BaseService,
  CookiesService,
  CrossDomainStorage,
  HttpClient,
} from 'src/core/common/services';
import { YEAR_IN_DAYS } from 'src/core/common/constants';
import { userAuthenticatedSessionDtoToEntity } from '../mappers';
import {
  AuthenticatedUserDTO,
  CheckIfEmailExistsDTO,
  NewUserDTO,
  UserAuthenticatedSessionDto,
  ValidateEmailDTO,
} from './dto';

export class AuthClientService extends BaseService {
  private refreshTokenExpireAtKey = 'refresh_token_expires_at';

  private cookiesService: CookiesService;

  private crossDomainStorage: CrossDomainStorage;

  constructor(
    httpClient: HttpClient,
    cookiesService: CookiesService,
    crossDomainStorage: CrossDomainStorage,
  ) {
    super(httpClient);
    this.cookiesService = cookiesService;
    this.crossDomainStorage = crossDomainStorage;
  }

  async signInByToken(token: string) {
    const { data } = await this.httpClient.get<{
      access_expires_at: number;
      refresh_expires_at: number;
      user: AuthenticatedUserDTO;
    }>(`/auth/authenticate-by-token/${token}`);
    this.storeTokenExpiration(data.refresh_expires_at);
    return data;
  }

  async signIn(email: string, password: string) {
    try {
      const { data } = await this.httpClient.post<UserAuthenticatedSessionDto>('/auth/signIn', {
        data: { email, password },
      });

      this.crossDomainStorage.setNebulaId(data.user.nebula_id);
      this.storeTokenExpiration(data.refresh_expires_at);

      return userAuthenticatedSessionDtoToEntity(data);
    } catch (err) {
      if (!(err instanceof ApiError)) {
        throw err;
      }

      if (err.message === 'Invalid credentials') {
        throw new InvalidCredentialsError(err);
      }

      throw err;
    }
  }

  async requestPasswordReset(email: string) {
    await this.httpClient.post('auth/request-reset-token', {
      data: { email },
    });
  }

  async signUpViaQuiz(userInfo: NewUserDTO) {
    try {
      const { data } = await this.httpClient.post<{
        access_expires_at: number;
        refresh_expires_at: number;
        user: AuthenticatedUserDTO;
      }>('/funnel/quiz/user', { data: userInfo });
      this.storeTokenExpiration(data.refresh_expires_at);
      return data;
    } catch (err) {
      if (err instanceof ApiError) {
        this.throwApiError(err);
        return;
      }
      throw err;
    }
  }

  async isValidEmail(email: string) {
    const { data } = await this.httpClient.get<ValidateEmailDTO>('user/validate-email', {
      params: {
        email,
      },
    });
    return data;
  }

  async doesEmailExists(email: string) {
    const { data } = await this.httpClient.get<CheckIfEmailExistsDTO>('user/account/exists', {
      params: {
        email,
      },
    });
    return data;
  }

  private throwApiError(err: ApiError) {
    const error = err.message === 'Account exists' ? new AccountExistError(err) : err;
    throw error;
  }

  async signUpViaExternal(userInfo: Omit<NewUserDTO, 'email'>) {
    const { data } = await this.httpClient.post<{
      access_expires_at: number;
      refresh_expires_at: number;
      user: AuthenticatedUserDTO;
    }>('/funnel/user/external', { data: userInfo });
    this.storeTokenExpiration(data.refresh_expires_at);
    return data;
  }

  private storeTokenExpiration(expiredAt: number) {
    this.cookiesService.set(this.refreshTokenExpireAtKey, expiredAt.toString(), {
      expires: YEAR_IN_DAYS,
    });
  }
}
