import { ChatBotName } from '../chatBot';
import { BotStep, CreateAccountStep, Step, StepType, UserStep } from '../step';
import { InputControl } from '../inputControl';
import { Scenario } from './scenario';

const DEFAULT_TYPING_DURATION = 1000;

const getStepId = (id: number) => `linear:${id}`;

export const linearSteps = <State>(name: ChatBotName, steps: Step<State>[]): Scenario<State> => {
  let id = 1;

  const initialStepId = getStepId(id);
  const stepsConfig: Record<string, Step<State>> = {};

  steps.forEach((step) => {
    const stepId = getStepId(id);
    const nextStepId = id < steps.length ? getStepId(id + 1) : undefined;
    stepsConfig[stepId] = {
      next: nextStepId,
      ...step,
    };
    id += 1;
  });

  return new Scenario({
    name,
    initial: initialStepId,
    steps: stepsConfig,
  });
};

export const bot = <State>(
  message: string | ((state: State) => string),
  options?: Partial<BotStep<State>>,
): BotStep<State> => ({
  type: StepType.BOT,
  message,
  typingDuration: DEFAULT_TYPING_DURATION,
  ...options,
});

export const user = <State>({
  variable,
  control,
}: {
  variable?: keyof State;
  control: InputControl;
}): UserStep<State> => ({
  type: StepType.USER,
  variable,
  control,
});

export const createAccount = (): CreateAccountStep => ({
  type: StepType.CREATE_ACCOUNT,
});
