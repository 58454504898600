import { convertSecToMs } from 'src/core/common/utils/timer';
import { BaseService } from 'src/core/common/services';
import { FlowName } from 'src/core/quiz/entities';
import { ExpertService } from '../interfaces';
import { mapExpertDtoToEntity } from '../mappers';
import {
  Expert,
  ExpertId,
  ExpertReview,
  ExpertStatus,
  ExpertsRequestParams,
  GetExpertReviewsParams,
} from '../entities';
import { ExpertDto, ExpertReviewDto } from './dto';

export class HttpExpertService extends BaseService implements ExpertService {
  async getExperts(params: ExpertsRequestParams = {}): Promise<Array<Expert>> {
    const { perPage, page, customerSource, filter } = params;

    const headers = customerSource && {
      customer_source: `quiz_${customerSource}`,
    };

    const { data: expertsDto } = await this.httpClient.get('/astrologers', {
      params: {
        sort: 'status_active_livechats_rand',
        per_page: perPage,
        page: page,
        filter,
      },
      headers,
    });

    return expertsDto.map((expertDto: ExpertDto) => mapExpertDtoToEntity(expertDto));
  }

  async getExpertById(expertId: ExpertId): Promise<Expert> {
    const { data: expert } = await this.httpClient.get<ExpertDto>(`/astrologers/${expertId}`);

    return mapExpertDtoToEntity(expert);
  }

  async getRandomExpert({ customerSource, ...params }: ExpertsRequestParams = {}): Promise<Expert> {
    const headers = customerSource && {
      customer_source: `quiz_${customerSource}`,
    };

    const { data } = await this.httpClient.get<Array<ExpertDto>>('/astrologers', {
      params: {
        sort: 'status_active_livechats_rand',
        per_page: 1,
        ...params,
      },
      headers,
    });

    return mapExpertDtoToEntity(data[0]);
  }

  async getExpertReviews(
    expertId: string,
    params: GetExpertReviewsParams = {},
  ): Promise<Array<ExpertReview>> {
    const { page, sort, per_page, filter = {} } = params;

    const { data } = await this.httpClient.get<Array<ExpertReviewDto>>(
      `/astrologers/${expertId}/reviews`,
      {
        params: {
          filter: {
            processing_status: filter.processingStatus,
            astrologer_uuid: filter.expertId,
            customer_source: filter.customerSource,
            customer_source_id: filter.customerSourceId,
            rating: filter.rating,
            'with-text': Number(!!filter.withText),
            'without-text': Number(!!filter.withoutText),
          },
          sort,
          page,
          per_page,
        },
      },
    );

    if (!data || !Array.isArray(data)) return [];

    return data.map((review) => ({
      ...review,
      customer: {
        ...review.customer,
        name: review.customer.name ?? '',
      },
      created_at: convertSecToMs(review.created_at),
    }));
  }

  async getMostSuitableExpert(customerSource: FlowName): Promise<Expert> {
    const expert = await this.getRandomExpert({ filter: { 'only-trial': 1 }, customerSource });

    return expert;
  }

  async isExpertOnline(expertId: ExpertId): Promise<boolean> {
    const { data: expertDto } = await this.httpClient.get<ExpertDto>(`/astrologers/${expertId}`);

    const expert = mapExpertDtoToEntity(expertDto);

    return expert.status === ExpertStatus.ONLINE;
  }
}
