import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class HomePageGetStartedClickEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class HomePageGetStartedClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof HomePageGetStartedClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_home_page_get_started_click');
  }
}
