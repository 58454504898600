import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type AdvisorSinglePageOtherLinkClickEventPayload = {
  name: string;
  link: string;
};

export class AdvisorSinglePageOtherLinkClickEvent extends BaseEvent<AdvisorSinglePageOtherLinkClickEventPayload> {}

export class AdvisorSinglePageOtherLinkClickEventHandler extends BaseEventHandler<AdvisorSinglePageOtherLinkClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof AdvisorSinglePageOtherLinkClickEvent;
  }

  async track(event: AdvisorSinglePageOtherLinkClickEvent) {
    const { name, link } = event.getPayload();

    return this.amplitudeService.logEvent('seo_advisor_page_other_link_click', {
      name,
      link,
    });
  }
}
