import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class BecomeAPsychicOpenEvent extends BaseEvent<void> {}

export class BecomeAPsychicOpenEventHandler extends BaseEventHandler<void> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BecomeAPsychicOpenEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_become_psychic_page_open');
  }
}
