import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { AnyString } from 'src/core/common/entities';

export type ZodiacTraitsMainPageLinkClickEventPayload = {
  zodiac: ZodiacTypes | AnyString;
  link: string;
};

export class ZodiacTraitsMainPageLinkClickEvent extends BaseEvent<ZodiacTraitsMainPageLinkClickEventPayload> {}

export class ZodiacTraitsMainPageLinkClickEventHandler extends BaseEventHandler<ZodiacTraitsMainPageLinkClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacTraitsMainPageLinkClickEvent;
  }

  async track(event: ZodiacTraitsMainPageLinkClickEvent) {
    const { zodiac, link } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_traits_main_page_link_click', {
      zodiac,
      link,
    });
  }
}
