import { DailyHoroscopeMainPageOpenEventHandler } from './main/DailyHoroscopeMainPageOpenEvent';
import { DailyHoroscopeMainHoroscopeClickEventHandler } from './main/DailyHoroscopeMainHoroscopeClickEvent';
import { DailyHoroscopeMainExpertCardClickEventHandler } from './main/DailyHoroscopeMainExpertCardClickEvent';
import { DailyHoroscopeMainStartChatClickEventHandler } from './main/DailyHoroscopeMainStartChatClickEvent';
import { DailyHoroscopeMainBrowseAdvisorsClickEventHandler } from './main/DailyHoroscopeMainBrowseAdvisorsClickEvent';
import { DailyHoroscopeMainCtaClickEventHandler } from './main/DailyHoroscopeMainCtaClickEvent';

import { DailyHoroscopeSingleCompatibilityWithClickEventHandler } from './single/DailyHoroscopeSingleCopmatibilityWithClickEvent';
import { DailyHoroscopeSingleCompatibilityClickEventHandler } from './single/DailyHoroscopeSingleCompatibilityClickEvent';
import { DailyHoroscopeSingleCtaClickEventHandler } from './single/DailyHoroscopeSingleCtaClickEvent';
import { DailyHoroscopeSinglePageOpenEventHandler } from './single/DailyHoroscopeSinglePageOpenEvent';

export * from './main/DailyHoroscopeMainPageOpenEvent';
export * from './main/DailyHoroscopeMainHoroscopeClickEvent';
export * from './main/DailyHoroscopeMainExpertCardClickEvent';
export * from './main/DailyHoroscopeMainStartChatClickEvent';
export * from './main/DailyHoroscopeMainBrowseAdvisorsClickEvent';
export * from './main/DailyHoroscopeMainCtaClickEvent';

export * from './single/DailyHoroscopeSingleCopmatibilityWithClickEvent';
export * from './single/DailyHoroscopeSingleCompatibilityClickEvent';
export * from './single/DailyHoroscopeSingleCtaClickEvent';
export * from './single/DailyHoroscopeSinglePageOpenEvent';

export const horoscopePageEventHandlers = [
  DailyHoroscopeMainPageOpenEventHandler,
  DailyHoroscopeMainHoroscopeClickEventHandler,
  DailyHoroscopeMainExpertCardClickEventHandler,
  DailyHoroscopeMainStartChatClickEventHandler,
  DailyHoroscopeMainBrowseAdvisorsClickEventHandler,
  DailyHoroscopeMainCtaClickEventHandler,

  DailyHoroscopeSingleCompatibilityWithClickEventHandler,
  DailyHoroscopeSingleCompatibilityClickEventHandler,
  DailyHoroscopeSingleCtaClickEventHandler,
  DailyHoroscopeSinglePageOpenEventHandler,
];
