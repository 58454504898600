import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type ReviewsSingleCtaClickEventPayload = {
  competitor: string;
};

export class ReviewsSingleCtaClickEvent extends BaseEvent<ReviewsSingleCtaClickEventPayload> {}

export class ReviewsSingleCtaClickEventHandler extends BaseEventHandler<ReviewsSingleCtaClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ReviewsSingleCtaClickEvent;
  }

  async track(event: ReviewsSingleCtaClickEvent) {
    const { competitor } = event.getPayload();
    return this.amplitudeService.logEvent('seo_reviews_page_cta_click', { competitor });
  }
}
