import { AppEnvironment, Config } from 'src/config';
import { Platform } from 'src/core/common/entities';
import { Logger } from 'src/core/common/logger/interfaces';
import { CookiesService } from 'src/core/common/services/cookies';
import { AppEnvironmentService } from './AppEnvironmentService';

export class AppEnvironmentServerService implements AppEnvironmentService {
  private config: Config;
  private logger: Logger;
  private cookiesService: CookiesService;

  constructor(config: Config, logger: Logger, cookies: CookiesService) {
    this.config = config;
    this.logger = logger;
    this.cookiesService = cookies;
  }

  isTestingEnv() {
    return false;
  }

  isApple() {
    return false;
  }

  isProductionEnv() {
    return this.config.env === AppEnvironment.PRODUCTION;
  }

  hasMatchMedia() {
    return false;
  }

  createMediaQuery() {
    return null;
  }

  isAndroid() {
    return false;
  }

  isAppleMobile() {
    return false;
  }

  isInAppBrowser() {
    return false;
  }

  getPlatform(): Platform {
    return 'unknown';
  }

  getSplitId() {
    return '';
  }
}
