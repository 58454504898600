import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossarySinglePageOpenEventPayload = {
  name: string;
  parentName: string;
};

export class GlossarySinglePageOpenEvent extends BaseEvent<GlossarySinglePageOpenEventPayload> {}

export class GlossarySinglePageOpenEventHandler extends BaseEventHandler<GlossarySinglePageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossarySinglePageOpenEvent;
  }

  async track(event: GlossarySinglePageOpenEvent) {
    const { name, parentName } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_page_open', {
      name,
      parent_name: parentName,
    });
  }
}
