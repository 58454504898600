export type UserLocation = Partial<{
  region: LocationRegion;
  country: LocationCountry;
  countryName: string;
  cityName: string;
}>;

export enum LocationRegion {
  CALIFORNIA = 'CA',
}

export enum LocationCountry {
  US = 'US',
  UA = 'UA',
  AE = 'AE',
  AL = 'AL',
  AT = 'AT',
  AU = 'AU',
  BG = 'BG',
  BE = 'BE',
  CA = 'CA',
  CH = 'CH',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  GB = 'GB',
  GR = 'GR',
  IE = 'IE',
  IT = 'IT',
  IS = 'IS',
  ME = 'ME',
  HR = 'HR',
  HU = 'HU',
  EE = 'EE',
  ES = 'ES',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MK = 'MK',
  MT = 'MT',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  RS = 'RS',
  SI = 'SI',
  SK = 'SK',
  FI = 'FI',
  FR = 'FR',
  SE = 'SE',
  ZA = 'ZA',
}

export const europeanUnionCountries = [
  LocationCountry.AT,
  LocationCountry.BG,
  LocationCountry.BE,
  LocationCountry.CZ,
  LocationCountry.CY,
  LocationCountry.DE,
  LocationCountry.DK,
  LocationCountry.GR,
  LocationCountry.IE,
  LocationCountry.IT,
  LocationCountry.HR,
  LocationCountry.HU,
  LocationCountry.EE,
  LocationCountry.ES,
  LocationCountry.LT,
  LocationCountry.LU,
  LocationCountry.LV,
  LocationCountry.ME,
  LocationCountry.MK,
  LocationCountry.MT,
  LocationCountry.NL,
  LocationCountry.PL,
  LocationCountry.PT,
  LocationCountry.RO,
  LocationCountry.SI,
  LocationCountry.SK,
  LocationCountry.FI,
  LocationCountry.FR,
  LocationCountry.SE,
];

export const europeanOtherCountries = [
  LocationCountry.AL,
  LocationCountry.CH,
  LocationCountry.GB,
  LocationCountry.IS,
  LocationCountry.LI,
  LocationCountry.ME,
  LocationCountry.MK,
  LocationCountry.NO,
];

export const countriesWithConsentCheck = [...europeanUnionCountries, LocationCountry.GB];

export const europeanCountries = [...europeanUnionCountries, ...europeanOtherCountries];
