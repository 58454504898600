import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type ZodiacCompatibilitySinglePageStartQuizClickEventPayload = {
  zodiac: ZodiacTypes;
};

export class ZodiacCompatibilitySinglePageStartQuizClickEvent extends BaseEvent<ZodiacCompatibilitySinglePageStartQuizClickEventPayload> {}

export class ZodiacCompatibilitySinglePageStartQuizClickEventHandler extends BaseEventHandler<ZodiacCompatibilitySinglePageStartQuizClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacCompatibilitySinglePageStartQuizClickEvent;
  }

  async track(event: ZodiacCompatibilitySinglePageStartQuizClickEvent) {
    const { zodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_compatibility_page_start_quiz_click', {
      zodiac,
    });
  }
}
