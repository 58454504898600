import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type BlogSinglePageTarotGetPersonalizedClickEventPayload = {
  blog: string;
};

export class BlogSinglePageTarotGetPersonalizedClickEvent extends BaseEvent<BlogSinglePageTarotGetPersonalizedClickEventPayload> {}

export class BlogSinglePageTarotGetPersonalizedClickEventHandler extends BaseEventHandler<BlogSinglePageTarotGetPersonalizedClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogSinglePageTarotGetPersonalizedClickEvent;
  }

  async track(event: BlogSinglePageTarotGetPersonalizedClickEvent) {
    const { blog } = event.getPayload();

    return this.amplitudeService.logEvent('seo_blog_page_tarot_get_personalized_click', {
      blog: convertToSnakeCase(blog),
    });
  }
}
