import { config } from 'src/config';
import { WithRequestId } from './WithRequestId';
import { createHttpClient } from './createHttpClient';

export const createDefaultHttpClient = () => {
  return new WithRequestId(
    createHttpClient({
      apiUrl: config.apiUrl,
      apiPrefix: config.apiPrefix,
    }),
  );
};
