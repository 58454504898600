import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { stringifyQueryParams } from 'src/core/common/utils/query';
import { ApiError } from 'src/core/common/errors';
import { HttpClient, HttpRequestConfig } from './HttpClient';

export class AxiosHttpClient implements HttpClient {
  private refreshAccessJob: Promise<{
    data: unknown;
    meta: unknown;
    success: boolean;
  }> | null = null;

  private unauthorizedListeners: Array<(reason: unknown) => void> = [];

  private readonly client: AxiosInstance;

  constructor(
    private readonly apiUrl: string,
    private readonly prefix: string,
    axiosConfig: AxiosRequestConfig = {},
  ) {
    this.client = axios.create({
      paramsSerializer: (params) => {
        return stringifyQueryParams(params);
      },
      ...axiosConfig,
    });
  }

  onUnauthorized(listener: (reason: unknown) => void) {
    this.unauthorizedListeners.push(listener);
  }

  async get<ResponseData = any, ResponseMeta = any>(url: string, config: HttpRequestConfig = {}) {
    return this.request<ResponseData, ResponseMeta>({
      url,
      method: 'GET',
      ...config,
    });
  }

  async post<ResponseData = any, ResponseMeta = any>(url: string, config: HttpRequestConfig = {}) {
    return this.request<ResponseData, ResponseMeta>({
      url,
      method: 'POST',
      ...config,
    });
  }

  async put<ResponseData = any, ResponseMeta = any>(url: string, config: HttpRequestConfig = {}) {
    return this.request<ResponseData, ResponseMeta>({
      url,
      method: 'PUT',
      ...config,
    });
  }

  private async request<ResponseData, ResponseMeta>(
    config: AxiosRequestConfig,
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    try {
      return await this.rawRequest<ResponseData, ResponseMeta>(config);
    } catch (err) {
      if (!(err instanceof ApiError) || err.originalStatusCode !== 403) {
        throw err;
      }

      await this.refreshAccess();
      return this.rawRequest<ResponseData, ResponseMeta>(config);
    }
  }

  private async rawRequest<ResponseData = any, ResponseMeta = any>(
    config: AxiosRequestConfig,
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    try {
      const { data: result } = await this.client.request({
        ...config,
        baseURL: `${this.apiUrl}${this.prefix}`,
        method: config.method,
        headers: {
          'content-type': 'application/json',
          ...config.headers,
        },
        withCredentials: true,
      });
      return result;
      // TODO(ErrorTyping)
    } catch (err: any) {
      const originalError =
        err.response && err.response.data ? err.response.data.error : { message: err.message };

      throw new ApiError({
        originalError,
        originalStatusCode: err.response ? err.response.status : 400,
      });
    }
  }

  private async refreshAccess() {
    if (this.refreshAccessJob) return this.refreshAccessJob;

    this.refreshAccessJob = this.rawRequest({
      url: '/auth/refresh',
      method: 'POST',
      data: {},
    })
      .catch((err) => {
        this.notifyUnauthorized(err);

        throw err;
      })
      .finally(() => {
        this.refreshAccessJob = null;
      });

    return this.refreshAccessJob;
  }

  private notifyUnauthorized(reason: unknown) {
    this.unauthorizedListeners.forEach((listener) => {
      try {
        listener(reason);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    });
  }
}
