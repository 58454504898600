import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class ReviewsMainCtaClickEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class ReviewsMainCtaClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ReviewsMainCtaClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_reviews_main_page_cta_click');
  }
}
