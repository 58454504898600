import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class ContactUsPageOpenEvent extends BaseEvent {}

export class ContactUsPageOpenEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ContactUsPageOpenEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_contact_us_page_opened');
  }
}
