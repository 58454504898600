export const generateDates = (
  startDate: number,
  endDate: number,
  type: 'increment' | 'decrement',
  count = 1,
) => {
  const arrayNumbers = Array.from(
    { length: Math.floor((endDate + 1 - startDate) / count) },
    (_, i) => `${startDate + i * count}`,
  );
  if (type === 'decrement') {
    return arrayNumbers.reverse();
  }
  return arrayNumbers;
};

export const generateMonthDays = (month: string, year: string): string[] => {
  let currentMonthDays: number;
  switch (month) {
    case 'Jan':
    case 'Mar':
    case 'May':
    case 'Jul':
    case 'Aug':
    case 'Oct':
    case 'Dec':
      currentMonthDays = 31;
      break;
    case 'Apr':
    case 'Jun':
    case 'Sep':
    case 'Nov':
      currentMonthDays = 30;
      break;
    case 'Feb':
      currentMonthDays = 28;
      break;
    default:
      currentMonthDays = 31;
  }

  const leapYear = generateDates(1960, 2008, 'increment', 4).includes(year);

  if (leapYear && month === 'Feb') {
    return generateDates(1, 29, 'increment');
  }
  return generateDates(1, currentMonthDays, 'increment');
};
