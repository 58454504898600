import { browserDetector } from '@opentelemetry/opentelemetry-browser-detector';
import { detectResourcesSync } from '@opentelemetry/resources';
import { Resource } from './Resource';

export class BrowserAppResource extends Resource {
  private constructor() {
    super();
  }

  static create(): BrowserAppResource {
    try {
      const resource = new BrowserAppResource();

      const detectedResources = detectResourcesSync({
        detectors: [browserDetector],
      });

      resource.merge(detectedResources);

      return resource;
    } catch (err) {
      return new BrowserAppResource();
    }
  }
}
