import { LoggerTransport } from '../interfaces';
import { Log } from '../entities';

export class ConsoleLoggerTransport implements LoggerTransport {
  send(log: Log): void {
    // eslint-disable-next-line no-console
    const logMethod = console[log.getLevel()] || console.log;

    logMethod(log.getMessage(), log.getExtra(), log.getCreatedAt());
  }
}
