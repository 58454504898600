import { SuggestedLocation } from 'src/core/common/entities';

interface SuggestedLocationDTO {
  type: string;
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  properties: {
    id: string;
    gid: string;
    layer: string;
    source: string;
    souce_id: string;
    name: string;
    confidence: number;
    country: string;
    country_gid: string;
    country_a: string;
    macroregion: string;
    macroregion_gid: string;
    region?: string;
    region_gid?: string;
    locality: string;
    locality_gid: string;
    label: string;
  };
  bbox: Array<number>;
}

export class GeoService {
  private geocodeUrl: string;

  private geocodeApiKey: string;

  constructor(geocodeUrl: string, geocodeApiKey: string) {
    this.geocodeUrl = geocodeUrl;
    this.geocodeApiKey = geocodeApiKey;
  }

  async getSuggestedLocations(location: string): Promise<SuggestedLocation[]> {
    const rawResponse = await fetch(
      `${this.geocodeUrl}?${new URLSearchParams({
        api_key: this.geocodeApiKey,
        text: location,
      })}`,
    );

    const response: { features: Array<SuggestedLocationDTO> } = await rawResponse.json();

    if (!response.features) return [];

    return response.features;
  }
}
