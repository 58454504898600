import { ProductNameType, ReportProduct } from 'src/core/payments/entities';
import { BaseService } from 'src/core/common/services';

export type PaymentDescriptionDTO = {
  formMerchantData: unknown;
  paypalPaymentUrl: string;
};

export interface ProductDTO {
  id: string;
  name: string;
  description: string | null;
  product_type: string;
  price: number;
  created_at: Date;
  updated_at: Date;
}

export class PaymentService extends BaseService {
  async createPayment(
    products: ProductNameType[],
    extraData?: Record<string, unknown>,
  ): Promise<PaymentDescriptionDTO> {
    const { data } = await this.httpClient.post('/payments', {
      data: {
        products,
        extra_data: extraData,
      },
    });

    return {
      formMerchantData: data.formMerchantData,
      paypalPaymentUrl: data.paypalPaymentData.scriptUrl,
    };
  }

  async getProduct(productName: ProductNameType): Promise<{ data: ProductDTO }> {
    return this.httpClient.get(`/products/${productName}`);
  }

  async purchaseReport(reportType: ReportProduct) {
    return this.httpClient.post('/reports/purchase', {
      data: {
        product_name: reportType,
      },
    });
  }
}
