import { ReactElement } from 'react';
import { useLocation } from 'src/core/location/hooks';
import { LocationCountry } from '../../entities';

export type LocationCountrySwitchProps = {
  children: ReactElement[] | ReactElement;
};

const LocationCountrySwitch = (props: LocationCountrySwitchProps) => {
  const { country } = useLocation();

  const renderChildren = (child: ReactElement) => {
    if (Array.isArray(child.props.value)) {
      return child.props.value.some((value: LocationCountry) => value === country) ? child : null;
    }

    if (child.props.value === country) {
      return child;
    }

    if (child.props.default) {
      return child;
    }

    return null;
  };

  const renderArrayChildren = (children: ReactElement[]) => {
    const foundMatchedChildren = children.filter((child) => {
      if (Array.isArray(child.props.value)) {
        return child.props.value.some((value: LocationCountry) => value === country);
      }

      return child.props.value === country;
    });

    if (foundMatchedChildren.length) {
      return foundMatchedChildren;
    }

    const defaultChildren = children.filter((child) => child.props.default);

    if (defaultChildren.length) {
      return defaultChildren;
    }

    return null;
  };

  const content = Array.isArray(props.children)
    ? renderArrayChildren(props.children)
    : renderChildren(props.children);

  return <>{content}</>;
};

export default LocationCountrySwitch;
