import { FeatureFlagsConfig } from './entities';

export const sessionRecorderExperimentName = 'session_recorder_redistributor';

export const categorizedExpertCatalogueExperimentName = 'regcat_4_categorized_catalogue';

export const phoneNumberOptInFeature = 'phone_number_opt_in';

export const simpleSimpleChatOnCatalogueExperiment = 'simple_simple_chat_on_catalogue';

export const fbAutologinSimpleRegExperimentName = 'fb_autologin_simple_reg';

export const simpleRegSummaryScreenExperimentName = 'summary_section_simple_reg';

export const SimpleRegChatOnFunnel2ExperimentName = 'simple_reg_chat_on_funnel_2';

export const simpleRegFunnelStart3ExperimentName = 'simple_reg_funnel_start_3';

export enum SimpleRegUserName2ExperimentGroups {
  CONTROL = 'user_name_as_is',
  WITHOUT_TEXT = 'user_name_push_without_text',
  WITH_TEXT = 'user_name_push_with_text',
}

export enum SessionRecorderExperimentGroups {
  RECORD_ON = 'record_on',
  RECORD_OFF = 'record_off',
}

export enum CategorizedExpertCatalogueExperimentGroups {
  CONTROL = 'reg_cat_as-is',
  WITH_CATEGORIZED_CATALOGUE = 'reg_cat_categorized_catalogue',
}

export enum FbAutologinSimpleRegExperimentGroups {
  CONTROL = 'as-is',
  FB_AUTOLOGIN = 'fb_autologin',
}

export enum SimpleSimpleChatOnCatalogueExperimentGroups {
  CONTROL = 'simple_simple_as-is',
  TEST_2 = 'simple_simple_chat_with_redirect',
}

export enum SimpleRegExplanationScreens3ExperimentGroups {
  CONTROL = 'simple_reg_as-is',
  EXTRA_SCREENS = 'simple_reg_extra_screens',
}

export enum SimpleRegSummaryScreenExperimentGroups {
  CONTROL = 'control',
  EXPERT_FOCUS = 'test_expert_focus',
  USER_FOCUS = 'test_user_focus',
}

export enum SimpleRegChatOnFunnel2ExperimentGroups {
  CONTROL = 'simple_reg_as-is',
  EXPERTS = 'simple_reg_experts',
  ONLY_MESSAGE = 'simple_reg_only_message',
}

export enum SimpleRegFunnelStart3ExperimentGroups {
  CONTROL = 'control',
  TEST_DYNAMIC_TITLE = 'test_dynamic_title',
  TEST_CHANGE_HERO_1 = 'test_change_hero_1',
  TEST_CHANGE_HERO_2 = 'test_change_hero_2',
  TEST_CHANGE_HERO_3 = 'test_change_hero_3',
  TEST_CHANGE_HERO_VIDEO = 'test_change_hero_video',
}

export const featureFlagsConfig: FeatureFlagsConfig = {
  features: {
    [sessionRecorderExperimentName]: {
      defaultValue: SessionRecorderExperimentGroups.RECORD_OFF,
      rules: [
        {
          variations: [
            SessionRecorderExperimentGroups.RECORD_OFF,
            SessionRecorderExperimentGroups.RECORD_ON,
          ],
          weights: [0.8, 0.2],
        },
      ],
      shouldNotTrack: true,
    },
    [categorizedExpertCatalogueExperimentName]: {
      defaultValue: CategorizedExpertCatalogueExperimentGroups.CONTROL,
      rules: [
        {
          seed: 'as-is_vs_categorized_catalogue',
          hashVersion: 2,
          variations: [
            CategorizedExpertCatalogueExperimentGroups.CONTROL,
            CategorizedExpertCatalogueExperimentGroups.WITH_CATEGORIZED_CATALOGUE,
          ],
          weights: [0.5, 0.5],
        },
      ],
    },
    [simpleSimpleChatOnCatalogueExperiment]: {
      defaultValue: SimpleSimpleChatOnCatalogueExperimentGroups.CONTROL,
    },
    [fbAutologinSimpleRegExperimentName]: {
      defaultValue: FbAutologinSimpleRegExperimentGroups.CONTROL,
    },
    [phoneNumberOptInFeature]: {
      defaultValue: null,
    },
    [simpleRegSummaryScreenExperimentName]: {
      defaultValue: SimpleRegSummaryScreenExperimentGroups.CONTROL,
    },
    [SimpleRegChatOnFunnel2ExperimentName]: {
      defaultValue: SimpleRegChatOnFunnel2ExperimentGroups.CONTROL,
    },
    [simpleRegFunnelStart3ExperimentName]: {
      defaultValue: SimpleRegFunnelStart3ExperimentGroups.CONTROL,
    },
  },
};
