import libUrlJoin from 'url-join';
import { config } from 'src/config';
import { AnchorHref } from '../entities';

export function joinUrls(...args: string[]) {
  return libUrlJoin(args);
}

export function withAppOrigin(...args: string[]) {
  return joinUrls(config.appOrigin, ...args);
}

export const getUrlPortionAfterLastSlash = (url: string) => url.split('/').pop();
export const getUrlWithoutFirstSlash = (url: string) => url.replace(/^\/(.*)$/, '$1');

export const createIdAnchor = <T extends string>(id: T): AnchorHref<T> => `#${id}`;
export const createMailToHref = (email: string) => `mailto:${email}` as const;
