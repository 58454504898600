import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class ExpertCatalogueIdleModalOpenEvent extends BaseEvent {}

export class ExpertCatalogueIdleModalOpenEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ExpertCatalogueIdleModalOpenEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('intro_offer_pop-up_open');
  }
}
