import { ZodiacTraitsPageCtaClickEventHandler } from 'src/zodiac-traits/analytics/events/ZodiacTraitsSingle/ZodiacTraitsPageCtaClickEvent';
import { ZodiacTraitsPageCompatibilityClickEventHandler } from 'src/zodiac-traits/analytics/events/ZodiacTraitsSingle/ZodiacTraitsPageCompatibilityClickEvent';
import { ZodiacTraitsPageOpenEventHandler } from './ZodiacTraitsSingle/ZodiacTraitsPageOpenEvent';
import { ZodiacTraitsPageLinkClickEventHandler } from './ZodiacTraitsSingle/ZodiacTraitsPageLinkClickEvent';
import { ZodiacTraitsMainPageOpenEventHandler } from './ZodiacTraitsMain/ZodiacTraitsMainPageOpenEvent';
import { ZodiacTraitsMainPageCtaBannerClickEventHandler } from './ZodiacTraitsMain/ZodiacTraitsMainPageCtaBannerClickEvent';
import { ZodiacTraitsMainPageCompatibilityClickEventHandler } from './ZodiacTraitsMain/ZodiacTraitsMainPageCompatibilityClickEvent';
import { ZodiacTraitsMainPageLinkClickEventHandler } from './ZodiacTraitsMain/ZodiacTraitsMainPageLinkClickEvent';

export * from './ZodiacTraitsMain/ZodiacTraitsMainPageOpenEvent';
export * from './ZodiacTraitsMain/ZodiacTraitsMainPageCtaBannerClickEvent';
export * from './ZodiacTraitsMain/ZodiacTraitsMainPageCompatibilityClickEvent';
export * from './ZodiacTraitsMain/ZodiacTraitsMainPageLinkClickEvent';
export * from './ZodiacTraitsSingle/ZodiacTraitsPageLinkClickEvent';
export * from './ZodiacTraitsSingle/ZodiacTraitsPageCompatibilityClickEvent';
export * from './ZodiacTraitsSingle/ZodiacTraitsPageCtaClickEvent';
export * from './ZodiacTraitsSingle/ZodiacTraitsPageOpenEvent';

export const zodiacTraitsPagesEventHandlers = [
  ZodiacTraitsPageOpenEventHandler,
  ZodiacTraitsPageCompatibilityClickEventHandler,
  ZodiacTraitsPageCtaClickEventHandler,
  ZodiacTraitsPageLinkClickEventHandler,
  ZodiacTraitsMainPageOpenEventHandler,
  ZodiacTraitsMainPageCtaBannerClickEventHandler,
  ZodiacTraitsMainPageCompatibilityClickEventHandler,
  ZodiacTraitsMainPageLinkClickEventHandler,
];
