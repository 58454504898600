import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type ZodiacCompatibilityMainPageZodiacClickEventPayload = {
  zodiac: ZodiacTypes;
};

export class ZodiacCompatibilityMainPageZodiacClickEvent extends BaseEvent<ZodiacCompatibilityMainPageZodiacClickEventPayload> {}

export class ZodiacCompatibilityMainPageZodiacClickEventHandler extends BaseEventHandler<ZodiacCompatibilityMainPageZodiacClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacCompatibilityMainPageZodiacClickEvent;
  }

  async track(event: ZodiacCompatibilityMainPageZodiacClickEvent) {
    const { zodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_compatibility_main_page_zodiac_sign_click', {
      zodiac,
    });
  }
}
