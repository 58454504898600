import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type BlogMainCardClickEventPayload = {
  clickedBlog: string;
};

export class BlogMainCardClickEvent extends BaseEvent<BlogMainCardClickEventPayload> {}

export class BlogMainCardClickEventHandler extends BaseEventHandler<BlogMainCardClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof BlogMainCardClickEvent;
  }

  async track(event: BlogMainCardClickEvent) {
    const { clickedBlog } = event.getPayload();
    return this.amplitudeService.logEvent('seo_blog_main_page_card_click', {
      clicked_blog: convertToSnakeCase(clickedBlog),
    });
  }
}
