import { ReviewsMainPageOpenEventHandler } from './main/ReviewsMainPageOpenEvent';
import { ReviewsMainCtaClickEventHandler } from './main/ReviewsMainCtaClickEvent';
import { ReviewsMainTrustedPlatformClickEventHandler } from './main/ReviewsMainTrustedPlatformClickEvent';

import { ReviewsSinglePageOpenEventHandler } from './single/ReviewsSinglePageOpenEvent';
import { ReviewsSingleCtaClickEventHandler } from './single/ReviewsSingleCtaClickEvent';
import { ReviewsSingleTrustedPlatformClickEventHandler } from './single/ReviewsSingleTrustedPlatformClickEvent';
import { ReviewsSingleStartReadingClickEventHandler } from './single/ReviewsSingleStartReadingClickEvent';
import { ReviewsSingleReviewClickEventHandler } from './single/ReviewsSingleReviewClickEvent';

export * from './main/ReviewsMainPageOpenEvent';
export * from './main/ReviewsMainCtaClickEvent';
export * from './main/ReviewsMainTrustedPlatformClickEvent';

export * from './single/ReviewsSinglePageOpenEvent';
export * from './single/ReviewsSingleCtaClickEvent';
export * from './single/ReviewsSingleTrustedPlatformClickEvent';
export * from './single/ReviewsSingleStartReadingClickEvent';
export * from './single/ReviewsSingleReviewClickEvent';

export const reviewsPageEventHandlers = [
  ReviewsMainPageOpenEventHandler,
  ReviewsMainCtaClickEventHandler,
  ReviewsMainTrustedPlatformClickEventHandler,

  ReviewsSinglePageOpenEventHandler,
  ReviewsSingleCtaClickEventHandler,
  ReviewsSingleTrustedPlatformClickEventHandler,
  ReviewsSingleStartReadingClickEventHandler,
  ReviewsSingleReviewClickEventHandler,
];
