import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class SimpleRegistrationMainPageExThemeClickedEvent extends BaseEvent {}

export class SimpleRegistrationMainPageExThemeClickedEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SimpleRegistrationMainPageExThemeClickedEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('registration_ex_theme_screen_click');
  }
}
