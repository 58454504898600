import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { AnyString } from 'src/core/common/entities';

export type ZodiacTraitsMainPageCtaBannerClickEventPayload = {
  zodiac: ZodiacTypes | AnyString;
};

export class ZodiacTraitsMainPageCtaBannerClickEvent extends BaseEvent<ZodiacTraitsMainPageCtaBannerClickEventPayload> {}

export class ZodiacTraitsMainPageCtaBannerClickEventHandler extends BaseEventHandler<ZodiacTraitsMainPageCtaBannerClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacTraitsMainPageCtaBannerClickEvent;
  }

  async track(event: ZodiacTraitsMainPageCtaBannerClickEvent) {
    const { zodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_traits_main_page_cta_click', {
      zodiac,
    });
  }
}
