import useMediaQuery from '@mui/material/useMediaQuery';
import dimensions from 'src/core/common/assets/styles/vars.module.scss';

export function useResolution() {
  const mobile = useMediaQuery(`(max-width:${dimensions.maximalMobileWidth})`);

  return {
    isMobile: mobile,
    isDesktop: !mobile,
  };
}
