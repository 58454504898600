import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type CatalogueCategoryOpenEventPayload = {
  landing: string;
};

export class CatalogueCategoryOpenEvent extends BaseEvent<CatalogueCategoryOpenEventPayload> {}

export class CatalogueCategoryOpenEventHandler extends BaseEventHandler<CatalogueCategoryOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueCategoryOpenEvent;
  }

  async track(event: CatalogueCategoryOpenEvent) {
    const { landing } = event.getPayload();

    return this.amplitudeService.logEvent('seo_landing_page_open', {
      landing: convertToSnakeCase(landing),
    });
  }
}
