import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class ExpertCatalogueIdleModalClaimEvent extends BaseEvent {}

export class ExpertCatalogueIdleModalClaimEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ExpertCatalogueIdleModalClaimEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('intro_offer_pop-up_claim');
  }
}
