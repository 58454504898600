import Cookies, { CookieAttributes } from 'js-cookie';

export class CookiesService {
  private options: CookieAttributes = {};

  constructor(options: CookieAttributes = {}) {
    this.options = options;
  }

  set(key: string, value: string, options: CookieAttributes = {}) {
    const mergedOptions = { ...this.options, ...options };

    Cookies.set(key, value, mergedOptions);
  }

  get(key: string): string | undefined {
    return Cookies.get(key);
  }

  getAll(): Record<string, string> {
    return Cookies.get();
  }

  remove(key: string, options: CookieAttributes = {}) {
    const mergedOptions = { ...this.options, ...options };
    Cookies.remove(key, mergedOptions);
  }
}
