import { BaseEvent, BaseEventHandler } from 'src/core/analytics/events';

export class ChatExpertChangedEvent extends BaseEvent {}

export class ChatExpertChangedEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ChatExpertChangedEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('expert_catalogue_expert_autochange');
  }
}
