import { BaseService, HttpClient } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { mapDailyHoroscopeDtoToEntity } from '../mappers';
import { HoroscopesTypeDto, isDailyHoroscope } from './dto';

export class HoroscopeService extends BaseService {
  logger: Logger;

  constructor(httpClient: HttpClient, logger: Logger) {
    super(httpClient);
    this.logger = logger;
  }
  async getDailyHoroscope(zodiac: ZodiacTypes) {
    const { data }: { data: HoroscopesTypeDto } = await this.httpClient.get(
      `/zodiac/horoscope/${zodiac}`,
    );

    const horoscope = data.find(isDailyHoroscope);

    if (!horoscope) {
      this.logger.error(`Daily horoscope for ${zodiac} not found`);
      throw new Error(`Daily horoscope for ${zodiac} not found`);
    }

    return { ...mapDailyHoroscopeDtoToEntity(horoscope) };
  }
}
