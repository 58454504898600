import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { AnyString } from 'src/core/common/entities';

export type ZodiacTraitsMainPageOpenEventPayload = {
  zodiac: ZodiacTypes | AnyString;
};

export class ZodiacTraitsMainPageOpenEvent extends BaseEvent<ZodiacTraitsMainPageOpenEventPayload> {}

export class ZodiacTraitsMainPageOpenEventHandler extends BaseEventHandler<ZodiacTraitsMainPageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacTraitsMainPageOpenEvent;
  }

  async track(event: ZodiacTraitsMainPageOpenEvent) {
    const { zodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_traits_main_page_open', {
      zodiac,
    });
  }
}
