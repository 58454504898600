import { useEffect } from 'react';

export const useBackRedirect = (callback: () => void) => {
  useEffect(() => {
    const handlePopstate = () => {
      callback();
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [callback]);
};
