import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type CatalogueMainBrowseCategoryClickEventPayload = {
  category: string;
};

export class CatalogueMainBrowseCategoryClickEvent extends BaseEvent<CatalogueMainBrowseCategoryClickEventPayload> {}

export class CatalogueMainBrowseCategoryClickEventHandler extends BaseEventHandler<CatalogueMainBrowseCategoryClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueMainBrowseCategoryClickEvent;
  }

  async track(event: CatalogueMainBrowseCategoryClickEvent) {
    const { category } = event.getPayload();

    return this.amplitudeService.logEvent('seo_catalogue_page_browse_click', {
      click_to: category,
    });
  }
}
