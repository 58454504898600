import { HeaderLinkClickEventHandler } from './HeaderLinkClickEvent';
import { FooterClickEventHandler } from './FooterLinkClickEvent';
import { FooterAppClickEventHandler } from './FooterAppClickEvent';
import { SeoFreeReadingModalOpenEventHandler } from './SeoFreeReadingModalOpenEvent';
import { SeoFreeReadingModalClickEventHandler } from './SeoFreeReadingModalClickEvent';
import { SignInClickEventHandler } from './SignInClickEvent';

export * from './HeaderLinkClickEvent';
export * from './FooterLinkClickEvent';
export * from './FooterAppClickEvent';
export * from './SeoFreeReadingModalOpenEvent';
export * from './SeoFreeReadingModalClickEvent';
export * from './SignInClickEvent';

export const contentManagerEventHandlers = [
  HeaderLinkClickEventHandler,
  FooterClickEventHandler,
  FooterAppClickEventHandler,
  SeoFreeReadingModalClickEventHandler,
  SeoFreeReadingModalOpenEventHandler,
  SignInClickEventHandler,
];
