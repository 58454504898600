import cn from 'classnames';
import styles from './ErrorHolder.module.scss';

export type ErrorHolderProps = {
  className?: string;
  onTryAgain: () => void;
};
// TODO(Share)
const ErrorHolder = ({ className, onTryAgain }: ErrorHolderProps) => (
  <div className={cn(styles.content, className)}>
    <div className={styles.title}>Sorry</div>
    <div className={styles.text}>Something went wrong. Please try again.</div>
    <button onClick={onTryAgain} className={styles.button}>
      Try again
    </button>
  </div>
);

export default ErrorHolder;
