import Head from 'next/head';
import ErrorHolder from 'src/core/common/components/common/ErrorHolder';
import DefaultLayout from 'src/core/common/components/layout/DefaultLayout';
import styles from './Error.module.scss';

const ErrorPage = () => {
  const onTryAgain = () => {
    // TODO create smth more efficient
    window.location.reload();
  };

  return (
    <div className={styles.root}>
      <DefaultLayout>
        <Head>
          <link key="canonical" />
        </Head>
        <ErrorHolder onTryAgain={onTryAgain} />
      </DefaultLayout>
    </div>
  );
};

export default ErrorPage;
