import { PersistentStorageService } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';

export class PersistentStorageClientService implements PersistentStorageService {
  private logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger;
  }

  get<T>(key: string, { raw = false } = {}): T | null | string {
    if (!key) throw new Error('storage key may not be falsy');
    try {
      const localStorageValue = localStorage.getItem(key);
      if (!localStorageValue || raw) {
        return localStorageValue;
      }
      try {
        return JSON.parse(localStorageValue);
      } catch {
        return localStorageValue;
      }
    } catch (err) {
      this.logger.warn(`Can't load value from persistent store for key: ${key}`);
      throw err;
    }
  }

  async set(key: string, newVal: unknown): Promise<void> {
    try {
      if (typeof newVal === 'undefined') return;
      let value: string;
      if (typeof newVal === 'string') value = newVal;
      else value = JSON.stringify(newVal);
      localStorage.setItem(key, value);
    } catch (err) {
      this.logger.warn(`Can't load value from persistent store for key: ${key}`);
      throw err;
    }
  }
}
