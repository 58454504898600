import qs from 'qs';
import { CrossDomainStorage } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { normalizeError } from 'src/core/common/utils/errors';
import { UtmTags } from 'src/core/user/entities';
import { UtmTagsService } from './UtmTagsService';

const POSSIBLE_UTM_TAGS: Array<keyof UtmTags> = [
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_term',
  'utm_campaign_id',
  'utm_campaignid',
  'utm_campaign',
  'utm_adset_id',
  'utm_adsetid',
  'utm_adset',
  'utm_ad_id',
  'utm_adid',
  'utm_ad',
  'gclid',
  'iterable_message_id',
  'fbp',
  'fbc',
  'fbclid',
  'ga',
  'everflow_transaction_id',
  'utm_source_retention',
  'utm_adset_name',
  'utm_placement',
  'utm_ad_name',
  'ttclid',
  'quizz',
  'oid',
  '_ef_transaction_id',
  'fb_test_event_code',
];

export class UtmTagsClientService implements UtmTagsService {
  private readonly crossDomainStorage: CrossDomainStorage;

  private readonly logger: Logger;

  private constructor(crossDomainStorage: CrossDomainStorage, logger: Logger) {
    this.crossDomainStorage = crossDomainStorage;
    this.logger = logger;
  }

  getUtmSource() {
    return this.getUtmTags().utm_source;
  }

  getUtmTags() {
    return this.getTagsFromCache();
  }

  static crateFromQueryParams(crossDomainStorage: CrossDomainStorage, logger: Logger) {
    const utmService = new UtmTagsClientService(crossDomainStorage, logger);
    utmService.loadFormQueryParams();

    return utmService;
  }

  updateUtmTags(utmTags: UtmTags) {
    const oldUtmTags = this.getUtmTags();
    const newUtmTags = { ...oldUtmTags, ...utmTags };

    this.saveToCache(newUtmTags);

    return this.getUtmTags();
  }

  private getUtmTagsFromQueryParams() {
    const newUtmTags: Record<string, string> = {};

    const parsedQs: UtmTags = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    POSSIBLE_UTM_TAGS.forEach((utmTag) => {
      if (utmTag in parsedQs) {
        newUtmTags[utmTag] = this.extractUtmTagFromParsedQs(parsedQs[utmTag]);
      }
    });

    return newUtmTags;
  }

  private loadFormQueryParams() {
    const newUtmTags = this.getUtmTagsFromQueryParams();

    this.updateUtmTags(newUtmTags);
  }

  private saveToCache(utmTags: UtmTags) {
    try {
      this.crossDomainStorage.saveUtmTags(utmTags);
    } catch (err) {
      const error = normalizeError(err);
      this.logger.error(error);
    }
  }

  private getTagsFromCache(): UtmTags {
    try {
      return this.crossDomainStorage.getUtmTags();
    } catch (err) {
      const error = normalizeError(err);
      this.logger.error(error);
      return {};
    }
  }

  private extractUtmTagFromParsedQs(parsedQs: undefined | string | string[]): string {
    if (!parsedQs) return '';

    return Array.isArray(parsedQs) ? parsedQs[0] : parsedQs;
  }
}
