export type HoroscopeCategoriesTypeDto = Array<{
  name: string;
  percent: number;
}>;

export type HoroscopeTextTypeDto = {
  percent: null;
  text: string;
  title: string;
};

export type DailyHoroscopeTypeDto = {
  type: 'today';
  categories: HoroscopeCategoriesTypeDto;
  date: Date;
  focus: string;
  text_blocks: Array<HoroscopeTextTypeDto>;
};

export type TomorrowHoroscopeTypeDto = {
  type: 'tomorrow';
};

export type MonthlyHoroscopeTypeDto = {
  type: 'month';
};

export type YearlyHoroscopeTypeDto = {
  type: 'year';
};

export type HoroscopesUnionTypeDto =
  | DailyHoroscopeTypeDto
  | TomorrowHoroscopeTypeDto
  | MonthlyHoroscopeTypeDto
  | YearlyHoroscopeTypeDto;

export type HoroscopesTypeDto = Array<HoroscopesUnionTypeDto>;

export const isDailyHoroscope = (
  horoscope: HoroscopesUnionTypeDto,
): horoscope is DailyHoroscopeTypeDto => horoscope.type === 'today';
