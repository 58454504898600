import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type ZodiacTraitsPageLinkClickEventPayload = {
  zodiac: ZodiacTypes;
  name: string;
  link: string;
};

export class ZodiacTraitsPageLinkClickEvent extends BaseEvent<ZodiacTraitsPageLinkClickEventPayload> {}

export class ZodiacTraitsPageLinkClickEventHandler extends BaseEventHandler<ZodiacTraitsPageLinkClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacTraitsPageLinkClickEvent;
  }

  async track(event: ZodiacTraitsPageLinkClickEvent) {
    const { zodiac, name, link } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_traits_page_link_click', {
      zodiac,
      link,
      name: convertToSnakeCase(name),
    });
  }
}
