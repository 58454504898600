/* eslint-disable max-len */
export interface PaymentFormError {
  code?: PaymentFormErrorCode;
  message: string;
  [errorProperty: string]: unknown;
}

export interface PaypalProcessingError {
  code: PaymentFormErrorCode;
  messages: string[];
  recommended_message_for_user: string;
}

export enum PaymentFormErrorCode {
  GENERAL_DECLINE = '0.01',
  ORDER_EXPIRED = '0.02',
  ILLEGAL_OPERATION = '0.03',
  AUTHENTICATION_FAILED = '1.01',
  INVALID_DATA = '2.01',
  INVALID_AMOUNT = '2.02',
  INVALID_CURRENCY = '2.03',
  ORDER_NOT_FOUND = '2.05',
  INVALID_CVV2_CODE = '2.06',
  REQUEST_IS_EMPTY = '2.07',
  INVALID_CARD_NUMBER = '2.08',
  INVALID_EXPIRATION_DATE = '2.09',
  INVALID_3DS_FLOW_ON_THE_MERCHANT_SIDE = '2.10',
  INVALID_3DS_FLOW_ON_THE_BANK_SIDE = '2.11',
  INVALID_3DS_FLOW = '2.12',
  INVALID_IP = '2.13',
  SUBSCRIPTION_ERROR = '2.14',
  SCA_REQUIRE_3D_AUTHENTICATION = '2.15',
  SUBSCRIPTION_IS_LOCKED = '2.16',
  CARD_IS_BLOCKED = '3.01',
  INSUFFICIENT_FUNDS = '3.02',
  PAYMENT_AMOUNT_LIMIT_EXCESS = '3.03',
  THE_TRANSACTION_IS_DECLINED_BY_THE_ISSUER = '3.04',
  CALL_YOU_BANK = '3.05',
  DEBIT_CARD_NOT_SUPPORTED = '3.06',
  CARD_BRAND_IS_NOT_SUPPORTED = '3.07',
  DO_NOT_HONOR = '3.08',
  SECURE_3D_AUTHENTICATION_REQUIRED = '3.09',
  SUSPECTED_FRAUD = '3.10',
  CARD_IS_IN_A_BLACK_LIST = '4.01',
  STOLEN_CARD = '4.02',
  RESTRICTED_CARD = '4.03',
  LOST_CARD = '4.04',
  SUSPECTED_FRAUD2 = 'Suspected fraud',
  BLOCKED_BY_COUNTRY_IP = '4.06',
  TRUSTED_ANTIFRAUD_SYSTEM = '4.07',
  AVS_MISMATCH = '4.08',
  SOLID_ANTIFRAUD_ENGINE = '4.09',
  SECURE_3D_VERIFICATION_FAILED = '5.01',
  INVALID_CARD_TOKEN = '5.02',
  APPLICATION_ERROR = '5.03',
  MERCHANT_IS_NOT_CONFIGURED_CORRECTLY = '5.04',
  MERCHANT_IS_NOT_ACTIVATED_YET = ' 5.05',
  DUPLICATE_ORDER = '5.06',
  EXCEEDED_API_CALLS_LIMITS = '5.07',
  INVALID_TRANSACTION = '5.08',
  MERCHANT_NOT_FOUND = '5.09',
  PROCESSOR_DOES_NOT_SUPPORT_REQUEST_API_METHOD = '5.10',
  INVALID_ROUTING = '5.11',
  UNKNOWN_DECLINE_CODE = '6.01',
  CONNECTION_ERROR = '6.02',
  CARD_TOKEN_NOT_FOUND = '7.01',
  GOOGLE_PAYMENT_ERROR = '7.02',
  SMART_CASCADE_DECLINE = '7.03',
  CASCADE_3DS_TO_2D = '7.04',
  APPLE_ONLINE_PAYMENT_ERROR = '7.05',
  TOKEN_GENERATION_ERROR = '7.06',
}

export enum PaymentOrderStatus {
  APPROVED = 'approved',
  REFUNDED = 'refunded',
  DECLINED = 'declined',
  PROCESSING = 'processing',
}

export type PaymentOrderDescription = {
  status: PaymentOrderStatus;
  order_id: string;
  amount: number;
  currency: string;
  descriptor: string;
  fraudulent: boolean;
  processing_amount: number;
  processing_currency: number;
  refunded_amount: number;
};

export enum ReportProduct {
  PERSONALIZED_COMPATIBILITY_REPORT = 'personalized_compatibility_report',
  TAROT_SPREAD_FOR_2022 = 'tarot_spread_for_2022',
  NUMEROLOGY_CALCULATOR = 'numerology_calculator',
}

export enum SubscriptionProduct {
  ASK_NEBULA_WEEKLY_999 = 'asknebula_weekly_9.99',
  ASK_NEBULA_WEEKLY_499 = 'asknebula_weekly_4.99',
  ASK_NEBULA_FREE_TRIAL_WEEKLY_999 = 'asknebula_free_trial_weekly_9.99',
  INTRO_7DAY_SIMPLE_REGISTRATION_2W_19_99_150_CREDITS = '2.99_intro_7day_simplereg_2w_subscription_USD19.99_150credits',
  INTRO_7DAY_SIMPLE_REGISTRATION_1M_34_99_500_CREDITS = '5.99_intro_7day_simplereg_1m_subscription_USD34.99_500credits',
  INTRO_7DAY_SIMPLE_REGISTRATION_3M_94_99_2000_CREDITS = '14.99_intro_7day_simplereg_3m_subscription_USD99.99_2000credits',
}

export enum PackageProduct {
  WELCOME_BASIC = 'welcome_basic',
  INTRO_BASIC = 'intro_basic',
  INTRO_CREDITS_0_99 = 'intro_credits_0.99',
  INTRO_ONE_TIME_9_99_150 = 'intro_chatbot_one_time_USD9.99_150',
  INTRO_OFFER_2_99 = 'intro_offer_2.99',
  INTRO_EXPERTS_CATALOGUE_ONE_TIME_USD_199_300 = 'intro_experts_catalouge_one_time_USD1.99_300',
  INTRO_STANDARD = 'intro_standard',
  INTRO_PREMIUM = 'intro_premium',
  INTRO_COSMIC = 'intro_cosmic',
  INTRO_EMAIL_ONE_TIME_2_99_150 = 'intro_email_one_time_USD2.99_150',
  INTRO_EMAIL_ONE_TIME_0_99_100 = 'intro_email_one_time_USD0.99_100',
  BASIC = 'regular_basic',
  STANDARD = 'regular_standard',
  PREMIUM = 'regular_premium',
  COSMIC = 'regular_cosmic',
}

export type ProductNameType = ReportProduct | SubscriptionProduct | PackageProduct;

export const ProductName = {
  ...ReportProduct,
  ...SubscriptionProduct,
  ...PackageProduct,
};

export interface Package {
  id: PackageProduct;
  label: string;
  display_price: string;
  display_discount: string | null;
  display_features: string[];
  charge_amount: number;
  top_up_amount: number;
  sort_order: number;
  is_special: boolean;
}
