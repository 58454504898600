import { useEffect, useRef, useState } from 'react';
import { MEDIA_QUERIES } from 'src/core/common/constants';
import { useServices } from './useServices';

export function useReducedMotion(): boolean {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);
  const { appEnvironmentService } = useServices();

  const { current: mediaQuery } = useRef(
    appEnvironmentService.createMediaQuery(MEDIA_QUERIES.prefersReducedMotion),
  );

  useEffect(() => {
    if (mediaQuery) {
      const listener = () => {
        setPrefersReducedMotion(mediaQuery.matches);
      };

      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener('change', listener);
      } else {
        mediaQuery.addListener(listener);
      }

      return () => {
        if (mediaQuery.removeEventListener) {
          mediaQuery.removeEventListener('change', listener);
        } else {
          mediaQuery.removeListener(listener);
        }
      };
    }
  }, [mediaQuery]);

  return prefersReducedMotion;
}
