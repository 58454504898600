/**
 * For more details of how attribution parameters are generated
 * see official facebook developers documentation:
 * https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
 */

import Cookies from 'js-cookie';

export type CAPIAttributionParameters = {
  fbp: string;
  fbc: string;
};

enum Version {
  FB = 'fb',
}

enum SubdomainIndex {
  FACEBOOK_COM = '1',
}

export class ConversionsAPIService {
  private readonly fbpCookieKey = '_fbp';

  private readonly fbcCookieKey = '_fbc';

  private readonly fbClickIdKey = 'fbclid';

  getAttributionParameters(): CAPIAttributionParameters {
    const attributionParameters = this.getAttributionParametersFromCookies();

    if (!attributionParameters.fbp) {
      attributionParameters.fbp = this.generateFbp();
    }

    if (!attributionParameters.fbc) {
      attributionParameters.fbc = this.generateFbc();
    }

    return attributionParameters as CAPIAttributionParameters;
  }

  private getAttributionParametersFromCookies(): Partial<CAPIAttributionParameters> {
    return {
      fbp: Cookies.get(this.fbpCookieKey),
      fbc: Cookies.get(this.fbcCookieKey),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private generateFbp(): string {
    const randomInteger = Math.round(Math.random() * 1e10);
    return ConversionsAPIService.generateAttributionParameter(randomInteger);
  }

  private generateFbc(): string {
    const { searchParams } = new URL(window.location.href);
    const fbClickId = searchParams.get(this.fbClickIdKey) || '';
    return ConversionsAPIService.generateAttributionParameter(fbClickId);
  }

  private static generateAttributionParameter(randomIntOrFbClickId: number | string): string {
    const currentTimestamp = Date.now();
    return [Version.FB, SubdomainIndex.FACEBOOK_COM, currentTimestamp, randomIntOrFbClickId].join(
      '.',
    );
  }
}
