import { prepareHtml } from 'src/core/common/utils/prepareHtml';
import styles from './IconGradientPreloader.module.scss';

export const IconGradientPreloader = () => {
  return (
    <span
      className={styles.root}
      aria-hidden="true"
      dangerouslySetInnerHTML={prepareHtml(`
          <svg focusable="false">
              <linearGradient 
                id="gradient-pink-base" 
                x1="8" 
                y1="28.0038" 
                x2="69.9108" 
                y2="28.8529" 
                gradientUnits="userSpaceOnUse">
              <stop stop-color="#6A4DBC"/>
              <stop offset="1" stop-color="#F2994A"/>
            </linearGradient> 
          </svg>
          <svg focusable="false">
            <linearGradient 
               id="gradient-purple" 
               x1="26.1326" 
               y1="5.06436" 
               x2="3.37914" 
               y2="17.3005" 
               gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#BD98F5"/>
              <stop offset="0.25" stop-color="#A9A1E8"/>
              <stop offset="0.90" stop-color="#5E66FD"/>
            </linearGradient>
          </svg>
        `)}
    />
  );
};

export default IconGradientPreloader;
