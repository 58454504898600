import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class SignInByTokenPendingEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class SignInByTokenPendingEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SignInByTokenPendingEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('authenticate_by_token_pending');
  }
}
