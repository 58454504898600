import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class DailyHoroscopeMainStartChatClickEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class DailyHoroscopeMainStartChatClickEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof DailyHoroscopeMainStartChatClickEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_daily_horoscope_main_page_start_chat_click');
  }
}
