import { normalizeError } from 'src/core/common/utils/errors';
import { parseQueryParams } from 'src/core/common/utils/query';
import { BaseService, HttpClient } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { UserLocation } from '../entities';
import { LocationService } from './LocationService';

export class LocationClientService extends BaseService implements LocationService {
  private logger: Logger;

  private overriddenValues: Partial<UserLocation> = {};

  constructor(httpClient: HttpClient, logger: Logger) {
    super(httpClient);
    this.logger = logger;
    this.loadFormQueryParams();
  }

  async getLocation(): Promise<UserLocation> {
    try {
      const { data } = await this.httpClient.get('/user/locale');

      return {
        region: this.overriddenValues.region || data.region,
        country: this.overriddenValues.country || data.country,
        countryName: this.overriddenValues.countryName || data.country_name,
        cityName: this.overriddenValues.cityName || data.city_name,
      };
    } catch (err) {
      const error = normalizeError(err);
      this.logger.error(error);

      return {};
    }
  }

  private loadFormQueryParams() {
    const queries = parseQueryParams() as Partial<UserLocation>;

    this.overriddenValues.region = queries.region;
    this.overriddenValues.country = queries.country;
  }
}
