import { AxiosHttpClient } from './AxiosHttpClient';
import { HttpRequestConfig } from './HttpClient';

type HttpClientOptions = {
  apiUrl: string;
  apiPrefix?: string;
  requestConfig?: HttpRequestConfig;
};

export const createHttpClient = (options: HttpClientOptions) => {
  return new AxiosHttpClient(options.apiUrl, options.apiPrefix || '', options.requestConfig);
};
