import { createAsyncThunk } from '@reduxjs/toolkit';
import { Services } from 'src/core/common/context';
import { normalizeError } from 'src/core/common/utils/errors';
import { AppThunk } from 'src/core/common/store';
import { UserLocation } from '../entities';

export const fetchLocation = createAsyncThunk<UserLocation, void, { extra: Services }>(
  'location/FETCH_LOCATION',
  async (_, { extra, rejectWithValue }) => {
    const { locationService } = extra;

    try {
      return await locationService.getLocation();
    } catch (err) {
      const error = normalizeError(err);

      return rejectWithValue(error.message);
    }
  },
);

export const fetchLocationRequest = (): AppThunk => async (dispatch) => {
  return dispatch(fetchLocation());
};
