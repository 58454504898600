import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Services } from 'src/core/common/context';
import { normalizeError } from 'src/core/common/utils/errors';
import { FlowName } from 'src/core/quiz/entities';
import {
  Expert,
  ExpertCategory,
  ExpertRequestFilter,
  ExpertReview,
  ExpertsRequestParams,
} from '../entities';

export const currentExpertUpdated = createAction<string | null>('expert/CURRENT_EXPERT_UPDATED');

export const fetchInitialExperts = createAsyncThunk<
  Array<Expert>,
  { perPage: number; customerSource: FlowName; filter?: ExpertRequestFilter },
  { extra: Services }
>('experts/FETCH_INITIAL_EXPERTS', async ({ perPage, customerSource, filter }, { extra }) => {
  const { expertService, logger } = extra;
  try {
    const experts = await expertService.getExperts({ perPage, page: 1, customerSource, filter });
    return experts;
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);
    return [];
  }
});

export const fetchAllExperts = createAsyncThunk<
  Array<Expert>,
  ExpertsRequestParams,
  { extra: Services }
>('experts/FETCH_ALL_EXPERTS', async (params, { extra }) => {
  const { expertService, logger } = extra;
  try {
    const experts = await expertService.getExperts(params);
    return experts;
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);
    return [];
  }
});

export const fetchExpertsByCategories = createAsyncThunk<
  Array<Expert>,
  { categories: ExpertCategory[]; customerSource: FlowName },
  { extra: Services }
>('experts/FETCH_EXPERTS_BY_CATEGORIES', async ({ customerSource }, { extra }) => {
  const { expertService, logger } = extra;

  try {
    const experts = await expertService.getExperts({ customerSource });
    return experts;
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);
    return [];
  }
});

export const fetchExpertsByCategory = createAsyncThunk<
  Array<Expert>,
  { category: ExpertCategory; customerSource: FlowName },
  { extra: Services }
>('experts/FETCH_EXPERTS_BY_CATEGORY', async ({ customerSource }, { extra }) => {
  const { expertService, logger } = extra;
  try {
    const experts = await expertService.getExperts({ customerSource });
    return experts;
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);
    return [];
  }
});

export const fetchRandomExpert = createAsyncThunk<
  { info: Expert | null; reviews: Array<ExpertReview> | null },
  FlowName | 'seo',
  { extra: Services }
>('experts/FETCH_RANDOM_EXPERT', async (customerSource, { extra }) => {
  const { expertService, logger } = extra;
  try {
    const expert = await expertService.getRandomExpert({ customerSource });
    const reviews = await expertService.getExpertReviews(expert.id, {
      filter: {
        withText: true,
        processingStatus: 'published',
      },
    });
    return { info: expert, reviews };
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);
    return { info: null, reviews: null };
  }
});

export const fetchExpertReviews = createAsyncThunk<
  Array<ExpertReview>,
  string,
  { extra: Services }
>('expert/FETCH_EXPERT_REVIEWS', async (expertId: string, { extra }) => {
  const { expertService, logger } = extra as Services;
  try {
    const reviews = await expertService.getExpertReviews(expertId, {
      filter: {
        withText: true,
        processingStatus: 'published',
      },
    });
    return reviews;
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);
    return [];
  }
});
