import {
  deserializeError as libDeserialize,
  serializeError as libSerialize,
} from 'serialize-error';
import { UnknownError } from 'src/core/common/errors';

export function normalizeError(rawErr: unknown): Error {
  return rawErr instanceof Error ? rawErr : new UnknownError(rawErr);
}

export function serializeError(error: Error) {
  return libSerialize(error);
}

export function deserializeError(errorObject: unknown) {
  return libDeserialize(errorObject);
}
