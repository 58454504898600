import { BotStep, CreateAccountStep, SplitStep, Step, StepType, UserStep } from '../step';

export const isCreateAccountStep = <ScenarioState>(
  step: Step<ScenarioState> | null,
): step is CreateAccountStep => {
  return step?.type === StepType.CREATE_ACCOUNT;
};

export const isBotStep = <ScenarioState>(
  step: Step<ScenarioState> | null,
): step is BotStep<ScenarioState> => {
  return step?.type === StepType.BOT;
};

export const isUserStep = <ScenarioState>(
  step: Step<ScenarioState> | null,
): step is UserStep<ScenarioState> => {
  return step?.type === StepType.USER;
};

export const isSplitStep = <ScenarioState>(
  step: Step<ScenarioState> | null,
): step is SplitStep<ScenarioState> => {
  return step?.type === StepType.SPLIT;
};

export const isFinalStep = <ScenarioState>(step: Step<ScenarioState> | null): boolean => {
  return !!step && !step.next;
};
