import { Coordinates } from 'src/core/common/entities';
import { StepId, StepType } from './step';

export enum ChatBotName {
  CHAT_BOT = 'chat_bot',
}

export type ChatBotMessage = {
  id: StepId;
  type: StepType;
  text: string;
  createdAt: number;
};

export type ChatBotAnswers = Partial<{
  expertName: string;
  question: string;
  name: string;
  gender: string;
  date: string;
  time: string;
  location: {
    name: string;
    coordinates: Coordinates;
  };
  email: string;
}>;
