import { CatalogueCategoryOpenEventHandler } from './CatalogueCategoryOpenEvent';
import { CatalogueCategoryExpertCardClickEventHandler } from './CatalogueCategoryExpertCardClickEvent';
import { CatalogueCategoryExpertCardStartChatClickEventHandler } from './CatalogueCategoryExpertStartChatClickEvent';
import { CatalogueCategoryBrowseAdvisorsClickEventHandler } from './CatalogueCategoryBrowseAdvisorsClickEvent';
import { CatalogueCategoryFindAdvisorClickEventHandler } from './CatalogueCategoryFindAdvisorClickEvent';

export * from './CatalogueCategoryOpenEvent';
export * from './CatalogueCategoryExpertCardClickEvent';
export * from './CatalogueCategoryExpertStartChatClickEvent';
export * from './CatalogueCategoryBrowseAdvisorsClickEvent';
export * from './CatalogueCategoryFindAdvisorClickEvent';

export const psychicsCategoriesPageEventHandlers = [
  CatalogueCategoryOpenEventHandler,
  CatalogueCategoryExpertCardClickEventHandler,
  CatalogueCategoryExpertCardStartChatClickEventHandler,
  CatalogueCategoryBrowseAdvisorsClickEventHandler,
  CatalogueCategoryFindAdvisorClickEventHandler,
];
