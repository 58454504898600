import { config } from 'src/config';
import { joinUrls } from 'src/core/common/utils/url';

export enum BASE_ROUTES {
  HOME = '/',
  PSYCHICS = '/psychics',
  BLOGS = '/blog',
  BLOGS_PAGE = '/blog/page',
  BLOG_TAG = '/blog/tag',
  NEBULA_APP_MOBILE = '/nebula-app-mobile',
  ZODIAC_COMPATIBILITY = '/zodiac-compatibility',
  ZODIAC_SIGN = '/zodiac-sign',
  PSYCHIC_NEAR_ME = '/psychic-near-me',
  TAROT_READING_NEAR_ME = '/tarot-reading-near-me',
  REVIEWS = '/reviews',
  CONTACT_US = '/contact-us',
  FAQ = '/faq',
  CAREERS = '/careers',
  PRESS = '/press',
  SUPPORT = '/support',
  SUPPORT_BOT = '/support-bot',
  DAILY_HOROSCOPE = '/horoscope/daily',
  SIGN_UP = '/signup',
  MEANING = '/meaning',
  ADVISORS = '/advisors',
  CHARTS = '/charts',
  ADVISORS_PAGE = '/advisors/page',
  APP_PRIVACY_POLICY = '/app-privacy-policy',
  APP_TERMS_OF_USE = '/app-terms-of-use',
  POST_QUIZ = '/flow/lp/intro-offer-1',

  SIMPLE_REGISTRATION_LANDING = '/flow/lp/simple-registration',
  SIMPLE_REGISTRATION_EXPERTS = '/flow/lp/simple-registration/experts',
  SIMPLE_REGISTRATION_FORGOT_PASSWORD = '/flow/lp/simple-registration/forgot-password',
  SIMPLE_REGISTRATION_FORGOT_PASSWORD_SUCCESS = '/flow/lp/simple-registration/forgot-password-success',
  SIMPLE_REGISTRATION_QUIZ = '/flow/lp/simple-registration/quiz',
  SIMPLE_REGISTRATION_QUIZ_GENDER = '/flow/lp/simple-registration/quiz/gender',
  SIMPLE_REGISTRATION_QUIZ_GOAL_SETUP = '/flow/lp/simple-registration/quiz/goalSetup',
  SIMPLE_REGISTRATION_QUIZ_GOAL = '/flow/lp/simple-registration/quiz/goal',
  SIMPLE_REGISTRATION_QUIZ_PSYCHIC_READING = '/flow/lp/simple-registration/quiz/psychicReading',
  SIMPLE_REGISTRATION_SUBSCRIPTION = '/flow/lp/simple-registration/subscription',

  SIMPLE_SIMPLE_REGISTRATION_QUIZ = '/flow/lp/simple-simple-registration/quiz',
  SIMPLE_SIMPLE_REGISTRATION_QUIZ_GENDER = '/flow/lp/simple-simple-registration/quiz/gender',

  SIMPLE_NATAL_CHART_LANDING = '/flow/lp/simple-natal-chart',
  SIMPLE_NATAL_CHART_EXPERTS = '/flow/lp/simple-natal-chart/experts',
  SIMPLE_NATAL_CHART_QUIZ = '/flow/lp/simple-natal-chart/quiz',
  SIMPLE_SYNASTRY_LANDING = '/flow/lp/simple-synastry',
  SIMPLE_SYNASTRY_EXPERTS = '/flow/lp/simple-synastry/experts',
  SIMPLE_SYNASTRY_QUIZ = '/flow/lp/simple-synastry/quiz',
  SIMPLE_TAROT_LANDING = '/flow/lp/simple-tarot',
  SIMPLE_TAROT_EXPERTS = '/flow/lp/simple-tarot/experts',
  SIMPLE_TAROT_QUIZ = '/flow/lp/simple-tarot/quiz',
  SIMPLE_ASTROLOGY_LANDING = '/flow/lp/simple-astrology',
  SIMPLE_ASTROLOGY_EXPERTS = '/flow/lp/simple-astrology/experts',
  SIMPLE_ASTROLOGY_QUIZ = '/flow/lp/simple-astrology/quiz',
  EXPERTS_CATALOGUE = '/flow/lp/experts-catalogue',
  EXPERTS_CATALOGUE_TAROT = '/flow/lp/tarot-experts-catalogue',
  EXPERTS_CATALOGUE_FORTUNE_TELLING = '/flow/lp/fortune-telling-experts-catalogue',
  EXPERTS_CATALOGUE_LOVE_SPELL = '/flow/lp/love-spell-experts-catalogue',
  EXPERTS_CATALOGUE_BEST_PSYCHIC = '/flow/lp/best-psychic-live-chat',
  EXPERTS_CATALOGUE_NUMEROLOGY = '/flow/lp/numerology-experts-catalogue',
  EXPERTS_CATALOGUE_ASTROLOGY = '/flow/lp/astrology-experts-catalogue',

  EXPERTS_CATALOGUE_PSYCHICS_CATALOGUE = '/flow/lp/experts-catalogue/psychics/catalogue',
  EXPERTS_CATALOGUE_PSYCHICS = '/flow/lp/experts-catalogue/psychics',
  EXPERTS_CATALOGUE_PSYCHICS_QUIZ = '/flow/lp/experts-catalogue/psychics/signUp',
  EXPERTS_CATALOGUE_PSYCHICS_CHAT = '/flow/lp/experts-catalogue/psychics/chat',

  EXPERTS = '/experts',
  PAYMENT = '/flow/lp/payment',
  APPNEBULA = '/flow/lp/appnebula',
}

export const mainAppRoutes = {
  cookiePolicy: joinUrls(config.mainAppUrl, '/cookie-policy'),

  terms: joinUrls(config.mainAppUrl, '/terms'),

  privacyPolicy: joinUrls(config.mainAppUrl, '/privacy-policy'),

  moneyBackPolicy: joinUrls(config.mainAppUrl, '/money-back-policy'),

  FAQ: joinUrls(config.mainAppUrl, '/faq'),

  personalInfoPolicy: joinUrls(config.mainAppUrl, '/personal-info-policy'),

  paymentTerms: joinUrls(config.mainAppUrl, '/payment-terms'),

  liveChatRules: joinUrls(config.mainAppUrl, '/live-chat-rules'),

  support: joinUrls(config.mainAppUrl, '/support'),

  login: joinUrls(config.mainAppUrl, '/login'),

  experts: joinUrls(config.mainAppUrl, '/experts'),

  signupCredits: joinUrls(config.mainAppUrl, '/signup/credits'),

  signupFreeTrial: joinUrls(config.mainAppUrl, '/signup/trial-info'),

  expertChat: joinUrls(config.mainAppUrl, '/chat/'),

  signUp: joinUrls(config.mainAppUrl, '/signup'),

  commands: joinUrls(config.mainAppUrl, '/commands/'),
};

export const loaderEnabledRoutes = ['/flow/lp'];
