/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { LoadingStatuses } from 'src/core/common/entities';
import { FeatureFlags } from '../services';

export const FeatureFlagsContext = createContext<{
  featureFlags: FeatureFlags;
  featureFlagsLoadingStatus: LoadingStatuses;
}>(null!);

export type FeatureFlagsProviderProps = {
  children?: ReactNode;
  featureFlags: FeatureFlags;
  featureFlagsLoadingStatus: LoadingStatuses;
};

export const FeatureFlagsProvider = ({
  children,
  featureFlags,
  featureFlagsLoadingStatus,
}: FeatureFlagsProviderProps) => {
  const [updateCount, setStateUpdateCount] = useState(0);

  useEffect(() => {
    const noop = () => {};

    if (!featureFlags) return noop();

    featureFlags.setStateUpdateHandler(() => {
      setStateUpdateCount((value) => value + 1);
    });

    return () => {
      featureFlags.setStateUpdateHandler(noop);
    };
  }, [featureFlags]);

  const providerValue = useMemo(() => {
    return { featureFlags, featureFlagsLoadingStatus };
  }, [featureFlags, featureFlagsLoadingStatus, updateCount]);

  return (
    <FeatureFlagsContext.Provider value={providerValue}>{children}</FeatureFlagsContext.Provider>
  );
};
