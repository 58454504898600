import { SupportBotPageChatActionEventHandler } from './SupportBotPageChatActionEvent';
import { SupportBotPageChatLoadFailEventHandler } from './SupportBotPageChatLoadFailEvent';
import { SupportBotPageOpenEventHandler } from './SupportBotPageOpenEvent';
import { SupportBotPageChatLoadEventHandler } from './SupportBotPageChatLoadEvent';

export * from './SupportBotPageChatActionEvent';
export * from './SupportBotPageChatLoadFailEvent';
export * from './SupportBotPageOpenEvent';
export * from './SupportBotPageChatLoadEvent';

export const technicalPagesEventHandlers = [
  SupportBotPageOpenEventHandler,
  SupportBotPageChatActionEventHandler,
  SupportBotPageChatLoadFailEventHandler,
  SupportBotPageChatLoadEventHandler,
];
