import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type AdvisorSinglePageBlogClickEventPayload = {
  name: string;
  blogName: string;
};

export class AdvisorSinglePageBlogClickEvent extends BaseEvent<AdvisorSinglePageBlogClickEventPayload> {}

export class AdvisorSinglePageBlogClickEventHandler extends BaseEventHandler<AdvisorSinglePageBlogClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof AdvisorSinglePageBlogClickEvent;
  }

  async track(event: AdvisorSinglePageBlogClickEvent) {
    const { name, blogName } = event.getPayload();

    return this.amplitudeService.logEvent('seo_advisor_page_blog_click', {
      name,
      blog_name: convertToSnakeCase(blogName),
    });
  }
}
