import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossaryMainPageAngelNumberClickEventPayload = {
  number: string;
};

export class GlossaryMainPageAngelNumberClickEvent extends BaseEvent<GlossaryMainPageAngelNumberClickEventPayload> {}

export class GlossaryMainPageAngelNumberClickEventHandler extends BaseEventHandler<GlossaryMainPageAngelNumberClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossaryMainPageAngelNumberClickEvent;
  }

  async track(event: GlossaryMainPageAngelNumberClickEvent) {
    const { number } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_main_page_angel_number_click', {
      number,
    });
  }
}
