import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { TrustSectionMainItem } from 'src/reviews/entities';

export type ReviewsSingleTrustedPlatformClickEventPayload = {
  competitor: string;
  platform: TrustSectionMainItem['name'];
};

export class ReviewsSingleTrustedPlatformClickEvent extends BaseEvent<ReviewsSingleTrustedPlatformClickEventPayload> {}

export class ReviewsSingleTrustedPlatformClickEventHandler extends BaseEventHandler<ReviewsSingleTrustedPlatformClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ReviewsSingleTrustedPlatformClickEvent;
  }

  async track(event: ReviewsSingleTrustedPlatformClickEvent) {
    const { competitor, platform } = event.getPayload();
    return this.amplitudeService.logEvent('seo_reviews_page_trusted_review_platform_click', {
      competitor,
      platform,
    });
  }
}
