import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type ZodiacTraitsPageCtaClickEventPayload = {
  zodiac: ZodiacTypes;
  name: string;
};

export class ZodiacTraitsPageCtaClickEvent extends BaseEvent<ZodiacTraitsPageCtaClickEventPayload> {}

export class ZodiacTraitsPageCtaClickEventHandler extends BaseEventHandler<ZodiacTraitsPageCtaClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacTraitsPageCtaClickEvent;
  }

  async track(event: ZodiacTraitsPageCtaClickEvent) {
    const { zodiac, name } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_traits_page_cta_click', {
      zodiac,
      name: convertToSnakeCase(name),
    });
  }
}
