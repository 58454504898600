import { useRouter } from 'next/router';
import { withAppOrigin } from 'src/core/common/utils/url';

export const useDefaultCanonicalUrl = () => {
  const router = useRouter();

  const defaultCanonicalUrl = withAppOrigin(router.asPath).split('?')[0];

  return {
    defaultCanonicalUrl,
  };
};
