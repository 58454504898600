import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ExpertId, ExpertStatus } from 'src/core/experts/entities';

type EventPayload = {
  expertId: ExpertId;
  expertStatus: ExpertStatus;
  eventValue: string;
};

export class ExpertCardVisibleEvent extends BaseEvent<EventPayload> {}

export class ExpertCardVisibleEventHandler extends BaseEventHandler<EventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ExpertCardVisibleEvent;
  }

  async track(event: ExpertCardVisibleEvent) {
    const { expertId, expertStatus, eventValue } = event.getPayload();

    return this.amplitudeService.logEvent('expert_card_open', {
      expert_id: expertId,
      event_value: eventValue,
      expert_status: expertStatus,
    });
  }
}
