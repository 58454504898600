import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';

export type ZodiacCompatibilitySinglePageOpenEventPayload = {
  zodiac: ZodiacTypes;
};

export class ZodiacCompatibilitySinglePageOpenEvent extends BaseEvent<ZodiacCompatibilitySinglePageOpenEventPayload> {}

export class ZodiacCompatibilitySinglePageOpenEventHandler extends BaseEventHandler<ZodiacCompatibilitySinglePageOpenEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacCompatibilitySinglePageOpenEvent;
  }

  async track(event: ZodiacCompatibilitySinglePageOpenEvent) {
    const { zodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_compatibility_page_open', {
      zodiac,
    });
  }
}
