import { GTMService } from './GTMService';

export class GTMClientService implements GTMService {
  private static get dataLayer() {
    return window.dataLayer;
  }

  purchase({ price, orderId, productId }: { productId: string; price: number; orderId: string }) {
    this.track({
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          actionField: {
            id: orderId,
            revenue: price,
          },
          products: [
            {
              id: productId,
              name: productId,
              price,
              quantity: 1,
            },
          ],
        },
      },
      eventCategory: 'ecommerce',
      eventAction: 'purchase',
      eventLabel: 'acquisition',
      eventValue: 'revenue',
      eventNonInteraction: 'False',
      event: 'autoEvent',
    });
  }

  lead() {
    this.track({
      event: 'autoEvent',
      eventNonInteraction: 'False',
      eventCategory: 'email',
      eventAction: 'entered_email',
      eventLabel: 'entered_email',
    });
  }

  private track(event: unknown) {
    GTMClientService.dataLayer.push(event);
  }
}
