import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserEmail } from 'src/core/user/store/selectors';

export function useGoToInbox() {
  const userEmail = useSelector(getUserEmail);

  const goToInbox = useCallback(() => {
    const emailDomain = userEmail.split('@').pop();
    window.open(`https://${emailDomain}`, '_blank');
  }, [userEmail]);

  return {
    goToInbox,
  };
}
