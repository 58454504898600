import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class SimpleRegistrationMainPageExpertsClickedEvent extends BaseEvent {}

export class SimpleRegistrationMainPageExpertsClickedEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SimpleRegistrationMainPageExpertsClickedEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('registration_experts_start_screen_click');
  }
}
