import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type CatalogueCategoryExpertCardClickEventPayload = {
  landing: string;
};

export class CatalogueCategoryExpertCardClickEvent extends BaseEvent<CatalogueCategoryExpertCardClickEventPayload> {}

export class CatalogueCategoryExpertCardClickEventHandler extends BaseEventHandler<CatalogueCategoryExpertCardClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof CatalogueCategoryExpertCardClickEvent;
  }

  async track(event: CatalogueCategoryExpertCardClickEvent) {
    const { landing } = event.getPayload();

    this.googleAnalyticsService.trackGA4({
      name: 'click_from_seo',
    });

    return this.amplitudeService.logEvent('seo_landing_page_expert_card_click', {
      landing: convertToSnakeCase(landing),
    });
  }
}
