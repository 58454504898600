import { BaseEvent, BaseEventHandler } from 'src/core/analytics/events';

export type FeatureFlagsConfigLoadedEventProperties = {
  config: Record<string, unknown>;
};

export class FeatureFlagsConfigLoadedEvent extends BaseEvent<FeatureFlagsConfigLoadedEventProperties> {}

export class FeatureFlagsConfigLoadedEventHandler extends BaseEventHandler<FeatureFlagsConfigLoadedEventProperties> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof FeatureFlagsConfigLoadedEvent;
  }

  async track(event: FeatureFlagsConfigLoadedEvent) {
    const { config } = event.getPayload();

    return this.amplitudeService.logEvent('growthbook_config_download', {
      data: config,
    });
  }
}
