import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export class SupportBotPageChatLoadFailEvent extends BaseEvent {
  constructor() {
    super(null);
  }
}

export class SupportBotPageChatLoadFailEventHandler extends BaseEventHandler {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof SupportBotPageChatLoadFailEvent;
  }

  async track() {
    return this.amplitudeService.logEvent('seo_support_bot_page_chat_load_fail');
  }
}
