import { RootInitialState } from 'src/core/common/store/rootReducer';

export const getActiveChatBotName = (state: RootInitialState) => state.chatBot.activeChatBotName;

export const getCurrentStepId = (state: RootInitialState) => state.chatBot.currentStepId;

export const getChatBotMessages = (state: RootInitialState) => state.chatBot.messages;

export const getBotIsTyping = (state: RootInitialState) => state.chatBot.botIsTyping;

export const getChatBotAnswers = (state: RootInitialState) => state.chatBot.answers;

export const getChatIsEnded = (state: RootInitialState) => state.chatBot.chatIsEnded;
