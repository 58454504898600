import { CatalogueMainOpenEventHandler } from './CatalogueMainOpenEvent';
import { CatalogueMainExpertStartChatClickEventHandler } from './CatalogueMainExpertStartChatClickEvent';
import { CatalogueMainExpertCardClickEventHandler } from './CatalogueMainExpertCardClickEvent';
import { CatalogueMainFindAdvisorClickEventHandler } from './CatalogueMainFindAdvisorClickEvent';
import { CatalogueMainBrowseMoreAdvisorsClickEventHandler } from './CatalogueMainBrowseMoreAdvisorsClickEvent';
import { CatalogueMainTopicClickEventHandler } from './CatalogueMainTopicClickEvent';
import { CatalogueMainCategoryClickEventHandler } from './CatalogueMainCategoryClickEvent';
import { CatalogueMainBrowseCategoryClickEventHandler } from './CatalogueMainBrowseCategoryClickEvent';

export * from './CatalogueMainOpenEvent';
export * from './CatalogueMainExpertStartChatClickEvent';
export * from './CatalogueMainExpertCardClickEvent';
export * from './CatalogueMainFindAdvisorClickEvent';
export * from './CatalogueMainBrowseMoreAdvisorsClickEvent';
export * from './CatalogueMainCategoryClickEvent';
export * from './CatalogueMainBrowseCategoryClickEvent';
export * from './CatalogueMainTopicClickEvent';

export const psychicsPageEventHandlers = [
  CatalogueMainOpenEventHandler,
  CatalogueMainExpertStartChatClickEventHandler,
  CatalogueMainExpertCardClickEventHandler,
  CatalogueMainFindAdvisorClickEventHandler,
  CatalogueMainBrowseMoreAdvisorsClickEventHandler,
  CatalogueMainTopicClickEventHandler,
  CatalogueMainCategoryClickEventHandler,
  CatalogueMainBrowseCategoryClickEventHandler,
];
