import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'src/core/common/entities';
import {
  currentExpertUpdated,
  fetchAllExperts,
  fetchExpertsByCategories,
  fetchExpertsByCategory,
  fetchExpertReviews,
  fetchInitialExperts,
  fetchRandomExpert,
} from './actions';
import { ExpertsState } from './state';

export const expertsInitialState: ExpertsState = {
  data: {},
  loadingStatus: LoadingStatuses.IDLE,
  loadingInitialExpertsStatus: LoadingStatuses.IDLE,
  totalAmount: 0,
  currentPage: 1,
  perPage: 5,
  expertReview: {
    reviews: [],
    loadingStatus: LoadingStatuses.IDLE,
  },
  currentExpert: null,
  randomExpert: {
    info: null,
    reviews: null,
    loadingStatus: LoadingStatuses.IDLE,
  },
};

export const expertsReducer = createReducer(expertsInitialState, (builder) => {
  builder
    .addCase(fetchInitialExperts.pending, (state) => {
      state.loadingInitialExpertsStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchInitialExperts.fulfilled, (state, { payload: experts }) => {
      state.loadingInitialExpertsStatus = LoadingStatuses.FULFILLED;
      state.data = experts.reduce(
        (acc, expert) => ({
          ...acc,
          [expert.id]: expert,
        }),
        {},
      );
    })
    .addCase(fetchInitialExperts.rejected, (state) => {
      state.loadingInitialExpertsStatus = LoadingStatuses.FAILED;
    })
    .addCase(fetchAllExperts.pending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchAllExperts.fulfilled, (state, { payload: experts }) => {
      state.loadingStatus = LoadingStatuses.FULFILLED;
      state.totalAmount = experts.length;
      const newExperts = experts.reduce((acc, expert) => {
        if (state.data[expert.id]) {
          state.data[expert.id] = { ...state.data[expert.id], ...expert };
          return acc;
        }

        return {
          ...acc,
          [expert.id]: expert,
        };
      }, {});
      state.data = { ...state.data, ...newExperts };
    })
    .addCase(fetchAllExperts.rejected, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    })
    .addCase(fetchExpertsByCategories.pending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchExpertsByCategories.fulfilled, (state, { payload: experts }) => {
      state.loadingStatus = LoadingStatuses.FULFILLED;
      state.totalAmount = experts.length;
      const newExperts = experts.reduce((acc, expert) => {
        if (state.data[expert.id]) {
          state.data[expert.id] = { ...state.data[expert.id], ...expert };
          return acc;
        }

        return {
          ...acc,
          [expert.id]: expert,
        };
      }, {});
      state.data = { ...state.data, ...newExperts };
    })
    .addCase(fetchExpertsByCategories.rejected, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    })
    .addCase(fetchExpertsByCategory.pending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchExpertsByCategory.fulfilled, (state, { payload: experts }) => {
      state.loadingStatus = LoadingStatuses.FULFILLED;
      state.totalAmount = experts.length;
      const newExperts = experts.reduce((acc, expert) => {
        if (state.data[expert.id]) {
          state.data[expert.id] = { ...state.data[expert.id], ...expert };
          return acc;
        }

        return {
          ...acc,
          [expert.id]: expert,
        };
      }, {});
      state.data = { ...state.data, ...newExperts };
    })
    .addCase(fetchExpertsByCategory.rejected, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    })
    .addCase(currentExpertUpdated, (state, { payload: expertId }) => {
      if (!expertId) {
        state.currentExpert = null;
        return;
      }

      const expert = state.data[expertId];
      if (!expert) return;
      state.currentExpert = expert;
    })
    .addCase(fetchExpertReviews.pending, (state) => {
      state.expertReview.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchExpertReviews.fulfilled, (state, { payload: reviews }) => {
      state.expertReview.loadingStatus = LoadingStatuses.FULFILLED;
      state.expertReview.reviews = reviews;
    })
    .addCase(fetchExpertReviews.rejected, (state) => {
      state.expertReview.loadingStatus = LoadingStatuses.FAILED;
    })
    .addCase(fetchRandomExpert.pending, (state) => {
      state.randomExpert.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchRandomExpert.fulfilled, (state, { payload }) => {
      const { info, reviews } = payload;
      state.randomExpert.loadingStatus = LoadingStatuses.FULFILLED;
      state.randomExpert.info = info;
      state.randomExpert.reviews = reviews;
    })
    .addCase(fetchRandomExpert.rejected, (state) => {
      state.randomExpert.loadingStatus = LoadingStatuses.FAILED;
      state.randomExpert.info = null;
    });
});
