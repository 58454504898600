import { v4 as uuid } from 'uuid';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ZodiacService } from 'src/core/zodiac/services';
import { EmailDTO, NewUserDTO } from 'src/core/auth/services/dto';
import { Services } from 'src/core/common/context';
import { FlowName } from 'src/core/quiz/entities';
import { delay } from 'src/core/common/utils/delay';
import { AppThunk } from 'src/core/common/store';
import { RootInitialState } from 'src/core/common/store/rootReducer';
import {
  BotStep,
  ChatBotAnswers,
  ChatBotName,
  isBotStep,
  isCreateAccountStep,
  isFinalStep,
  isSplitStep,
  isUserStep,
  Step,
  StepId,
  StepType,
} from '../entities';
import {
  addAnswer,
  addMessage,
  chatEnded,
  setActiveChatBotName,
  setBotIsNotTyping,
  setBotIsTyping,
  setChatBotScenarioStepId,
} from './actions';
import { getActiveChatBotName, getChatBotAnswers, getCurrentStepId } from './selectors';

export const startOrResumeChat =
  (name: ChatBotName): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const currentStepId = getCurrentStepId(state);

    if (!currentStepId) {
      dispatch(setActiveChatBotName({ name }));
      dispatch(moveToInitialStep());
    }
  };

export const maybeTypeAndAddMessage =
  (text: string): AppThunk =>
  async (dispatch, getState, { chatBotService }) => {
    const state = getState();
    const chatBotName = getActiveChatBotName(state);

    if (!chatBotName) {
      return;
    }

    const scenario = chatBotService.getScenario(chatBotName);
    const currentStepId = getCurrentStepId(state);
    const currentStep = scenario.getStep(currentStepId);

    if (!currentStep) {
      return;
    }

    if (isBotStep(currentStep)) {
      dispatch(setBotIsTyping());
      await delay(currentStep.typingDuration);
      dispatch(setBotIsNotTyping());
    }

    dispatch(
      addMessage({
        id: uuid(),
        type: currentStep.type,
        text,
        createdAt: Date.now(),
      }),
    );
  };

export const moveToInitialStep =
  (): AppThunk =>
  async (dispatch, getState, { chatBotService }) => {
    const state = getState();
    const chatBotName = getActiveChatBotName(state);

    if (!chatBotName) {
      return;
    }

    const scenario = chatBotService.getScenario(chatBotName);
    const initialStepId = scenario.getInitialStepId();
    const initialStep = scenario.getInitialStep();

    dispatch(
      setChatBotScenarioStepId({
        stepId: initialStepId,
      }),
    );

    if (isBotStep(initialStep)) {
      const message = getMessageForBotStep(initialStep, state);
      await dispatch(maybeTypeAndAddMessage(message));
      dispatch(moveToNextStep());
    }
  };

export const moveToNextStep =
  (): AppThunk =>
  async (dispatch, getState, { chatBotService }) => {
    const state = getState();
    const chatBotName = getActiveChatBotName(state);

    if (!chatBotName) {
      return;
    }

    const scenario = chatBotService.getScenario(chatBotName);
    const currentStepId = getCurrentStepId(state);
    const currentStep = scenario.getStep(currentStepId);

    if (!currentStep) {
      return;
    }

    if (isFinalStep(currentStep)) {
      dispatch(chatEnded());
    }

    const nextStepId = getNextStepIdForStep(currentStep, state);
    const nextStep = scenario.getStep(nextStepId);

    if (!nextStep) {
      return;
    }

    dispatch(
      setChatBotScenarioStepId({
        stepId: nextStepId,
      }),
    );

    if (isBotStep(nextStep)) {
      const message = getMessageForBotStep(nextStep, state);
      await dispatch(maybeTypeAndAddMessage(message));
      dispatch(moveToNextStep());
    } else if (isCreateAccountStep(nextStep)) {
      dispatch(tryCreateAccount(currentStepId!));
    }
  };

const tryCreateAccount =
  (prevStepId: StepId): AppThunk =>
  async (dispatch, getState, { analyticsService }) => {
    const state = getState();
    const chatBotName = getActiveChatBotName(state);

    if (!chatBotName) {
      return;
    }

    try {
      dispatch(setBotIsTyping());
      await delay();
      await dispatch(
        signUpViaChatBot({
          chatBotName,
          emailConsent: true,
        }),
      );
      analyticsService.lead();
      dispatch(moveToNextStep());
    } catch (error) {
      dispatch(setBotIsNotTyping());
      dispatch(
        addMessage({
          id: uuid(),
          type: StepType.BOT,
          text: getCreateAccountErrorText(error),
          createdAt: Date.now(),
        }),
      );
      dispatch(
        setChatBotScenarioStepId({
          stepId: prevStepId,
        }),
      );
    }
  };

const getCreateAccountErrorText = (error: unknown) => {
  if (error instanceof Error) {
    if (error.message === 'Account exists') {
      return 'Sorry, looks like a user with such email already exists. Please try a different one';
    }

    if (error.message === 'Validation failed') {
      return 'Sorry, this looks like an invalid email address. Please try a different one';
    }
  }

  return 'Sorry, some unknown error happened. Try again, please';
};

export const userInput =
  ({
    value,
    displayText,
  }: {
    value?: ChatBotAnswers[keyof ChatBotAnswers];
    displayText: string;
  }): AppThunk =>
  async (dispatch, getState, { chatBotService }) => {
    const state = getState();
    const chatBotName = getActiveChatBotName(state);

    if (!chatBotName) {
      return;
    }

    const scenario = chatBotService.getScenario(chatBotName);
    const currentStepId = getCurrentStepId(state);
    const currentStep = scenario.getStep(currentStepId);

    if (!isUserStep(currentStep)) {
      return;
    }

    const { variable } = currentStep;
    if (variable) {
      dispatch(
        addAnswer({
          question: variable,
          answer: value,
        }),
      );
    }

    dispatch(maybeTypeAndAddMessage(displayText));

    if (isFinalStep(currentStep)) {
      dispatch(chatEnded());
    } else {
      dispatch(moveToNextStep());
    }
  };

const getMessageForBotStep = (step: BotStep<ChatBotAnswers>, state: RootInitialState): string => {
  const answers = getChatBotAnswers(state);
  return typeof step.message === 'string' ? step.message : step.message(answers);
};

const getNextStepIdForStep = (
  step: Step<ChatBotAnswers>,
  state: RootInitialState,
): StepId | undefined => {
  const answers = getChatBotAnswers(state);
  return isSplitStep(step) ? step.getNextBasedOnState(answers) : step.next;
};

export const signUpViaChatBot = createAsyncThunk<
  { id: string; email: string },
  { chatBotName?: ChatBotName; emailConsent?: boolean },
  { extra: Services; state: RootInitialState }
>('store/SIGN_UP_VIA_CHAT_BOT', async ({ emailConsent = false }, { getState, extra }) => {
  const { authService, analyticsService, utmTagsService, sessionRecorder, crossDomainStorage } =
    extra;

  const chatBotAnswers = getChatBotAnswers(getState()) as Required<ChatBotAnswers>;
  const utmTags = utmTagsService.getUtmTags();
  const attributionParams = analyticsService.getAttributionParameters();
  const isOptOutSellUserData = crossDomainStorage.isOptOutSellUserData();

  const sessionId = await analyticsService.getSessionId();
  const deviceId = await analyticsService.getDeviceId();

  const userData: NewUserDTO = {
    email: chatBotAnswers.email,
    name: chatBotAnswers.name || chatBotAnswers.email.split('@')[0],
    gender: chatBotAnswers.gender,
    birth_day: chatBotAnswers.date,
    birth_time: chatBotAnswers.time,
    birth_place: chatBotAnswers.location.name,
    longitude: chatBotAnswers.location.coordinates[0],
    latitude: chatBotAnswers.location.coordinates[1],
    extra_data: {
      ...chatBotAnswers,
    },
    // TODO add as value chatBotName from function parameter
    acquisition_source: FlowName.CHAT_BOT,
    acquisition_data: {
      email_consent: emailConsent,
      gender: chatBotAnswers.gender!,
      zodiacSign: ZodiacService.getZodiacSignByDate(chatBotAnswers.date!),
    },
    media_source: utmTags.utm_source,
    quiz: utmTags.quizz,
    campaign_id: utmTags.utm_campaignid,
    campaign: utmTags.utm_campaign,
    adset_id: utmTags.utm_adsetid,
    adset: utmTags.utm_adset,
    ad_id: utmTags.utm_adid,
    ad: utmTags.utm_ad,
    gclid: utmTags.gclid,
    fbclid: utmTags.fbclid,
    fb_test_event_code: utmTags.fb_test_event_code,
    sessionId,
    deviceId,
    do_not_sell_data: isOptOutSellUserData,
    ...attributionParams,
  };

  const authResult = (await authService.signUpViaQuiz(userData)) as EmailDTO;

  await crossDomainStorage.saveLastUserEmail(chatBotAnswers.email);
  analyticsService.setUserId(authResult.user.id);
  analyticsService.accountCreated(authResult.user.id);
  sessionRecorder.identifyUser(authResult.user.id, {
    email: chatBotAnswers.email,
    gender: userData.gender,
    acquisition_source: FlowName.CHAT_BOT,
  });

  return { email: chatBotAnswers.email, id: authResult.user.id };
});
