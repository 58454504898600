import { useCallback, useState } from 'react';
import { ExpertId } from 'src/core/experts/entities';

export function useExpertsCatalogueModalState() {
  const [modalState, setModalState] = useState<{
    modalOpened: boolean;
    selectedExpertId?: ExpertId;
  }>({ modalOpened: false });

  const openModal = useCallback((id?: ExpertId) => {
    setModalState({ modalOpened: true, selectedExpertId: id });
  }, []);

  const closeModal = useCallback(() => {
    setModalState({ modalOpened: false });
  }, []);

  return {
    modalOpened: modalState.modalOpened,
    selectedExpertId: modalState.selectedExpertId,
    openModal,
    closeModal,
  };
}
