import { AppEnvironment, Config } from 'src/config';
import { Platform } from 'src/core/common/entities';
import { CookiesService } from 'src/core/common/services/cookies';
import { CrossDomainStorage } from '../cross-domain-storage';
import { AppEnvironmentService } from './AppEnvironmentService';

export class AppEnvironmentClientService implements AppEnvironmentService {
  private config: Config;
  private cookiesService: CookiesService;
  private crossDomainStorage: CrossDomainStorage;

  constructor(
    config: Config,
    crossDomainStorage: CrossDomainStorage,
    cookiesService: CookiesService,
  ) {
    this.crossDomainStorage = crossDomainStorage;
    this.config = config;
    this.cookiesService = cookiesService;
  }

  isTestingEnv() {
    return this.crossDomainStorage.isTestingTokenPresent();
  }

  isProductionEnv() {
    return this.config.env === AppEnvironment.PRODUCTION;
  }

  isApple() {
    return navigator.userAgent.search(/OS X|iPhone|iPad|iOS/i) !== -1;
  }
  hasMatchMedia() {
    return typeof window.matchMedia === 'function';
  }

  createMediaQuery(query: string) {
    if (this.hasMatchMedia()) {
      return window.matchMedia(query);
    }

    return null;
  }

  isAndroid() {
    return navigator.userAgent.toLowerCase().includes('android');
  }

  isAppleMobile() {
    return (
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !(window as any).MSStream
    );
  }

  isInAppBrowser() {
    if (navigator.userAgent.match(/Instagram|FBAN|FBAV|Twitter|Pinterest|LinkedIn/i)) {
      return true;
    }

    return navigator.userAgent.toLowerCase().includes('wv');
  }

  getPlatform(): Platform {
    if (this.isAndroid()) return 'android';

    if (this.isAppleMobile()) return 'ios';

    return 'unknown';
  }

  getSplitId() {
    return this.cookiesService.get('split_id') || '';
  }
}
