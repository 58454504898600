import { SessionRecorder } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import {
  AmplitudeService,
  BackendAnalyticsService,
  FacebookService,
  GoogleAnalyticsService,
  GTMService,
} from '../services';
import { BaseEvent } from './BaseEvent';

export type AnalyticEventHandlerServices = {
  amplitudeService: AmplitudeService;
  gtmService: GTMService;
  googleAnalyticsService: GoogleAnalyticsService;
  facebookService: FacebookService;
  backendAnalyticsService: BackendAnalyticsService;
  sessionRecorder: SessionRecorder;
  logger: Logger;
};

export abstract class BaseEventHandler<Payload = unknown> {
  protected amplitudeService: AmplitudeService;

  protected gtmService: GTMService;

  protected googleAnalyticsService: GoogleAnalyticsService;

  protected facebookService: FacebookService;

  protected backendAnalyticsService: BackendAnalyticsService;

  protected sessionRecorder: SessionRecorder;

  protected logger: Logger;

  constructor({
    amplitudeService,
    gtmService,
    googleAnalyticsService,
    facebookService,
    backendAnalyticsService,
    sessionRecorder,
    logger,
  }: AnalyticEventHandlerServices) {
    this.amplitudeService = amplitudeService;
    this.gtmService = gtmService;
    this.googleAnalyticsService = googleAnalyticsService;
    this.facebookService = facebookService;
    this.backendAnalyticsService = backendAnalyticsService;
    this.sessionRecorder = sessionRecorder;
    this.logger = logger;
  }

  protected abstract shouldTrack(event: BaseEvent<Payload>): boolean;

  protected abstract track(event: BaseEvent<Payload>): Promise<void>;

  handle(event: BaseEvent<Payload>): { isHandled: boolean } {
    if (!this.shouldTrack(event)) return { isHandled: false };

    this.track(event).catch((e) => {
      this.logger.error(e);
    });
    return { isHandled: true };
  }
}
