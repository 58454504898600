import Head from 'next/head';
import { useDefaultCanonicalUrl } from 'src/core/common/hooks';

const MetaDataDefault = () => {
  const { defaultCanonicalUrl } = useDefaultCanonicalUrl();

  return (
    <Head>
      <title>AskNebula | Best psychics & astrologers</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="title" content="AskNebula | Best psychics & astrologers" />
      <meta
        name="description"
        content="Gain clarity from professional advisors and find your way to happiness - today"
      />
      <link key="canonical" rel="canonical" href={defaultCanonicalUrl} />
    </Head>
  );
};

export default MetaDataDefault;
