import { v4 as uuid } from 'uuid';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { ProductNameType } from 'src/core/payments/entities';
import { UtmTags } from 'src/core/user/entities';
import { SessionRecorder } from 'src/core/common/services';
import { Logger } from 'src/core/common/logger/interfaces';
import { BaseEvent, BaseEventHandler, QUIZ_ANALYTIC_EVENTS } from '../../events';
import { GTMService } from '../gtm';
import { ConversionsAPIService } from '../conversions-api';
import { FacebookService } from '../facebook';
import { GoogleAnalyticsService } from '../google-analytics';
import { EverflowService } from '../everflow';
import { AmplitudeService } from '../amplitude';
import { BackendAnalyticsService } from '../backend-analytics';
import { AnalyticsService, AttributionParameters } from './AnalyticsService';

export class AnalyticsClientService implements AnalyticsService {
  private amplitudeService: AmplitudeService;

  private gtmService: GTMService;

  private conversionsAPIService: ConversionsAPIService;

  private facebookService: FacebookService;

  private googleAnalyticsService: GoogleAnalyticsService;

  private everflowService: EverflowService;

  private sessionRecorder: SessionRecorder;

  private backendAnalyticsService: BackendAnalyticsService;

  private logger: Logger;

  handlers: BaseEventHandler[];

  constructor(
    amplitudeService: AmplitudeService,
    gtmService: GTMService,
    conversionsAPIService: ConversionsAPIService,
    facebookService: FacebookService,
    googleAnalyticsService: GoogleAnalyticsService,
    backendAnalyticsService: BackendAnalyticsService,
    everflowService: EverflowService,
    logger: Logger,
    sessionRecorder: SessionRecorder,
    handlers: BaseEventHandler[],
  ) {
    this.amplitudeService = amplitudeService;
    this.gtmService = gtmService;
    this.conversionsAPIService = conversionsAPIService;
    this.facebookService = facebookService;
    this.googleAnalyticsService = googleAnalyticsService;
    this.backendAnalyticsService = backendAnalyticsService;
    this.everflowService = everflowService;
    this.logger = logger;
    this.sessionRecorder = sessionRecorder;
    this.handlers = handlers;
  }

  async track(event: BaseEvent) {
    for (let i = 0; i < this.handlers.length; i++) {
      const handler = this.handlers[i];

      const { isHandled } = handler.handle(event);

      if (isHandled) return;
    }
  }

  getAttributionParameters(): AttributionParameters {
    return {
      ...this.conversionsAPIService.getAttributionParameters(),
      ...this.googleAnalyticsService.getAttributionParameters(),
      ...this.everflowService.getAttributionParameters(),
    };
  }

  trackQuizEvent(quizEvent: QUIZ_ANALYTIC_EVENTS, eventProperties: Record<string, any> = {}) {
    this.amplitudeService.trackQuizEvent(quizEvent, eventProperties);
  }

  privateDataCollectionAgreed() {
    this.amplitudeService.privateDataCollectionAgreed();
  }

  setCurrentQuizName(quizName: string) {
    this.amplitudeService.setCurrentQuizName(quizName);
  }

  setUserId(userId: string) {
    this.amplitudeService.setUserId(userId);
  }

  getDeviceId() {
    return this.amplitudeService.getDeviceId();
  }

  getSessionId() {
    return this.amplitudeService.getSessionId();
  }

  updateUtmTags(utmTags: UtmTags): Promise<void> {
    return this.amplitudeService.updateUtmTags(utmTags);
  }

  launch() {
    if (!localStorage.getItem('ASK_Nebula_launched')) {
      localStorage.setItem('ASK_Nebula_launched', 'true');
      this.amplitudeService.launchFirstTime();
    } else if (!sessionStorage.getItem('ASK_Nebula_launched')) {
      sessionStorage.setItem('ASK_Nebula_launched', 'true');
      this.amplitudeService.launch();
    }
  }

  accountCreated(userId: string) {
    this.googleAnalyticsService.track({
      ec: 'email',
      ea: 'entered_email',
      el: 'entered_email',
      cid: userId,
      cd1: userId,
    });
    this.googleAnalyticsService.trackGA4({
      name: 'email_confirm',
    });
  }

  lead() {
    this.gtmService.lead();
  }

  paymentCardChosen() {
    this.amplitudeService.paymentCardChosen();
  }

  paymentPaypalChosen() {
    this.amplitudeService.paymentPaypalChosen();
  }

  paymentRetried() {
    this.amplitudeService.paymentRetried();
  }

  paymentError(reason: string) {
    this.amplitudeService.paymentError(reason);
  }

  paymentSuccess(paymentData: { productId: string; price: number; orderId: string }) {
    this.amplitudeService.paymentSuccess(paymentData);
    this.gtmService.purchase(paymentData);
    this.facebookService.purchase(paymentData.price, paymentData.orderId);
    this.sessionRecorder.track('payment_success', {
      orderId: paymentData.orderId,
      price: paymentData.price,
      productId: paymentData.productId,
    });
    this.googleAnalyticsService.trackGA4({
      name: 'purchase',
      params: {
        currency: 'USD',
        transaction_id: paymentData.orderId,
        value: paymentData.price,
        items: [
          {
            item_id: paymentData.productId,
            item_name: paymentData.productId,
            price: paymentData.price,
            quantity: 1,
          },
        ],
      },
    });
    this.googleAnalyticsService.trackGA4({
      name: 'purchase_acquisition',
      params: {
        currency: 'USD',
        transaction_id: paymentData.orderId,
        value: paymentData.price,
        items: [
          {
            item_id: paymentData.productId,
            item_name: paymentData.productId,
            price: paymentData.price,
            quantity: 1,
          },
        ],
      },
    });
  }

  extendedLpOpened() {
    this.amplitudeService.extendedLpOpened();
  }

  extendedLpGetReport() {
    this.amplitudeService.extendedLpGetReport();
  }

  billingLpOpened() {
    this.amplitudeService.billingLpOpened();
  }

  billingLpGetReport() {
    this.amplitudeService.billingLpGetReport();
  }

  billingLpAdditionalDiscountPopup() {
    this.amplitudeService.billingLpAdditionalDiscountPopup();
  }

  billingLpGetAdditionalDiscount() {
    this.amplitudeService.billingLpGetAdditionalDiscount();
  }

  additionalDiscountLpOpened() {
    this.amplitudeService.additionalDiscountLpOpened();
  }

  additionalDiscountLpGetReport() {
    this.amplitudeService.additionalDiscountLpGetReport();
  }

  numerologyLpOpened(pageOrder: number) {
    this.amplitudeService.numerologyLpOpened(pageOrder);
  }

  numerologyLpGetReport(pageOrder: number) {
    this.amplitudeService.numerologyLpGetReport(pageOrder);
  }

  numerologyLpSkip(pageOrder: number) {
    this.amplitudeService.numerologyLpSkip(pageOrder);
  }

  tarotLpOpened(pageOrder: number) {
    this.amplitudeService.tarotLpOpened(pageOrder);
  }

  tarotLpGetReport(pageOrder: number) {
    this.amplitudeService.tarotLpGetReport(pageOrder);
  }

  tarotLpSkip(pageOrder: number) {
    this.amplitudeService.tarotLpSkip(pageOrder);
  }

  registrationLpOpened(value: string) {
    this.amplitudeService.registrationLpOpened(value);
    const randomUserId = uuid();
    this.googleAnalyticsService.track({
      cid: randomUserId,
      cd1: randomUserId,
      dl: window.location.href,
    });
  }

  subscriptionLandingOpen() {
    this.amplitudeService.subscriptionLandingOpen();
  }

  subscriptionLandingClicked(productName: ProductNameType) {
    this.amplitudeService.subscriptionLandingClicked(productName);
  }

  appnebulaCatalogueLpOpen() {
    this.amplitudeService.appnebulaCatalogueLpOpen();
  }

  appnebulaCatalogueExpertClick(value: string) {
    this.amplitudeService.appnebulaCatalogueExpertClick(value);
  }

  appnebulaCatalogueExpertLpOpen(value: string) {
    this.amplitudeService.appnebulaCatalogueExpertLpOpen(value);
  }

  appnebulaCatalogueExploreAllClick() {
    this.amplitudeService.appnebulaCatalogueExploreAllClick();
  }

  appnebulaFirstQuestionLpOpen() {
    this.amplitudeService.appnebulaFirstQuestionLpOpen();
  }

  registrationLpClicked(value: string) {
    this.amplitudeService.registrationLpClicked(value);
  }

  registrationCatalogueLpOpen() {
    this.amplitudeService.registrationCatalogueLpOpen();
  }

  registrationFormClicked(value: string) {
    this.amplitudeService.registrationFormClicked(value);
  }

  registrationExpertLpOpen(expertId: string) {
    this.amplitudeService.registrationExpertLpOpen(expertId);
  }

  registrationStartChatClicked(expertId: string) {
    this.amplitudeService.registrationStartChatClicked(expertId);
  }

  setUserExperimentGroupName(experimentName: string, groupName: string) {
    this.amplitudeService.setUserExperimentGroupName(experimentName, groupName);
    this.backendAnalyticsService.setSplitExperimentProperties({ experimentName, groupName });
  }

  expertsCatalogueLpOpened() {
    this.amplitudeService.expertsCatalogueLpOpened();
  }

  expertsCatalogueGetStartedClicked() {
    this.amplitudeService.expertsCatalogueGetStartedClicked();
  }

  expertsCatalogueLpStartChatClicked(id: string) {
    this.amplitudeService.expertsCatalogueLpStartChatClicked(id);
  }

  expertsCatalogueEmailFormOpened() {
    this.amplitudeService.expertsCatalogueEmailFormOpened();
  }

  expertsCatalogueGoogleFormOpened() {
    this.amplitudeService.expertsCatalogueGoogleFormOpened();
  }

  expertsCatalogueLpEmailConfirmOpened() {
    this.amplitudeService.expertsCatalogueLpEmailConfirmOpened();
  }

  expertsCatalogueLpExpertOpened(id: string) {
    this.amplitudeService.expertsCatalogueLpExpertOpened(id);
  }

  expertsCatalogueLpPrelandingOpened() {
    this.amplitudeService.expertsCatalogueLpPrelandingOpened();
  }

  expertsCatalogueMatchedExpertsOpened() {
    this.amplitudeService.expertsCatalogueMatchedExpertsOpened();
  }

  expertsCatalogueAllExpertsOpened() {
    this.amplitudeService.expertsCatalogueAllExpertsOpened();
  }

  expertsCataloguePaymentOpened() {
    this.amplitudeService.expertsCataloguePaymentOpened();
  }

  chatOnboardingLpOpened() {
    const randomUserId = uuid();
    this.googleAnalyticsService.track({
      cid: randomUserId,
      cd1: randomUserId,
      dl: window.location.href,
    });
  }

  mainQuizLpOpened() {
    const randomUserId = uuid();
    this.googleAnalyticsService.track({
      cid: randomUserId,
      cd1: randomUserId,
      dl: window.location.href,
    });
  }
  expertsCatalogueEmailConsentClick(value: boolean) {
    this.amplitudeService.expertsCatalogueEmailConsentClick(value);
  }

  registrationEmailConsentClick(value: boolean) {
    this.amplitudeService.registrationEmailConsentClick(value);
  }

  smsInputScreenClicked(data: { eventValue: 'opted_in' | 'closed' }): void {
    this.amplitudeService.smsInputScreenClicked(data);
  }

  registrationOfferEmailConfirmClicked(context: string): void {
    this.amplitudeService.registrationOfferEmailConfirmClicked(context);
  }

  registrationOfferEmailConfirmSkipped(context: string): void {
    this.amplitudeService.registrationOfferEmailConfirmSkipped(context);
  }

  registrationOfferEmailChangeEmail(context: string): void {
    this.amplitudeService.registrationOfferEmailChangeEmail(context);
  }

  appPageAppButtonClick(): void {
    this.amplitudeService.appPageAppButtonClick();
  }

  appPageOpened(): void {
    this.amplitudeService.appPageOpened();
  }

  zodiacPairCompatibilityPageOpen(zodiac: ZodiacTypes, zodiacToCompare: ZodiacTypes): void {
    this.amplitudeService.zodiacPairCompatibilityPageOpen(zodiac, zodiacToCompare);
  }

  zodiacPairCompatibilityPageZodiacSignClick(data: {
    zodiac: ZodiacTypes;
    zodiacToCompare: ZodiacTypes;
    clickedZodiac: ZodiacTypes;
  }): void {
    this.amplitudeService.zodiacPairCompatibilityPageZodiacSignClick(data);
  }

  geoMainPageOpen(): void {
    this.amplitudeService.geoMainPageOpen();
  }

  geoMainPageExpertsCardClick(): void {
    this.amplitudeService.geoMainPageExpertsCardClick();
  }

  geoMainPageStartChatClick(): void {
    this.amplitudeService.geoMainPageStartChatClick();
  }

  geoMainPageBrowseAdvisorsClick(): void {
    this.amplitudeService.geoMainPageBrowseAdvisorsClick();
  }

  geoMainPageFindAdvisorClick(): void {
    this.amplitudeService.geoMainPageFindAdvisorClick();
  }

  geoSinglePageOpen(location: string): void {
    this.amplitudeService.geoSinglePageOpen(location);
  }

  geoSinglePageExpertsCardClick(location: string): void {
    this.amplitudeService.geoSinglePageExpertsCardClick(location);
  }

  geoSinglePageStartChatClick(location: string): void {
    this.amplitudeService.geoSinglePageStartChatClick(location);
  }

  geoSinglePageBrowseAdvisorsClick(location: string): void {
    this.amplitudeService.geoSinglePageBrowseAdvisorsClick(location);
  }
  signUpPageOpened(): void {
    this.amplitudeService.signUpPageOpened();
  }

  signUpPageLoginClick(): void {
    this.amplitudeService.signUpPageLoginClick();
  }

  signUpPageSignUpClick(): void {
    this.amplitudeService.signUpPageSignUpClick();
  }

  registrationWelcomeBackClick(): void {
    this.amplitudeService.registrationWelcomeBackClick();
  }

  registrationWelcomeBackEmailError(): void {
    this.amplitudeService.registrationWelcomeBackEmailError();
  }

  registrationWelcomeBackPasswordError(): void {
    this.amplitudeService.registrationWelcomeBackPasswordError();
  }

  registrationWelcomeBackLoginSuccess(): void {
    this.amplitudeService.registrationWelcomeBackLoginSuccess();
  }

  registrationWelcomeBackForgotYourPasswordClick(): void {
    this.amplitudeService.registrationWelcomeBackForgotYourPasswordClick();
  }

  registrationPasswordRecoveryOpen(): void {
    this.amplitudeService.registrationPasswordRecoveryOpen();
  }

  registrationPasswordRecoveryClick(): void {
    this.amplitudeService.registrationPasswordRecoveryClick();
  }

  registrationCheckEmailOpen(): void {
    this.amplitudeService.registrationCheckEmailOpen();
  }

  registrationCheckEmailClick(): void {
    this.amplitudeService.registrationCheckEmailClick();
  }

  tarotGeoMainPageOpen(): void {
    this.amplitudeService.tarotGeoMainPageOpen();
  }

  tarotGeoMainPageExpertsCardClick(): void {
    this.amplitudeService.tarotGeoMainPageExpertsCardClick();
  }

  tarotGeoMainPageStartChatClick(): void {
    this.amplitudeService.tarotGeoMainPageStartChatClick();
  }

  tarotGeoMainPageBrowseAdvisorsClick(): void {
    this.amplitudeService.tarotGeoMainPageBrowseAdvisorsClick();
  }

  tarotGeoMainPageFindAdvisorClick(): void {
    this.amplitudeService.tarotGeoMainPageFindAdvisorClick();
  }

  tarotGeoSinglePageOpen(location: string): void {
    this.amplitudeService.tarotGeoSinglePageOpen(location);
  }

  tarotGeoSinglePageExpertsCardClick(location: string): void {
    this.amplitudeService.tarotGeoSinglePageExpertsCardClick(location);
  }

  tarotGeoSinglePageStartChatClick(location: string): void {
    this.amplitudeService.tarotGeoSinglePageStartChatClick(location);
  }

  tarotGeoSinglePageBrowseAdvisorsClick(location: string): void {
    this.amplitudeService.tarotGeoSinglePageBrowseAdvisorsClick(location);
  }

  trackUserAge(age: number) {
    this.amplitudeService.trackUserAge(age);
  }

  seoBannerClick() {
    this.amplitudeService.seoBannerClick();
  }

  expertLandingPageScreenOpened(category: string) {
    return this.amplitudeService.expertLandingPageScreenOpened(category);
  }

  expertLandingPageScreenClicked(category: string) {
    return this.amplitudeService.expertLandingPageScreenClicked(category);
  }

  expertCatalogueLandingPageScreenOpened() {
    return this.amplitudeService.expertCatalogueLandingPageScreenOpened();
  }

  startFreeChatScreenClicked() {
    return this.amplitudeService.startFreeChatScreenClicked();
  }

  expertPagePaginationButtonClicked(page: number) {
    return this.amplitudeService.expertPagePaginationButtonClicked(page);
  }

  notFoundPageOpen() {
    this.amplitudeService.notFoundPageOpen();
  }

  serverErrorPageOpen() {
    this.amplitudeService.serverErrorPageOpen();
  }
}
