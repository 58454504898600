import { BatchDestination } from 'src/core/common/logger/services';
import { LogsBatch } from 'src/core/common/logger/entities';
import { mapLogsBatchToGrafanaLoggerLogs } from '../mappers';
import { GrafanaLoggerService } from './GrafanaLoggerService';

export class GrafanaLoggerBatchDestination implements BatchDestination {
  constructor(private readonly lokiService: GrafanaLoggerService) {}

  async sendBatch(logsBatch: LogsBatch): Promise<void> {
    const logs = mapLogsBatchToGrafanaLoggerLogs(logsBatch);

    await this.lokiService.pushLogs(logs);
  }
}
