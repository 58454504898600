import { useServices } from 'src/core/common/hooks/useServices';

export const useCookieConsent = () => {
  const { cookieConsentService } = useServices();

  const toggleReconsent = () => cookieConsentService.toggleReconsent();

  return {
    toggleReconsent,
  };
};
