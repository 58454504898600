import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { ZodiacTypes } from 'src/core/zodiac/entities';
import { convertToSnakeCase } from 'src/core/common/utils/convertToSnakeCase';

export type ZodiacTraitsPageCompatibilityClickEventPayload = {
  zodiac: ZodiacTypes;
  name: string;
  clickedZodiac: ZodiacTypes;
};

export class ZodiacTraitsPageCompatibilityClickEvent extends BaseEvent<ZodiacTraitsPageCompatibilityClickEventPayload> {}

export class ZodiacTraitsPageCompatibilityClickEventHandler extends BaseEventHandler<ZodiacTraitsPageCompatibilityClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ZodiacTraitsPageCompatibilityClickEvent;
  }

  async track(event: ZodiacTraitsPageCompatibilityClickEvent) {
    const { zodiac, name, clickedZodiac } = event.getPayload();

    return this.amplitudeService.logEvent('seo_zodiac_traits_page_compatibility_click', {
      zodiac,
      clicked_zodiac: clickedZodiac,
      name: convertToSnakeCase(name),
    });
  }
}
