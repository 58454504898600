import { createHttpClient } from 'src/core/common/services';
import { Config, config } from 'src/config';

type CreateContentManagerHttpClientParams = Partial<{
  apiUrl: string;
  apiPrefix: string;
  requestConfig: Config['contentManager']['requestConfig'];
}>;

export const createContentManagerHttpClient = (params?: CreateContentManagerHttpClientParams) => {
  return createHttpClient({
    apiUrl: config.contentManager.url,
    apiPrefix: config.contentManager.apiPrefix,
    requestConfig: config.contentManager.requestConfig,
    ...(params ? params : {}),
  });
};
