import { Context } from '@opentelemetry/api/build/src/context/types';
import { AppEnvironmentService } from 'src/core/common/services';
import { ATTRS_SPLIT_ID, Span, SpanAttribute, SpanOptions } from '../entities';
import { ObservabilitySystem } from '../interfaces';
import { ObservabilitySystemDecorator } from './ObservabilitySystemDecorator';

export class ObservabilitySystemWithSplitId extends ObservabilitySystemDecorator {
  constructor(
    private readonly appEnvironment: AppEnvironmentService,
    observabilitySystem: ObservabilitySystem,
  ) {
    super(observabilitySystem);
  }

  getAttributes(): Record<string, SpanAttribute> {
    return {
      ...super.getAttributes(),
      splitId: this.appEnvironment.getSplitId(),
    };
  }

  startSpan(name: string, options?: SpanOptions, context?: Context): Span {
    const span = super.startSpan(name, options, context);

    span.setAttributes({
      [ATTRS_SPLIT_ID]: this.appEnvironment.getSplitId(),
    });

    return span;
  }
}
