import { BaseEvent, BaseEventHandler } from 'src/core/analytics/events';

export type ChatMessageSentEventPayload = {
  context: string;
};

export class ChatMessageSentEvent extends BaseEvent<ChatMessageSentEventPayload> {}

export class ChatMessageSentEventHandler extends BaseEventHandler<ChatMessageSentEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ChatMessageSentEvent;
  }

  async track(event: ChatMessageSentEvent) {
    const { context } = event.getPayload();

    return this.amplitudeService.logEvent('expert_catalogue_chat_screen_click', {
      context,
    });
  }
}
