import CircularProgress from '../CircularProgress';
import RenderOnlyOnClient from '../RenderOnlyOnClient';
import styles from './AppLoader.module.scss';

const AppLoader = () => {
  return (
    <RenderOnlyOnClient>
      <div className={styles.backdrop}>
        <CircularProgress />
      </div>
    </RenderOnlyOnClient>
  );
};

export default AppLoader;
