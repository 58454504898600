import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';
import { TrustSectionMainItem } from 'src/reviews/entities';

export type ReviewsMainTrustedPlatformClickEventPayload = {
  platform: TrustSectionMainItem['name'];
};

export class ReviewsMainTrustedPlatformClickEvent extends BaseEvent<ReviewsMainTrustedPlatformClickEventPayload> {}

export class ReviewsMainTrustedPlatformClickEventHandler extends BaseEventHandler<ReviewsMainTrustedPlatformClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof ReviewsMainTrustedPlatformClickEvent;
  }

  async track(event: ReviewsMainTrustedPlatformClickEvent) {
    const { platform } = event.getPayload();
    return this.amplitudeService.logEvent('seo_reviews_main_page_trusted_review_platform_click', {
      platform,
    });
  }
}
