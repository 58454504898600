import { BaseEvent } from 'src/core/analytics/events/BaseEvent';
import { BaseEventHandler } from 'src/core/analytics/events/BaseEventHandler';

export type GlossaryMainPageBannerClickEventPayload = {
  name: string;
};

export class GlossaryMainPageBannerClickEvent extends BaseEvent<GlossaryMainPageBannerClickEventPayload> {}

export class GlossaryMainPageBannerClickEventHandler extends BaseEventHandler<GlossaryMainPageBannerClickEventPayload> {
  shouldTrack(event: BaseEvent): boolean {
    return event instanceof GlossaryMainPageBannerClickEvent;
  }

  async track(event: GlossaryMainPageBannerClickEvent) {
    const { name } = event.getPayload();

    return this.amplitudeService.logEvent('seo_glossary_main_page_cta_click', {
      name,
    });
  }
}
